import React, { Component } from 'react'
import TabletLocationRow from './TabletLocationRow'
import FilterBar from './FilterBar'

class FilterableTabletLocation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadTabletLocation: false
        }

        this.locationListContainer = React.createRef()
        this.locationListBody = React.createRef()

        this.handleOnScrollTabletLocationsList = this.handleOnScrollTabletLocationsList.bind(this)
    }

    componentDidMount() {
        this.locationListContainer.current.addEventListener("scroll", this.handleOnScrollTabletLocationsList)
        this.locationListContainer.current.scrollTop = this.props.scrollPosition || 0
    }

    handleOnScrollTabletLocationsList() {
        this.props.handleTrackingScrollPosition(this.locationListContainer.current.scrollTop)
        if (this.props.isLoadTabletLocationList || !this.props.hasMoreTabletLocations) { return }
        if (this.locationListBody.current.getBoundingClientRect().bottom < window.innerHeight) {
            console.log("reach bottom")
            this.props.handleLoadMoreTabletLocation()
        }
    }

    render() {
        let tabletLocationRows = this.props.tabletLocations.map((tabletLocation) =>
            <TabletLocationRow
                key={tabletLocation.id}
                tabletLocation={tabletLocation}
                handleClickOnTabletLocationId={this.props.handleClickOnTabletLocationId}
                handleClickOnEditTabletLocation={this.props.handleClickOnEditTabletLocation}
                handleDeleteTabletLocation={this.props.handleDeleteTabletLocation} />
        )
        return (
            <div id="tablet-location-list">
                <div className="row">
                    <div className='col-md-6 add-location-container'>
                        <button className="btn btn-primary" onClick={() => this.props.handleSetCurrentComponent("ADD_TABLET_LOCATION")}>+ Add Location</button>
                    </div>
                    {
                        <FilterBar
                            filterListingText={this.props.filterListingText}
                            assignedStatus={this.props.assignedStatus}
                            locationType={this.props.locationType}
                            handleSetAssignedStatus={this.props.handleSetAssignedStatus}
                            handleSetLocationType={this.props.handleSetLocationType}
                            handleSetFilterListingText={this.props.handleSetFilterListingText}
                        />
                    }
                </div>
                <hr />
                <div className="table-list">
                    <div className="thead row">
                        <div className='col-md-1 col-lg-1 text-center'>ID</div>
                        <div className='col-md-2 col-lg-2 text-left'>Listing</div>
                        <div className='col-md-1 col-lg-1 text-center'>No of Listings</div>
                        <div className='col-md-3 col-lg-3 text-left'>Address</div>
                        <div className='col-md-1 col-lg-1 text-center'>Type</div>
                        <div className='col-md-1 col-lg-1 text-center'>Assigned</div>
                        <div className='col-md-1 col-lg-1 text-center'>Setup Code</div>
                        <div className='col-md-2 col-lg-2 text-center'>Actions</div>
                    </div>
                    <div className="tcontent" ref={this.locationListContainer}>
                        <div ref={this.locationListBody}>
                            {tabletLocationRows}
                        </div>
                        {!this.props.tabletLocations.length &&
                            <div className='no-data'>No Tablet Location Found</div>
                        }
                        {this.props.isLoadTabletLocationList && <div>Loading...</div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default FilterableTabletLocation 