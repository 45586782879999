import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { apiPath, cableURL } from '../../utils/api';
import { confirmAlert } from 'react-confirm-alert';
import { Loading } from '../../utils/loading';
import Video from "twilio-video";
import { createLocalTracks } from "twilio-video"
import ActionCable from "actioncable";
import axios from 'axios';
import Notifications from 'react-notify-toast';
import CallDetail from '../../components/CallDetail/CallDetail';
import CallScreenTopBar from './CallScreenTopBar'
import GuestsActions from './GuestsActions/GuestsActions';
import GuestDetails from './GuestDetails';
import CallUtil from '../../utils/callUtil'
import { CALL_WAITING_TIME, OPERATOR_WAITING_TIME_WHEN_TABLET_CALL } from 'configs/constants.js'
import EndCall from '../../images/EndCall.png';
import CameraIsNotAvailable from '../../images/CameraIsNotAvailable.png'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './CallScreen.scss';

class CCallScreen extends Component {
  constructor(props) {
    super(props)

    this.callScreenLastRowRef = React.createRef()
    this.callScreenMiddleRowRef = React.createRef()
    this.callScreenFirstRowRef = React.createRef()
    this.operatorCheckinScreenRef = React.createRef()
    this.operatorVideoSupportScreenRef = React.createRef()
    this.remoteVideoRef = React.createRef()

    this.operatorName = JSON.parse(localStorage.getItem('authToken'))['authUser']['name']; // staging
    this.identity = this.operatorName
    this.token = null;
    this.activeRoom = null;
    this.checkinChannel = null;
    this.guestInfo = [];
    this.cable = null;
    this.callType = this.props.location.call ? (this.props.location.call.reservation ? this.props.location.call.calling_type : "anonymous") : null
    this.state = {
      isCameraAvailable: false,
      isMicrophoneAvailable: false,
      guestEndBeforeOperatorJoined: false,
      operatorEndBeforeOperatorJoined: false,
      call: null,
      duration: null,
      isLoading: false,
      showMessage: false,
      guestId: null,
      callDetails: true,
      alert: {},
      disabledBtns: ['failed', 'verified'],
      disableInputMessage: true,
      disableEndCallButton: this.props.location.type === 'callBack' ? true : false,
      isSideBarOpen: false,
      verifyingGuest: {
        id: null,
        zens_data: {}
      },
      callTimeouts: []
    }

    this.handleVerifyCall = this.handleVerifyCall.bind(this)
    this.setDuration = this.setDuration.bind(this)
    this.leaveRoomIfJoined = this.leaveRoomIfJoined.bind(this)
    this.roomJoined = this.roomJoined.bind(this)
    this.handleUpdateCallCallBack = this.handleUpdateCallCallBack.bind(this)
    this.clearCallBackTimeout = this.clearCallBackTimeout.bind(this)
    this.toggleCallScreenSideBar = this.toggleCallScreenSideBar.bind(this)
  }

  componentDidMount() {
    window.addEventListener("beforeunload", () => { this.leaveRoomIfJoined('reload') });
    let state = { ...this.state }

    if (this.props.location.call) {
      state.call = this.props.location.call

      let self = this
      if (state.call.reservation) {
        state.verifyingGuest = state.call.reservation.guests.find(guest => {
          return guest.zens_data.id === state.call.guest_id
        })
      }
      if (this.props.location.type === 'callBack') {
        let timeout = setTimeout(function () { self.handleCallTimeout() }, CALL_WAITING_TIME);
        state.callTimeouts.push({ id: state.call.id, timeout: timeout })
        this.endCallButtonTimeout = setTimeout(() => this.setState({ disableEndCallButton: false }), 2000)
        console.log("Set operator call timeout")
      }

      if (this.props.location.call.from_system === "tablet") {
        console.log("Set tablet call timeout")
        let timeout = setTimeout(() => this.handleCallTimeoutForTabletCall(), OPERATOR_WAITING_TIME_WHEN_TABLET_CALL)
        state.callTimeouts.push({ id: state.call.id, timeout: timeout })
      }

      this.adjustCallScreenRows()
      if (this.callType === "checkin") {
        this.adjustOperatorVideoAspectRatio()
      }
      this.setState((prevState, props) => (state), () => {
        this.cable = ActionCable.createConsumer(cableURL());
        this.joinRoom(this.props.location.call)
      })
    }
  }

  adjustOperatorVideoAspectRatio() {
    let videoAspectRatio = 4 / 3
    let operatorVideoCheckinScreen = this.operatorCheckinScreenRef.current
    let lastRow = this.callScreenLastRowRef.current
    let videoFrameHeight = lastRow.offsetHeight * 0.9
    let videoFrameWidth = videoFrameHeight * videoAspectRatio
    operatorVideoCheckinScreen.style.height = `${videoFrameHeight}px`
    operatorVideoCheckinScreen.style.width = `${videoFrameWidth}px`
  }

  adjustCallScreenRows() {
    let videoAspectRatio = (this.callType === "support" || this.callType === "anonymous") ? 4 / 3 : 16 / 9

    let middleRow = this.callScreenMiddleRowRef.current
    let lastRow = this.callScreenLastRowRef.current
    let firstRow = this.callScreenFirstRowRef.current

    let callScreenMainHeight = window.innerHeight
    let videoFrameWidth = (middleRow.offsetWidth) / 2;
    let videoFrameHeight = videoFrameWidth / videoAspectRatio;

    middleRow.style.height = `${videoFrameHeight}px`;
    if (this.callType === 'support' || this.callType === 'anonymous') {
      firstRow.style.height = `${(callScreenMainHeight - videoFrameHeight) / 2}px`
      lastRow.style.height = `${(callScreenMainHeight - videoFrameHeight) / 2}px`
    } else {
      firstRow.style.height = `${(callScreenMainHeight - videoFrameHeight) / 3}px`
      lastRow.style.height = `${(callScreenMainHeight - videoFrameHeight) * (2 / 3)}px`
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.isSideBarOpen && this.state.isSideBarOpen) {
      this.adjustCallScreenRows()
      if (this.callType === "checkin") {
        this.adjustOperatorVideoAspectRatio()
      }
    } else if (prevState.isSideBarOpen && !this.state.isSideBarOpen) {
      this.adjustCallScreenRows()
      if (this.callType === "checkin") {
        this.adjustOperatorVideoAspectRatio()
      }
    }
  }

  componentWillUnmount() {
    this.stopLocalTracks()
    if (this.checkinChannel) {
      this.checkinChannel.unsubscribe()
    }
    this.state.callTimeouts.forEach((callTimeout) => {
      if (callTimeout && callTimeout.timeout) {
        clearTimeout(callTimeout.timeout)
      }
    })
  }

  showPopupReject() {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='popup-remopve-place'>
            <div className='body'>
              <p>Unfortunately, guest has rejected the call</p>
            </div>
            <div className='text-center'>
              <button className='btn submit' onClick={() => { this.leaveRoomIfJoined('guestEnd', 'rejected'); onClose() }}>
                OK
              </button>
            </div>
          </div>
        )
      }
    })
  }

  showPopupAnotherOperatorHasJoined() {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='popup-remopve-place'>
            <div className='body'>
              <p>Another operator has taken the call</p>
            </div>
            <div className='text-center'>
              <button className='btn submit' onClick={() => { this.props.history.push({ pathname: "/list_calls" }); onClose() }}>
                OK
              </button>
            </div>
          </div>
        )
      }
    })
  }

  notifyCallBackToDevice(callID) {
    window.axios.get(apiPath(`calls/${callID}/notify_callback_to_device`)).then(response => {
      console.log(response.data['success'])
    })
  }

  joinRoom(call) {
    if (this.checkinChannel) {
      this.checkinChannel.unsubscribe()
    }
    this.checkinChannel = this.cable.subscriptions.create({ channel: "CheckinChannel", room_name: call.room_name }, {
      connected: () => {
        console.log("Connect to the CheckinChannel")
        if (this.props.location.type === 'callBack') {
          this.notifyCallBackToDevice(this.state.call.id)
        }
      },
      disconnected: () => {
        console.log("Disconnect to the CheckinChannel")
      },
      received: response => {
        console.log(response.type)
        switch (response.type) {
          case 'tablet_lost_connection':
            if (response.room_name === call.room_name) {
              this.leaveRoomIfJoined("clientLostConnection")
            }
            break
          case 'guest_verify':
            let verifyingGuest = this.state.call.reservation.guests.find(guest => { return guest.zens_data.id === Number(response.data['guest_id']) })
            this.setState({
              verifyingGuest: verifyingGuest,
              disabledBtns: [],
            })
            break
          case 'rejected':
            this.clearCallBackTimeout(call)
            this.showPopupReject()
            break
          case 'call_connected':
            if (response.data.to === 'tablet') return;
            this.joinVideoRoom(call)
            break;
          case 'ended':
            this.leaveRoomIfJoined('guestEnd', 'ended')
            break;
          case 'ignored':
            this.clearCallBackTimeout(call)
            this.props.history.push({
              pathname: '/list_calls',
              flag: 'ignored'
            })
            break;
          default:
            break
        }
      },
      call_connected: function (call) {
        this.perform('call_connected', {
          room_name: call.room_name,
          to: 'tablet'
        })
      }
    });

    if (call.from_system !== 'operator') {
      this.joinVideoRoom(call)
    }

    this.setState({ isLoading: true })
  }

  joinVideoRoom(call) {
    let hasCamera = false, hasMicrophone = false, isCameraAvailable = false, isMicrophoneAvailable = false
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          if (device.kind === "videoinput") {
            hasCamera = true
          } else if (device.kind === "audioinput") {
            hasMicrophone = true
          }
        })
        return navigator.permissions.query({ name: 'camera' })
      })
      .then((cameraPermissionResult) => {
        if (hasCamera) {
          isCameraAvailable = (cameraPermissionResult.state === "granted" || cameraPermissionResult.state === "prompt") ? true : false
        } else {
          isCameraAvailable = false
        }
        return navigator.permissions.query({ name: "microphone" })
      })
      .then((microphonePermissionResult) => {
        if (hasMicrophone) {
          isMicrophoneAvailable = (microphonePermissionResult.state === "granted" || microphonePermissionResult.state === "prompt") ? true : false
        } else {
          isMicrophoneAvailable = false
        }
        this.setState({ isCameraAvailable: isCameraAvailable, isMicrophoneAvailable: isMicrophoneAvailable })
        return window.axios.post(apiPath('twilios/generate_token'), { room_sid: call.room_sid, identity: `${this.identity}-operator` })
      })
      .then((response) => {
        console.log('response', response)
        if (response.data.success) {
          this.token = response.data.token
          return createLocalTracks({ audio: isMicrophoneAvailable, video: isCameraAvailable })
        } else {
          this.showPopupAnotherOperatorHasJoined()
        }
      })
      .then(localTracks => {
        this.localTracks = localTracks
        let operatorVideoContainer = this.state.call.calling_type === "checkin" ? this.operatorCheckinScreenRef.current : this.operatorVideoSupportScreenRef.current
        this.attachTracks(localTracks, operatorVideoContainer)
        return Video.connect(this.token, { name: call.room_name, tracks: localTracks })
      })
      .then(room => this.roomJoined(room))
      .catch((err) => {
        console.log(err.name + ": " + err.message)
      })
  }

  attachTracks(tracks, container) {
    tracks.forEach((track) => {
      container.appendChild(track.attach());
    });
  }

  attachParticipantTracks(participant, container) {
    let tracks = Array.from(participant.tracks.values());
    console.log(tracks)
    this.attachTracks(tracks, container);
  }

  detachParticipantTracks(participant) {
    let tracks = Array.from(participant.tracks.values());
    tracks.forEach((track) => {
      track.detach().forEach((detachedElement) => {
        detachedElement.remove();
      });
    });
  }

  detachTrack(track) {
    track.detach().forEach(element => element.remove());
  }

  roomJoined(room) {
    if (this.state.guestEndBeforeOperatorJoined || this.state.operatorEndBeforeOperatorJoined) {
      room.disconnect();
      return
    }
    this.activeRoom = room;
    window.room = room;

    this.log("Joined as '" + this.identity + "'");
    if (this.state.call.from_system === 'tablet') {
      this.checkinChannel.call_connected(this.state.call)
    }
    // Attach the Tracks of the Room's Participants.
    room.participants.forEach((participant) => {
      if (room.participants.size < 1) {
        this.log("Already in Room: '" + participant.identity + "'")
        this.attachParticipantTracks(participant, this.remoteVideoRef.current)
      }
    });

    if (this.state.call.from_system === 'operator') {
      this.setState({ disabledBtns: [], disableInputMessage: false })
    }

    // When a Participant joins the Room, log the event.
    room.on("participantConnected", (participant) => {
      if (this.state.call.from_system === 'tablet') {
        if (room.participants.size >= 1) {
          this.setState({ disabledBtns: [], disableInputMessage: false })
        }
      }
      this.log("Joining: '" + participant.identity + "'");
    });

    // When a Participant adds a Track, attach it to the DOM.
    room.on("trackSubscribed", (track, participant) => {
      if (!(/-operator/.test(participant.identity))) {
        if (track.kind === 'video') {
          this.setState({ isLoading: false })
        }
        this.log(participant.identity + " added track: " + track.kind);
        let previewContainer = this.remoteVideoRef.current;
        this.attachTracks([track], previewContainer);
      }
    });

    room.on("trackUnsubscribed", (track) => {
      this.detachTrack(track);
    })

    // When a Participant leaves the Room, detach its Tracks.
    room.on("participantDisconnected", (participant) => {
      this.log("Participant '" + participant.identity + "' left the room");

      if (!(/-operator/.test(participant.identity))) {
        this.leaveRoomIfJoined('guestEnd')
      }
    });

    room.on("disconnected", () => {
      this.log("trigger the room disconnection event");
      this.activeRoom = null;
    });
  }

  stopLocalTracks() {
    let localTracks = this.localTracks
    if (localTracks) {
      localTracks.forEach((track) => {
        track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
        track.stop()
      })
    }
  }

  leaveRoomIfJoined(typeLeave = 'operatorEnd', status = 'completed') {
    console.log(`callLeaveRoomIfJoined: ${typeLeave}`)
    this.stopLocalTracks()
    if (typeLeave === 'reload') {
      this.handleUpdateCallCallBack()
      return;
    }
    let state = { ...this.state }
    state.isLoading = false
    this.setState(state)
    const currentCall = this.state.call
    if (currentCall) {
      // only disconnect room on the operator
      if (typeLeave === 'guestEnd' && (['rejected', 'ended'].includes(status))) {
        if (status === 'rejected') {
          if (this.activeRoom) {
            this.activeRoom.disconnect();
          }
          this.props.history.push({
            pathname: '/list_calls'
          })
          return;
        } else if (status === 'ended') {
          if (!this.activeRoom) {
            this.setState({ guestEndBeforeOperatorJoined: true })
            this.props.history.push({
              pathname: '/list_calls'
            })
          } else {
            this.activeRoom.disconnect();
            this.props.history.push({
              pathname: '/list_calls'
            })
          }
        }
      }
      if ((typeLeave === 'operatorEnd' || typeLeave === 'timeout') && (!this.activeRoom || this.activeRoom.participants.size === 0)) {
        window.axios.put(apiPath(`calls/${currentCall.id}/cancel`), { device: currentCall.reservation && currentCall.reservation.device })
          .then(response => {
            if (this.activeRoom) {
              this.activeRoom.disconnect();
            } else if (!this.activeRoom && typeLeave === 'operatorEnd') {
              this.setState({ operatorEndBeforeOperatorJoined: true })
            }
            if (typeLeave !== 'timeout') {
              this.props.history.push({
                pathname: '/list_calls'
              })
            }
          })
        return;
      }
      if (typeLeave === "clientLostConnection") {
        if (this.activeRoom) {
          this.activeRoom.disconnect()
          this.props.history.push({
            pathname: '/list_calls',
            flag: 'clientLostConnection'
          })
        }
      }
      let callParams = {
        duration: state.duration || 0,
        status: (state.duration === 0 || !state.duration) ? 'missed' : 'completed',
        operator_name: this.operatorName
      }
      CallUtil.updateCall(currentCall.id, callParams, this.handleUpdateCallCallBack)
    }
  }

  handleVerifyCall(guestID, status) {
    const call = this.state.call
    let requestParams = {
      call_id: call.id,
      room_name: call.room_name,
      status: status
    }
    this.handleDisabledBtn(status)
    window.axios.put(
      apiPath(`guests/${guestID}/verify`),
      requestParams
    ).then(response => {
      if (response.data.success) {
        this.handleShowFlashMessage(status)
        this.handleUpdateReservationGuests(guestID, status)
        this.handleUpdateVerifyingGuest(status)
      } else {
        this.handleShowFlashMessage('failed')
      }
    })
  }

  handleShowFlashMessage(status) {
    let { alert } = this.state
    alert = { flashType: status }
    this.setState({ alert: alert })
    setTimeout(() => {
      alert = {}
      this.setState({ alert: alert })
    }, 3000)
  }

  handleUpdateReservationGuests(guestID, status) {
    let call = this.state.call
    let reservation = call.reservation
    call.reservation.guests = reservation.guests.map(guest => (guest.id === guestID ? Object.assign({}, guest, { status: status }) : guest))
    this.setState({ call: call })
  }

  handleUpdateVerifyingGuest(status) {
    let { verifyingGuest } = this.state
    verifyingGuest.status = status
    this.setState({ verifyingGuest: verifyingGuest })
  }

  handleDisabledBtn(status) {
    this.setState({ disabledBtns: [status] })
  }

  setDuration(duration) {
    let state = { ...this.state }
    state.duration = duration
    this.setState(state)
  }

  showMessage() {
    const show = !this.state.showMessage
    this.setState({ showMessage: show })
  }

  log(message) {
    console.log(message)
  }

  operatorCall(oldCall) {
    const self = this
    let state = this.state
    if (oldCall.reservation) {
      const reservation = oldCall.reservation
      const dataRequest = {
        calling_type: oldCall.calling_type,
        device: reservation && reservation.device,
        tablet_type: oldCall.tablet_type,
        guest_id: oldCall.guest_id,
        reservation_code: oldCall.reservation.code,
        from_system: 'operator',
        operator_name: this.operatorName,
      }
      axios.post(apiPath('calls'), dataRequest).then(response => {
        let call = response.data['call']
        call['reservation']['listing'] = oldCall.reservation.listing
        let timeout = setTimeout(function () { self.handleCallTimeout() }, CALL_WAITING_TIME);
        this.joinRoom(call)
        state.callTimeouts.push({ id: call.id, timeout: timeout })
        self.setState((prevState, props) => ({ call: call, callTimeouts: state.callTimeouts }))
      })
    } else {
      let requestedData = {
        calling_type: 'support',
        tablet_type: oldCall.tablet_type,
        place_id: oldCall.place_id,
        from_system: 'operator',
        operator_name: this.operatorName,
      }
      axios.post(apiPath('calls'), requestedData).then(response => {
        if (response.data['success'] === "false" && response.data['error']['error_code'] === 1789) {
          return
        } else {
          let call = response.data['call']
          let timeout = setTimeout(function () { self.handleCallTimeout() }, CALL_WAITING_TIME);
          this.joinRoom(call)
          state.callTimeouts.push({ id: call.id, timeout: timeout })
          self.setState((prevState, props) => ({ call: call, callTimeouts: state.callTimeouts }))
        }
      })
    }
  }

  handleCallTimeoutForTabletCall() {
    if (this.state.isLoading && this.activeRoom && this.activeRoom.participants.size === 0) {
      this.leaveRoomIfJoined("clientLostConnection")
    }
  }

  handleCallTimeout() {
    if (this.state.isLoading && (!this.activeRoom || this.activeRoom.participants.size === 0)) {
      this.leaveRoomIfJoined('timeout')
      let self = this
      const oldCall = this.state.call
      confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className='popup-remopve-place'>
              <h2>Call Timeout</h2>
              <div className='body'>
                <p>Unfortunately, no one has answer the call.</p>
                <p>Please try again.</p>
              </div>
              <div className='text-center'>
                <button className='btn submit' onClick={function () { self.operatorCall(oldCall); onClose(); }}>
                  Call Again
                </button>
                <button className='btn cancel' onClick={function () { self.props.history.push({ pathname: '/list_calls' }); onClose(); }}>
                  Cancel
                </button>
              </div>
            </div>
          )
        }
      })
    } else if (!this.state.isLoading && this.activeRoom && this.activeRoom.participants.size === 0) {
      this.leaveRoomIfJoined('timeout')
    }
  }

  customHeightClass() {
    return this.callType === 'checkin' ? 'custom-height' : ''
  }

  handleUpdateCallCallBack() {
    const self = this
    if (self.activeRoom) {
      self.activeRoom.disconnect();
    }
    self.props.history.push({
      pathname: '/list_calls'
    })
  }

  clearCallBackTimeout(call) {
    console.log("Clear call timeout")
    let timeout = this.state.callTimeouts.find(t => t.id === call.id)
    console.log(timeout)
    if (timeout) {
      clearTimeout(timeout.timeout)
      this.setState({ callTimeouts: [] })
      timeout = null
    }
    console.log(this.state.callTimeouts)
  }

  toggleCallScreenSideBar() {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
  }

  sideBarOpenClass() {
    return this.state.isSideBarOpen ? "sidebar-open" : ""
  }

  renderEmptyCameraState() {
    if (!this.state.isCameraAvailable) {
      return (
        <div className="container-cameranotavailable">
          <img alt="" src={CameraIsNotAvailable} />
          <p>Camera is unavailable</p>
        </div>
      )
    }
  }

  renderBottomRow(guests, isCheckinCall) {
    if (this.callType !== 'checkin') {
      return (
        <div ref={this.callScreenLastRowRef} id="callscreen-lastrow" className={`row row-3 ${this.sideBarOpenClass()}`}>
          <div id="listing-short-name-container">
            {this.state.call && this.state.call.reservation && this.state.call.reservation.listing.short_name}
          </div>
          <div className='right-btn-end-call d-flex justify-content-center align-items-center'>
            {this.state.disableEndCallButton ? (
              <button className='align-self-center border-0 bg-transparent' disabled onClick={() => this.leaveRoomIfJoined()}>
                <img className='w-100' src={EndCall} alt="end-call" />
              </button>
            ) : (
                <button className='align-self-center border-0 bg-transparent' onClick={() => this.leaveRoomIfJoined()}>
                  <img className='w-100' src={EndCall} alt="end-call" />
                </button>
              )}
          </div>
          <button id="sidebar-btn" onClick={this.toggleCallScreenSideBar}>
            <i className="la la-comment"></i>
          </button>
        </div>
      )
    } else {
      return (
        <div ref={this.callScreenLastRowRef} className={`row row-3 custom-height ${this.sideBarOpenClass()}`}>
          <div className="operator-video col-md-6 d-flex justify-content-center align-items-center">
            <div ref={this.operatorCheckinScreenRef} id="operator-video-checkinscreen" className="align-self-center">
              {this.renderEmptyCameraState()}
            </div>
          </div>
          <GuestsActions
            guests={guests}
            verifyingGuest={this.state.verifyingGuest}
            handleVerifyCall={this.handleVerifyCall}
            isCheckinCall={isCheckinCall}
            disabledBtns={this.state.disabledBtns}
            isSideBarOpen={this.state.isSideBarOpen}
          />
        </div>
      )
    }
  }

  renderGuestsDetails() {
    if (this.callType === 'checkin' && this.state.verifyingGuest && this.state.verifyingGuest.id) {
      return (
        <GuestDetails verifyingGuest={this.state.verifyingGuest} />
      )
    } else {
      return (
        <div ref={this.operatorVideoSupportScreenRef} id='operator-video-supportscreen' className=''>
          {this.renderEmptyCameraState()}
        </div>
      )
    }
  }

  render() {
    const reservation = this.state.call ? this.state.call.reservation : null
    const placeId = (!reservation && this.state.call) ? this.state.call.place_id : null
    const placeAddress = (!reservation && this.state.call) ? this.state.call.location_address : null
    const listing = reservation && reservation.listing
    const guests = (reservation && reservation.guests) || []
    const tabletType = this.state.call && this.state.call.tablet_type
    let isCheckinCall = this.callType === 'checkin'

    return (
      <div className='call-screen' id='call-screen'>
        <div className={this.state.isSideBarOpen ? "callscreen-sidebar open" : "callscreen-sidebar"}>
          {this.state.call && this.state.call.room_name &&
            <CallDetail
              reservation={reservation}
              listing={listing}
              tabletType={tabletType}
              guestInfos={guests}
              isAnonymous={!reservation}
              roomName={this.state.call.room_name}
              listingsForAnonymousCall={this.state.call.related_listings}
              disableInputMessage={this.state.disableInputMessage}
              placeId={placeId}
              placeAddress={placeAddress}
            />
          }
        </div>

        <div id="callscreen-wrapper" className={this.state.isSideBarOpen ? "callscreen-main" : "callscreen-main full"}>
          <Notifications options={{ zIndex: 200, top: '50px', left: '600px' }} />
          <div ref={this.callScreenFirstRowRef} id='callscreen-firstrow' className='row row-1'>
            <CallScreenTopBar
              activeRoom={this.activeRoom}
              isLoading={this.isLoading}
              setDuration={this.setDuration}
              toggleCallScreenSideBar={this.toggleCallScreenSideBar}
              leaveRoomIfJoined={this.leaveRoomIfJoined}
              verifyingGuest={this.state.verifyingGuest}
              isAnonymous={!reservation}
              isCheckinCall={isCheckinCall}
              alert={this.state.alert}
              reservation={reservation}
              disableEndCallButton={this.state.disableEndCallButton}
              isMicrophoneAvailable={this.state.isMicrophoneAvailable}
            />
          </div>
          <div ref={this.callScreenMiddleRowRef} className={`row row-2 bg-white ${this.customHeightClass()} ${this.sideBarOpenClass()}`} id='div-screenshot'>
            <div className='col-md-6 px-0 video-with-guest-action-container'>
              <div ref={this.remoteVideoRef} id='remote-video' className=''>
                {this.state.isLoading && Loading('spinningBubbles', 'fff')}
              </div>
            </div>

            <div className='col-md-6 col-lg-6 px-0' id='passport'>
              {
                this.renderGuestsDetails()
              }
            </div>
          </div>
          {
            this.renderBottomRow(guests, isCheckinCall)
          }
        </div>
      </div>
    )
  }
}

const RCallScreen = withRouter(CCallScreen)

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

const CallScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(RCallScreen)

export default CallScreen
// deploy commit