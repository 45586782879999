import { RESERVATION_ACTIONS } from 'actions/reservation'

let initializeState = {
  reservationsList: [],
  currentPage: null,
  totalPages: null,
  loadMore: null
}

export const reservation = (state = initializeState, action) => {
  switch (action.type) {
    case RESERVATION_ACTIONS.RECEIVE_RESERVATIONS_INFO:
      return {
        ...state,
        reservationsList: action.payload.reservationsList,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        loadMore: action.payload.loadMore
      }
    case RESERVATION_ACTIONS.ADD_MORE_RESERVATIONS:
      return {
        ...state,
        reservationsList: [...state.reservationsList, ...action.payload.moreReservations],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        loadMore: action.payload.loadMore
      }
    default:
      break;
  }
  return state;
}