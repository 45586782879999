import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VerifyFlash.scss'

class VerifyFlash extends Component {

  renderMessage(alert) {
    const { flashType } = alert
    const t = this.context.t
    if (flashType === 'failed') {
      return (
        <div key={Math.random()} className={`verify-flash mb-1 ${flashType}`}>
          <FontAwesomeIcon icon='exclamation' color='white' />
          <span className='pl-2'>{t('call_detail.verify_flash_fail')}</span>
        </div>
      )
    } else if (flashType === 'verified') {
      return (
        <div key={Math.random()} className={`verify-flash mb-1 ${flashType}`}>
          <FontAwesomeIcon icon='check' color='white' />
          <span className='pl-2'>{t('call_detail.verify_flash_success')}</span>
        </div>
      )
    } else {
      return
    }
  }

  render() {
    return (
      <div className='flash-group text-center'>
        {
          this.renderMessage(this.props.alert)
        }
      </div>
    )
  }
}

VerifyFlash.contextTypes = {
  t: PropTypes.func
}
export default VerifyFlash