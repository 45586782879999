export const LISTING_ACTIONS = {
  RECEIVE_LISTINGS: 'RECEIVE_LISTINGS',
  ADD_LISTING: 'ADD_LISTING'
}

export const receiveListings = (listings) => ({
  type: LISTING_ACTIONS.RECEIVE_LISTINGS,
  payload: {
    listings: listings
  }
})

export const addListing = (listing) => ({
  type: LISTING_ACTIONS.ADD_LISTING,
  payload: {
    listing: listing
  }
})