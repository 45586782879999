import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { apiPath } from '../../../utils/api'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BackButton from '../BackButton'

class CPlaceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      place: {
        placeId: '',
        setupCode: '',
        noListings: '',
        listingIds: [],
        listingName: [],
        uid: '',
        address: '',
        placeType: '',
        lastSetup: '',
        slaves: null,
        masterId: null,
        listings: []
      }
    }
    this.listListings = []
    this.listDevices = []
    this.listingsChoosen = []
    this.generator = require('generate-password');
  }

  componentDidMount() {
    let state = { ...this.state }
    let place = state.place
    place.placeId = this.props.id

    window.axios.get(apiPath(`places/${place.placeId}`)).then(response => {
      if (response.data['success']) {
        let placeRes = response.data['place']
        place.setupCode = placeRes.setup_code
        place.noListings = placeRes.listings.length
        place.listingIds = placeRes.listing_ids
        place.address = placeRes.address
        place.uid = placeRes.uid
        place.placeType = placeRes.place_type
        place.lastSetup = placeRes.last_setup != null ? (new Date(placeRes.last_setup)).toLocaleString('jp') : 'None'
        place.has_available_reservation = placeRes.has_available_reservation
        place.listings = placeRes.listings
        place.slaves = placeRes.slaves
        place.masterId = placeRes.master_id
        state.place = place
        this.setState(state)
      }
    })
  }

  render() {
    const place = this.state.place
    const columns = [{
      dataField: 'field',
      text: 'Aspects'
    }, {
      dataField: 'value',
      text: 'Content'
    }]
    const placeInfo = [
      {
        id: 1,
        field: 'Type',
        value: place.placeType,
      },
      {
        id: 2,
        field: 'Number of Listings',
        value: place.noListings
      },
      {
        id: 3,
        field: 'Listings',
        value: place.listings.map(listing => listing.label).join(', ')
      },
      {
        id: 4,
        field: 'Address',
        value: place.address
      },
      {
        id: 5,
        field: 'Assigned',
        value: place.uid != null ? 'Yes' : 'No'
      },
      {
        id: 6,
        field: 'Tablet UDID',
        value: place.uid != null ? place.uid : 'None'
      },
      {
        id: 7,
        field: 'Setup Code',
        value: place.setupCode
      },
      {
        id: 8,
        field: 'Last Setup',
        value: place.lastSetup
      }
    ]
    let slavesId = place.slaves && place.slaves.map(slave => `ID ${slave.id}`)

    if (place.slaves && place.slaves.length > 0) {
      placeInfo.splice(4, 0, {
        id: 10,
        field: 'Control over',
        value: slavesId.join(', ')
      })
    }

    if (place.masterId) {
      placeInfo.splice(4, 0, {
        id: 13,
        field: 'Under Control',
        value: `ID ${place.masterId}`
      })
    }

    return (
      <div className='place-detail'>
        <div className='header-place-detail'>
          <BackButton handleReturnToFilterableTabletLocations={this.props.handleReturnToFilterableTabletLocations} />
          <span>{`Location ID #${this.props.id}`}</span>
        </div>

        <BootstrapTable
          keyField='id'
          data={placeInfo}
          columns={columns}
          headerClasses="place-detailed-bootstrap-table-header-row"
        />
        {this.state.place.placeId && <button className="edit-place-btn float-right" onClick={() => this.props.handleClickOnEditTabletLocation(this.state.place.placeId)}>Edit Place</button>}
      </div>
    )
  };
}

CPlaceDetail.contextTypes = {
  t: PropTypes.func
}

const mapStateToProps = state => ({
  reduxTokenAuth: state.reduxTokenAuth
})

const mapDispatchToProps = {
}

const PlaceDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(CPlaceDetail)

export default PlaceDetail
