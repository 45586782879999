import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios';
import PropTypes from 'prop-types'
import ToggleSliderButton from '../../ToggleSliderButton/ToggleSliderButton'
import RefreshCode from '../../../images/RefreshCode.png';
import { apiPath } from '../../../utils/api'
import Select from 'react-select';
import { renderMessage } from '../../../utils/flash';
import { errorMessages } from 'configs/errorMessages';
import { loadListingOptions } from 'utils/loadListingOptions.js'
import { loadPlaceOptions } from 'utils/loadPlaceOptions.js'
import AsyncPaginate from 'react-select-async-paginate'
import BackButton from '../BackButton'

class CEditPlace extends Component {
  constructor(props) {
    super(props);
    this.submitFormBtnRef = React.createRef()
    this.state = {
      isMasterPlace: null,
      setupCode: '',
      listingIds: [],
      listings: [],
      slavePlaces: [],
      device: '',
      address: '',
      placeType: '',
      editableTabletInfo: null,
      has_available_reservation: false
    }
    this.listListings = []
    this.listDevices = []
    this.generator = require('generate-password');
    this.handleType = this.handleType.bind(this)
    this.handleListings = this.handleListings.bind(this)
    this.handleDevice = this.handleDevice.bind(this)
    this.handleAddress = this.handleAddress.bind(this)
    this.handleGenerateCode = this.handleGenerateCode.bind(this)
    this.handleToggleMasterPlace = this.handleToggleMasterPlace.bind(this)
    this.handleSlavePlaces = this.handleSlavePlaces.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    this.getPlaceInfo()
  }

  getPlaceInfo() {
    let state = { ...this.state }
    let self = this

    axios.get(apiPath(`places/${this.props.id}/edit`)).then(response => {
      if (response.data['success']) {
        let place = response.data['place']
        let isEditTabletInfo = !place.uid;
        let isMasterPlace = (!place.listing_ids && place.slaves.length > 0) ? true : false
        place.slaves.forEach(slave => {
          slave['value'] = slave.id
          slave['label'] = slave.id
        })
        state = {
          ...state,
          setupCode: place.setup_code,
          listingIds: place.listing_ids,
          address: place.address,
          device: place.uid,
          placeType: place.place_type,
          editableTabletInfo: isEditTabletInfo,
          listings: isMasterPlace ? [] : place.listings,
          slavePlaces: isMasterPlace ? place.slaves : [],
          isMasterPlace: isMasterPlace,
          has_available_reservation: place.has_available_reservation
        }
        self.setState(state)
      } else {
        let errorMessage = errorMessages(response.data.error_code);
        renderMessage('error', errorMessage)
      }
    })
  }

  handleSlavePlaces(places) {
    this.setState({ slavePlaces: places })
  }

  updatePlace(placeId, placeParams) {
    axios.put(apiPath(`places/${placeId}`), placeParams).then(response => {
      if (response.data['success']) {
        this.props.handleUpdateTabletLocation(response.data)
      } else {
        this.submitFormBtnRef.current.disabled = false
        this.props.handleAddFlashMessage({ text: response.data['message'].toString(), type: 'error' })
      }
    })
  }

  handleListings(listings) {
    let state = { ...this.state }
    let firstListing = listings[0]
    state.listings = listings
    if (firstListing) {
      state.address = firstListing.address
    }
    if (listings.length > 1) {
      state.placeType = 'outside'
      state.isMasterPlace = false
    }
    this.setState(state)
  }

  handleDevice(device) {
    this.setState({ device: device })
  }

  handleAddress(e) {
    this.setState({ address: e.target.value })
  }

  handleType(type) {
    if (type === 'inside' && this.state.listings.length > 1) return false
    this.setState({ placeType: type })
  }

  handleGenerateCode() {
    window.axios.get(apiPath('places/generate_setup_code')).then(response => {
      this.setState({ setupCode: response.data['setup_code'] })
    })
  }

  handleToggleMasterPlace() {
    this.setState({ isMasterPlace: !this.state.isMasterPlace, listings: [] })
  }

  onSubmit = (e) => {
    e.preventDefault();
    let t = this.context.t;
    let listingRequired = (this.state.placeType === "inside" && !this.state.listings.length) ||
      (this.state.placeType === "outside" && !this.state.isMasterPlace && !this.state.listings.length)
    let addressRequired = (!this.state.isMasterPlace && !this.state.address.length && this.state.placeType === "outside") ||
      (this.state.placeType === "inside" && !this.state.address.length)
    let slavesRequired = this.state.placeType === "outside" && this.state.isMasterPlace && !this.state.slavePlaces.length
    if (listingRequired) {
      let message = { text: t('listings.required'), type: "error" }
      this.props.handleAddFlashMessage(message)
    } else if (addressRequired) {
      let message = { text: t('address.required'), type: "error" }
      this.props.handleAddFlashMessage(message)
    } else if (slavesRequired) {
      let message = { text: t('slaves.required'), type: "error" }
      this.props.handleAddFlashMessage(message)
    } else {
      this.submitFormBtnRef.current.disabled = true
      let listingIds = []
      this.state.listings.forEach(listing => {
        listingIds.push(listing.value)
      })
      let slavePlaces = []
      this.state.slavePlaces.forEach(place => {
        slavePlaces.push(place.id)
      })
      const dataRequest = (this.state.isMasterPlace && this.state.placeType === "outside") ? {
        'setup_code': this.state.password,
        'place_type': this.state.placeType,
        'is_master': this.state.isMasterPlace,
        'slave_places': slavePlaces
      } : {
          'setup_code': this.state.setupCode,
          'listing_ids': listingIds,
          'place_type': this.state.placeType,
          'address': this.state.address,
          'uid': this.state.device && this.state.device.value
        }
      this.updatePlace(this.props.id, dataRequest)
    }
  }

  renderActiveClass(type) {
    let state = { ...this.state }
    if (state.placeType === type) {
      return 'active'
    } else if (state.listings.length > 1) {
      return 'disabled'
    } else {
      return ''
    }
  }

  renderSelectListingPlaceholder() {
    let t = this.context.t
    if (this.state.placeType === 'inside') {
      return t('edit_place.add_one_listing_placeholder')
    } else {
      return t('edit_place.select_listings_placeholder')
    }
  }

  currentDevice() {
    return this.listDevices.find(item => { return item.value === this.state.device })
  }

  render() {
    let t = this.context.t
    let onLyAllowAddListingForm = (this.state.isMasterPlace || this.state.placeType === "inside") ? (
      <div className="cannot-edit-place">
        <p>Unable to edit this Tablet Location, since its listing being used</p>
      </div>
    ) : (
        <form className="form-add-place" onSubmit={this.onSubmit}>
          <div className="form-group form-inline">
            <div className="col-lg-4 text-left">
              <span className="add-place-title">{t('type')}</span>
            </div>
            <div className="col-lg-8 choose-place-type">
              <div className="btn-group">
                <label className={'btn btn-primary btn-place-type active'}>
                  <input className='d-none' type="radio" name="place-type" id="outside" /> {t('outside')}
                </label>
                <label className={'btn btn-primary btn-place-type disabled'}>
                  <input className='d-none' type="radio" name="place-type" id="inside" /> {t('inside')}
                </label>
              </div>
            </div>
          </div>
          <div className="form-group form-inline">
            <div className="col-lg-4 text-left">
              <span className="add-place-title">Control other tablets</span>
            </div>
            <div className="col-lg-8">
              {<ToggleSliderButton disabled={true} isMasterPlace={this.state.isMasterPlace} handleToggleMasterPlace={this.handleToggleMasterPlace} />}
            </div>
          </div>
          <div>
            <div className="form-group form-inline">
              <div className="col-lg-4 text-left">
                <span className="add-place-title">{t('listings')}</span>
              </div>
              <div className="col-lg-8">
                <AsyncPaginate
                  value={this.state.listings}
                  loadOptions={loadListingOptions}
                  onChange={this.handleListings}
                  additional={{ page: 1 }}
                  openOnFocus={true}
                  isMulti={true}
                  placeholder={this.renderSelectListingPlaceholder()}
                />
              </div>
            </div>
            <div className="form-group form-inline">
              <div className="col-lg-4 text-left">
                <span className="add-place-title">{t('tablet_info')}</span>
              </div>
              <div className="col-lg-8">
                <Select
                  value={this.currentDevice()}
                  onChange={this.handleDevice}
                  options={this.listDevices}
                  placeholder={!this.state.isEditTabletInfo ? "None" : "Select..."}
                  isClearable={true}
                  isDisabled={true}
                />
              </div>
            </div>
            <div className="form-group form-inline">
              <div className="col-lg-4 text-left">
                <span className="add-place-title">{t('address')}</span>
              </div>
              <div className="col-lg-8">
                <input
                  type="text"
                  className="form-control input-place"
                  name="address"
                  value={this.state.address}
                  onChange={this.handleAddress}
                />
              </div>
            </div>
            <div className="form-group group-button text-right">
              <button type="submit" ref={this.submitFormBtnRef} name="addPlaceSubmitBtn" className="btn btn-add">{t('save')}</button>
            </div>
          </div>
        </form>
      )

    let edidtedForm = this.state.has_available_reservation ? (onLyAllowAddListingForm) : (
      <form className="form-add-place" onSubmit={this.onSubmit}>
        <div className="form-group form-inline">
          <div className="col-lg-4 text-left">
            <span className="add-place-title">{t('type')}</span>
          </div>
          <div className="col-lg-8 choose-place-type">
            <div className="btn-group">
              <label className={`btn btn-primary btn-place-type ${this.renderActiveClass('outside')}`} onClick={() => this.handleType('outside')}>
                <input className='d-none' type="radio" name="place-type" id="outside" /> {t('outside')}
              </label>
              <label className={`btn btn-primary btn-place-type ${this.renderActiveClass('inside')}`} onClick={() => this.handleType('inside')}>
                <input className='d-none' type="radio" name="place-type" id="inside" /> {t('inside')}
              </label>
            </div>
          </div>
        </div>

        {
          this.state.placeType === "outside" &&
          <div className="form-group form-inline">
            <div className="col-lg-4 text-left">
              <span className="add-place-title">Control other tablets</span>
            </div>
            <div className="col-lg-8">
              {<ToggleSliderButton isMasterPlace={this.state.isMasterPlace} handleToggleMasterPlace={this.handleToggleMasterPlace} />}
            </div>
          </div>
        }

        {
          (!this.state.isMasterPlace || this.state.placeType === "inside") ? (
            <div>
              <div className="form-group form-inline">
                <div className="col-lg-4 text-left">
                  <span className="add-place-title">{t('listings')}</span>
                </div>
                <div className="col-lg-8">
                  <AsyncPaginate
                    value={this.state.listings}
                    loadOptions={loadListingOptions}
                    onChange={this.handleListings}
                    additional={{ page: 1 }}
                    openOnFocus={true}
                    isMulti={true}
                    placeholder={this.renderSelectListingPlaceholder()}
                  />
                </div>
              </div>
              <div className="form-group form-inline">
                <div className="col-lg-4 text-left">
                  <span className="add-place-title">{t('tablet_info')}</span>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={this.currentDevice()}
                    onChange={this.handleDevice}
                    options={this.listDevices}
                    placeholder={!this.state.isEditTabletInfo ? "None" : "Select..."}
                    isClearable={true}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="form-group form-inline">
                <div className="col-lg-4 text-left">
                  <span className="add-place-title">{t('address')}</span>
                </div>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control input-place"
                    name="address"
                    value={this.state.address}
                    onChange={this.handleAddress}
                  />
                </div>
              </div>
            </div>
          ) : (
              <div className="form-group form-inline">
                <div className="col-lg-4 text-left">
                  <span className="add-place-title">Control Over</span>
                </div>
                <div className="col-lg-5">
                  <AsyncPaginate
                    value={this.state.slavePlaces}
                    onChange={this.handleSlavePlaces}
                    loadOptions={loadPlaceOptions}
                    additional={{ page: 1, edit: this.props.location.pathname.split('/')[2] }}
                    placeholder="You may add multiple Location IDs"
                    isMulti={true}
                    openOnFocus={true}
                  />
                </div>
              </div>
            )
        }

        <div className="form-group form-inline">
          <div className="col-lg-4 text-left">
            <span className="add-place-title">{t('setup_code')}</span>
          </div>
          <div className="col-lg-8">
            <span className="setup-code">{this.state.setupCode}</span>
            <button className="generate-setup-code-btn" onClick={(e) => { e.preventDefault(); this.handleGenerateCode() }}>
              <img src={RefreshCode} alt='refresh-code' id='refresh-code' />
            </button>
          </div>
        </div>
        <div className="form-group group-button text-right">
          <button ref={this.submitFormBtnRef} type="submit" name="addPlaceSubmitBtn" className="btn btn-add">{t('save')}</button>
        </div>
      </form>
    )
    return (
      <div className='edit-place'>
        <div className='header-edit-place'>
          <BackButton handleReturnToFilterableTabletLocations={this.props.handleReturnToFilterableTabletLocations} />
          <span>{`Location ID #${this.props.id}`}</span>
        </div>
        {edidtedForm}
      </div>
    )
  };
}

CEditPlace.contextTypes = {
  t: PropTypes.func
}
const REditPlace = withRouter(CEditPlace)

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

const EditPlace = connect(
  mapStateToProps,
  mapDispatchToProps
)(REditPlace)

export default EditPlace