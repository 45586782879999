import React, { Component } from 'react';
import PropTypes from 'prop-types'
import './CallDetail.scss'
import moment from 'moment';
import 'configs/global.js';
import Messenger from '../../components/Messenger/Messenger';

const TAB = {
  INFORMATION: 'Infomation',
  CHAT: 'Chat'
}

class CallDetail extends Component {
  constructor(props) {
    super(props)

    this.switchDetailTab = this.switchDetailTab.bind(this)
    this.timeTypes = {
      checkin: 'checkin',
      checkout: 'checkout',
    }
    this.state = {
      tabSelect: TAB.INFORMATION
    }
  }

  renderTime(h) {
    let result
    if (h === null || h === '') {
      return
    }
    else if (h.toUpperCase() === 'FLEXIBLE' || h.toUpperCase() === 'NOT_SELECTED') {
      result = '(flexible)'
    } else {
      let time = h.split(":")[0]
      result = Number(time) <= 12 ? `${time}:00 AM` : `${Number(time) - 12}:00 PM`
    }
    return (<strong className='pr-2'>{result}</strong>)
  }

  renderDate(date) {
    return moment(new Date(date)).format("MMM DD, YYYY")
  }

  renderDateTime(type) {
    let { reservation } = this.props;
    let time = reservation.checkin_time;
    let date = reservation.start_date;
    if (type === this.timeTypes.checkout) {
      time = reservation.checkout_time;
      date = reservation.end_date;
    }
    return (
      <div className='reservation-datetime-info'>
        {this.renderTime(time)}
        <span>{this.renderDate(date)}</span>
      </div>
    )
  }

  renderGuestInfo() {
    const guestInfos = this.props.guestInfos
    return (
      <div className="guests-infos">
        <h3>Guests</h3>
        <table className='table table-hover text-center table-guest-infos'>
          <tbody>
            {
              guestInfos.map(guest => {
                const guestData = guest.zens_data
                return (
                  <tr key={guest.id}>
                    <td className="text-left guests-name" width='15%'>
                      {guestData.full_name}
                      <p className="text-left guest-occupation">{guestData.occupation}</p>
                    </td>
                    <td className="text-right" width='15%'>{guestData.nationality}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

  renderListingInfo() {
    const t = this.context.t
    return (
      <div className='listing-infos'>
        <h3>Listing</h3>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.listing_id')}</p>
            <p className='value'>{this.props.listing.listing_id}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.listing_short_name')}</p>
            <p className='value'>{this.props.listing.short_name}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.tablet_type')}</p>
            <p className='value'>{this.props.tabletType.capitalize()}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.address')}</p>
            <p className='value'>{this.props.listing.address}</p>
          </div>
        </div>
      </div>
    )
  }

  renderReservationInfo() {
    const t = this.context.t;
    const { reservation } = this.props;

    return (
      <div className='listing-infos reservation-infos'>
        <h3>Reservation</h3>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.check_in')}</p>
            {this.renderDateTime(this.timeTypes.checkin)}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.check_out')}</p>
            {this.renderDateTime(this.timeTypes.checkout)}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <p className='title'>{t('call_detail.number_of_guests')}</p>
            <p className='value'>{t(['call_detail.1_guest', 'call_detail.{n}_guests', 'n'], { n: reservation.number_of_guests })}</p>
          </div>
        </div>
        {reservation.ticket_id &&
          <div className='row'>
            <div className='col-md-12'>
              <p className='title'>{t('call_detail.ticket_page')}</p>
              <p className='value'>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_ZENS_APP_URL}/tickets/${reservation.ticket_id}?locale=en`}
                >
                  Go to page
                </a>
              </p>
            </div>
          </div>
        }
      </div>
    )
  }

  renderMessenger() {
    const roomName = this.props.roomName
    const disbaleInputMessage = this.props.disableInputMessage
    return (
      <Messenger
        roomName={roomName}
        disableInputMessage={disbaleInputMessage}
      />
    )
  }

  renderCallInformation() {
    if (this.props.isAnonymous) {
      let listingsForAnonymousCall = this.props.listingsForAnonymousCall.map(listing =>
        <li key={listing.id}>{listing.short_name}</li>
      )
      return (
        <div className="anonymous-call-info">
          <h3>Location Details</h3>
          <h4>Location ID </h4>
          <span>{this.props.placeId}</span>
          <h4>Listings related </h4>
          <ul>
            {listingsForAnonymousCall}
          </ul>
          <h4>Address</h4>
          <p>{this.props.placeAddress}</p>
        </div>
      )
    } else {
      const resInfo = this.renderReservationInfo()
      const listtingInfo = this.renderListingInfo()
      const guestsInfo = this.renderGuestInfo()
      return (
        <div>
          {listtingInfo}
          {resInfo}
          {guestsInfo}
        </div>
      )
    }
  }

  switchDetailTab(type) {
    this.setState({ tabSelect: type })
  }

  render() {
    return (
      <div className='call-detail scrollbar-custom'>
        <div className='row header'>
          <div className='col-md-12 px-0 btn-call-detail d-flex'>
            <button className={this.state.tabSelect === TAB.INFORMATION ? 'btn-select flex-fill btn rounded-0' : 'btn border-0 flex-fill rounded-0'}
              onClick={() => this.switchDetailTab(TAB.INFORMATION)}
            >
              <i className='la la-calendar pr-1' />
              <span>{TAB.INFORMATION}</span>
            </button>
            <button className={this.state.tabSelect === TAB.CHAT ? 'btn-select no-left flex-fill btn rounded-0' : 'no-left flex-fill btn rounded-0'}
              onClick={() => this.switchDetailTab(TAB.CHAT)}
            >
              <i className="la la-wechat"></i>
              <span>{TAB.CHAT}</span>
            </button>
          </div>
        </div>
        <div className='tab-detail scrollbar-custom'>
          <div className={'information-' + (this.state.tabSelect === TAB.INFORMATION ? 'display' : 'hidden')}
          >
            {this.renderCallInformation()}
          </div>
          <div className={'chat-' + (this.state.tabSelect === TAB.CHAT ? 'display' : 'hidden')}>
            {this.renderMessenger()}
          </div>
        </div>
      </div>
    )
  };
}

CallDetail.defaultProps = {
  reservation: {},
  listing: {},
  guestInfos: [],
  tabletType: ''
}

CallDetail.contextTypes = {
  t: PropTypes.func
}

export default CallDetail
