import { apiPath } from './api'

export const loadListingOptions = async (search, loadedOptions, {page}) => {
  const response = await fetch(`${apiPath('listings')}?search=${search}&page=${page}`)
  const responseJSON = await response.json()
  let hasMore = Number(responseJSON.total_page) > Number(responseJSON.current_page)
  const listings = responseJSON.listings
  listings.forEach(listing => {
    listing['value'] = listing.listing_id
    listing['label'] = listing.name
  })

  return {
    options: listings,
    hasMore: hasMore,
    additional: {
      page: page + 1
    }
  }
}

