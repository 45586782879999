import React, { Component } from 'react'
import { apiPath } from '../../utils/api'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'
import './ReservationsList.scss'
import {
  receiveReservationsInfo,
  addMoreReservations
} from 'actions/reservation'
import { connect } from 'react-redux'
import {
  updateReservationStatus,
  updateCheckinStatus,
  updateSmartLockStatus
} from 'actions/reservation-filter';

import {
  expirationReservationAlert,
  unassignedListingAlert,
  locationNotAvailableAlert,
  fullVerifiedAlert,
  guestsFillingFormAlert
} from '../ConfirmAlert/ConfirmAlert'

class CReservationsList extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    if (process.env.NODE_ENV !== 'development') {
      this.operatorName = JSON.parse(localStorage.getItem('authToken'))['authUser']['name']; // staging
    } else {
      this.operatorName = 'abc'; // local
    }

    this.state = {
      isLoadingRes: false
    }
    this.handleOnReservationsListScroll = this.handleOnReservationsListScroll.bind(this)
    this.handleResStatusFilter = this.handleResStatusFilter.bind(this)
    this.handleCheckinStatusFilter = this.handleCheckinStatusFilter.bind(this)
  }

  extractReservationsListData(data) {
    let reservationsList = data['reservations']
    let currentPage = data['pagination']['current_page']
    let totalPages = data['pagination']['total_pages']
    let loadMore = currentPage < totalPages ? true : false
    return { reservationsList: reservationsList, currentPage: currentPage, totalPages: totalPages, loadMore: loadMore }
  }

  componentDidMount() {
    let filter = this.props.reservationFilter.filter
    window.axios.get(apiPath('reservations'), { params: filter }).then(response => {
      let { reservationsList, currentPage, totalPages, loadMore } = this.extractReservationsListData(response.data)
      this.props.receiveReservationsInfo(reservationsList, currentPage, totalPages, loadMore)
    })
    this.myRef.current.addEventListener("scroll", this.handleOnReservationsListScroll)
  }

  loadMoreReservations() {
    this.setState({ isLoadingRes: true })
    let nextPage = this.props.currentPage + 1
    let filter = Object.assign({}, this.props.reservationFilter.filter)
    filter['page'] = nextPage
    window.axios.get(apiPath('reservations'), { params: filter }).then(response => {
      let { reservationsList, currentPage, totalPages, loadMore } = this.extractReservationsListData(response.data)
      this.props.addMoreReservations(reservationsList, currentPage, totalPages, loadMore)
      this.setState({ isLoadingRes: false })
    })
  }

  handleOnReservationsListScroll() {
    if (this.state.isLoadingRes) return
    let res_element = this.myRef.current
    if ((parseInt(res_element.scrollTop) + 5) >= (res_element.scrollHeight - res_element.offsetHeight)) {
      if (this.props.loadMore) {
        this.loadMoreReservations()
      }
    }
  }

  redirectToScreenCall(call) {
    this.props.history.push({
      pathname: '/call_screen',
      call: call,
      type: 'callBack',
    })
  }

  handleOnClickReservationCall(callType, resCode, onClose) {
    const t = this.context.t
    const dataRequest = {
      calling_type: callType,
      from_system: 'operator',
      operator_name: this.operatorName,
      is_check_filling_form: true,
      reservation_code: resCode
    }
    window.axios.post(apiPath('calls'), dataRequest)
      .then(response => {
        if (response.data['success'] === 'false') {
          switch (response.data.error.reason) {
            case 'guests_fully_verified':
              fullVerifiedAlert(t)
              break
            case 'reservation_expired_for_support':
            case 'reservation_expired_for_checkin':
              expirationReservationAlert(t)
              break
            case 'listing_not_assigned':
              unassignedListingAlert(t)
              break
            case 'location_not_available':
            case 'place_has_not_assigned_to_any_tablet':
              locationNotAvailableAlert(t)
              break
            case 'guests_not_filling_form':
              guestsFillingFormAlert(t)
              break
            default:
              console.log(response.data)
              break
          }
        } else {
          onClose()
          this.redirectToScreenCall(response.data.call)
        }
      })
  }

  handleOnClickOnReservationCallBtn(resCode) {
    const t = this.context.t
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="reservation-alert">
            <p className="title">{t('reservationsList.asking_calling_type.title')}</p>
            <button className="btn-call checkin" onClick={(e) => { e.target.disabled = true; e.target.nextElementSibling.disabled = true; this.handleOnClickReservationCall("checkin", resCode, onClose) }}>Checkin</button>
            <button className="btn-call support" onClick={(e) => { e.target.disabled = true; e.target.previousSibling.disabled = true; this.handleOnClickReservationCall("support", resCode, onClose) }}>Guest Support</button>
            <button id="" className="btn-cancel" onClick={onClose}><i className="la la-close"></i></button>
          </div>
        )
      }
    })
  }

  renderSmartLockStatus(res) {
    if (res && res.listing && res.listing.door_code_type === "smart") {
      let resEndDate = new Date(res.end_date)
      let currentDate = new Date()

      resEndDate.setHours(0, 0, 0, 0)
      currentDate.setHours(0, 0, 0, 0)

      if (currentDate > resEndDate) {
        return "Expired"
      } else {
        switch (res.smart_lock_used) {
          case true:
            return "Yes"
          case false:
            return "No"
          default:
            return ''
        }
      }

    } else {
      return ""
    }
  }

  isCheckedInReservation(res) {
    let numGuestsVerified = res.guests.filter(guest => guest['status'] === "verified")
    if (numGuestsVerified.length >= 1) {
      return true
    }
    return false
  }

  renderCheckinStatus(status) {
    if (status) {
      return (
        <div className="res-status checkin">Checked In</div>
      )
    } else {
      return (
        <div className="res-status not">No</div>
      )
    }
  }

  filterReservationList = (filter) => {
    filter = filter || this.props.reservationFilter.filter
    window.axios.get(apiPath('reservations'), { params: filter }).then(response => {
      let { reservationsList, currentPage, totalPages, loadMore } = this.extractReservationsListData(response.data)
      this.props.receiveReservationsInfo(reservationsList, currentPage, totalPages, loadMore)
    })
  }

  handleResStatusFilter(resStatus) {
    this.props.updateReservationStatus(resStatus)
    let filter = Object.assign({}, this.props.reservationFilter.filter)
    filter.reservation_status = resStatus
    this.filterReservationList(filter)
  }

  handleCheckinStatusFilter(checkinStatus) {
    this.props.updateCheckinStatus(checkinStatus)
    let filter = Object.assign({}, this.props.reservationFilter.filter)
    filter.checkin_status = checkinStatus
    this.filterReservationList(filter)
  }

  handleSmartLockStatusFilter(smartLockStatus) {
    this.props.updateSmartLockStatus(smartLockStatus)
    let filter = Object.assign({}, this.props.reservationFilter.filter)
    filter.in_use = smartLockStatus
    this.filterReservationList(filter)
  }

  renderReservationStatusFilter() {
    return (
      <div className='dropdown ml-3 reservation-status-filter'>
        <span className='color-dark line-height-24'><b>Reservation Status:</b></span>
        <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <b className='color-black'>{filterGroup.reservationStatuses[this.props.reservationFilter.filter.reservation_status]}</b>
          <span className='la la-angle-down line-height-15 ml-3' />
        </button>
        <div className='dropdown-menu calling-type-list' aria-labelledby="dateRangeDropdown">
          {
            Object.keys(filterGroup.reservationStatuses).map(key => {
              return (
                <button key={key} className='btn dropdown-item color-dark' type='button' onClick={() => (this.handleResStatusFilter(key))}>
                  {filterGroup.reservationStatuses[key]}
                </button>
              )
            })
          }
        </div>
      </div>
    )
  }

  renderCheckinStatusFilter() {
    return (
      <div className='dropdown ml-3 checkin-status-filter'>
        <span className='color-dark line-height-24'><b>Checkin Status:</b></span>
        <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <b className='color-black'>{filterGroup.checkinStatuses[this.props.reservationFilter.filter.checkin_status]}</b>
          <span className='la la-angle-down line-height-15 ml-3' />
        </button>
        <div className='dropdown-menu calling-type-list' aria-labelledby="dateRangeDropdown">
          {
            Object.keys(filterGroup.checkinStatuses).map(key => {
              return (
                <button key={key} className='btn dropdown-item color-dark' type='button' onClick={() => (this.handleCheckinStatusFilter(key))}>
                  {filterGroup.checkinStatuses[key]}
                </button>
              )
            })
          }
        </div>
      </div>
    )
  }

  renderSmartLockStatusFilter() {
    return (
      <div className='dropdown ml-3 checkin-status-filter'>
        <span className='color-dark line-height-24'><b>In Use:</b></span>
        <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <b className='color-black'>{filterGroup.smartLockStatuses[this.props.reservationFilter.filter.in_use]}</b>
          <span className='la la-angle-down line-height-15 ml-3' />
        </button>
        <div className='dropdown-menu calling-type-list' aria-labelledby="dateRangeDropdown">
          {
            Object.keys(filterGroup.smartLockStatuses).map(key => {
              return (
                <button key={key} className='btn dropdown-item color-dark' type='button' onClick={() => (this.handleSmartLockStatusFilter(key))}>
                  {filterGroup.smartLockStatuses[key]}
                </button>
              )
            })
          }
        </div>
      </div>
    )
  }

  renderReservationAction(res) {
    let resEndDate = new Date(res.end_date)
    let resStartDate = new Date(res.start_date)
    let currentDate = new Date()
    resEndDate.setHours(0, 0, 0, 0)
    resStartDate.setHours(0, 0, 0, 0)
    currentDate.setHours(0, 0, 0, 0)
    let disabledCallButton = (currentDate >= resStartDate && currentDate <= resEndDate) ? false : true
    return (
      <div className='btn-group d-flex justify-content-center'>
        <div className='d-flex justify-content-center'>
          {disabledCallButton ? (
            <button type="button" className='btn-action-call disabled' onClick={() => this.handleOnClickOnReservationCallBtn(res.code)} disabled>CALL</button>
          ) : (
              <button type="button" className='btn-action-call' onClick={() => this.handleOnClickOnReservationCallBtn(res.code)}>CALL</button>
            )
          }
          <button onClick={e => this.props.handleClickOnDetailedItem({ type: "ReservationSelect", data: { reservation: res } })} className='action-reservation-detail'>
            <i className='la la-info-circle' />
          </button>
        </div>
      </div>
    )
  }

  formatDate(date) {
    return moment(new Date(date)).format("YYYY/MM/DD")
  }

  render() {
    let reservationListRows = this.props.reservationsList.map(res => {
      let smartLockStatus = this.renderSmartLockStatus(res)
      let checkInStatus = this.isCheckedInReservation(res)
      let resRowClassName = (smartLockStatus === 'Yes' && !checkInStatus) ? 'tvalue row high-light' : 'tvalue row'
      return (
        <div className={resRowClassName} key={res.id}>
          <div className='col-md-1 col-lg-1 text-center'>{res.id}</div>
          <div className='col-md-2 col-lg-2 text-center'>{res.listing && res.listing.short_name}</div>
          <div className='col-md-1 col-lg-1 text-center'>{res.place_id}</div>
          <div className='col-md-1 col-lg-1 text-center'>{res.guest_name}</div>
          <div className='col-md-1 col-lg-1 text-center'>{res.place && res.place.place_type.capitalize()}</div>
          <div className='col-md-1 col-lg-1 pl-2 text-center'>{this.formatDate(res.start_date)}</div>
          <div className='col-md-1 col-lg-1 text-center'>{smartLockStatus}</div>
          <div className='col-md-2 col-lg-2 text-center'>{this.renderCheckinStatus(checkInStatus)}</div>
          <div className='col-md-2 col-lg-2 text-center'>{this.renderReservationAction(res)}</div>
        </div>)
    })

    return (
      <div className="reservations-list-container">
        <div className='row'>
          <div className='col-md-6 offset-md-6'>
            <div className='d-flex float-right search-filter-group'>
              {this.renderSmartLockStatusFilter()}
              {this.renderCheckinStatusFilter()}
              {this.renderReservationStatusFilter()}
            </div>
          </div>
        </div>
        <hr />
        <div className="table-reservations">
          <div className='thead row'>
            <div className='col-md-1 col-lg-1 text-center'>Reservation ID</div>
            <div className='col-md-2 col-lg-2 text-center'>Listing</div>
            <div className='col-md-1 col-lg-1 text-center'>Location ID</div>
            <div className='col-md-1 col-lg-1 text-center'>Account</div>
            <div className='col-md-1 col-lg-1 text-center'>Tablet Type</div>
            <div className='col-md-1 col-lg-1 text-center'>Start Date</div>
            <div className='col-md-1 col-lg-1 text-center'>Smart Lock</div>
            <div className='col-md-2 col-lg-2 text-center'>Check-in Status</div>
            <div className='col-md-2 col-lg-2 text-center'>Actions</div>
          </div>
          <div className="table-content" ref={this.myRef}>
            {reservationListRows}
            {
              !this.props.reservationsList.length &&
              <div className='col text-center res-not-found'>No Reservations Found</div>
            }
            {this.state.isLoadingRes && <div>Loading...</div>}
          </div>
        </div>
      </div>
    )
  }
}

CReservationsList.contextTypes = {
  t: PropTypes.func
}

const filterGroup = {
  reservationStatuses: {
    all: 'All',
    available: 'Available',
    expired: 'Expired',
    upcoming: 'Upcoming'
  },

  checkinStatuses: {
    all: 'All',
    checkin: 'Checked In',
    no: 'Not Checked In Yet'
  },

  smartLockStatuses: {
    all: 'All',
    yes: 'Yes',
    no: 'No'
  }
}

const mapStateToProps = state => ({
  reservationsList: state.reservation.reservationsList,
  currentPage: state.reservation.currentPage,
  loadMore: state.reservation.loadMore,
  totalPages: state.reservation.totalPages,
  reservationFilter: state.reservationFilter
})

const mapDispatchToProps = {
  receiveReservationsInfo: receiveReservationsInfo,
  addMoreReservations: addMoreReservations,
  updateCheckinStatus: updateCheckinStatus,
  updateReservationStatus: updateReservationStatus,
  updateSmartLockStatus: updateSmartLockStatus
}

const RReservationsList = withRouter(CReservationsList)

const ReservationsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(RReservationsList)

export default ReservationsList