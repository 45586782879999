export const LIST_CALLS_ACTIONS = {
  FETCHING_HISTORY_CALLS: 'FETCHING_HISTORY_CALLS',
  RECEIVE_HISTORY_CALLS: 'RECEIVE_HISTORY_CALLS',
  FETCHING_INCOMING_CALLS: 'FETCHING_INCOMING_CALLS',
  RECEIVE_INCOMING_CALLS: 'RECEIVE_INCOMING_CALLS',
  RECEIVE_NOTIFICATION_CALLS: 'RECEIVE_NOTIFICATION_CALLS',
  RECEIVE_INCOMING_CALL: 'RECEIVE_INCOMING_CALL',
  RECEIVE_NOTIFICATION_CALL: 'RECEIVE_NOTIFICATION_CALL',
  IGNORE_INCOMING_CALL: 'IGNORE_INCOMING_CALL',
  RECEIVE_HISTORY_CALL: 'RECEIVE_HISTORY_CALL',
  REMOVE_INCOMING_CALL: 'REMOVE_INCOMING_CALL',
}

export const fetchingHistoryCalls = () => ({
  type: LIST_CALLS_ACTIONS.FETCHING_HISTORY_CALLS
})

export const receiveHistoryCalls = (calls) => ({
  type: LIST_CALLS_ACTIONS.RECEIVE_HISTORY_CALLS,
  payload: {
    historyCalls: calls
  }
})

export const fetchingIncomingCalls = () => ({
  type: LIST_CALLS_ACTIONS.FETCHING_INCOMING_CALLS
})

export const receiveIncomingCalls = (calls) => ({
  type: LIST_CALLS_ACTIONS.RECEIVE_INCOMING_CALLS,
  payload: {
    incomingCalls: calls
  }
})

export const receiveIncomingCall = (call) => ({
  type: LIST_CALLS_ACTIONS.RECEIVE_INCOMING_CALL,
  payload: {
    incomingCall: call
  }
})

export const receiveNotificationCalls = (calls) => ({
  type: LIST_CALLS_ACTIONS.RECEIVE_NOTIFICATION_CALLS,
  payload: {
    notificationCalls: calls
  }
})

export const receiveNotificationCall = (call) => ({
  type: LIST_CALLS_ACTIONS.RECEIVE_NOTIFICATION_CALL,
  payload: {
    notificationCall: call
  }
})

export const ignoreIncomingCall = (call) => ({
  type: LIST_CALLS_ACTIONS.IGNORE_INCOMING_CALL,
  payload: {
    incomingCall: call
  }
})

export const receiveHistoryCall = (call) => ({
  type: LIST_CALLS_ACTIONS.RECEIVE_HISTORY_CALL,
  payload: {
    historyCall: call
  }
})

export const removeIncomingCall = (call) => ({
  type: LIST_CALLS_ACTIONS.REMOVE_INCOMING_CALL,
  payload: {
    incomingCall: call
  }
})