import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import './Layout.css'
class CLayout extends Component {

  render(){
    return (
      <div className="main-wrapper layout">
        <div className="page-wrapper">
          {this.props.children}
        </div>
      </div>
    )
  }
}

const RLayout = withRouter(CLayout)

const mapStateToProps = (state) => ({
  reduxTokenAuth: state.reduxTokenAuth
})

const mapDispatchToProps = {
}

const Layout =  connect(
  mapStateToProps,
  mapDispatchToProps
)(RLayout)

export default Layout
