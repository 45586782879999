import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  updateSearch
} from 'actions/reservation-filter';
import {  receiveReservationsInfo } from 'actions/reservation'
import { apiPath } from '../../utils/api'

class CSearchBarOnReservationList extends Component {
  constructor(props) {
    super(props)

    this.handleSearchForListingOnReservationList = this.handleSearchForListingOnReservationList.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)

    this.state = {
      timeOutId: null,
      showClosedButton: false
    }
  }

  componentDidMount() {
    if (this.props.reservationFilter.filter.q) {
      this.setState({showClosedButton: true})
      document.getElementsByClassName('searh-form')[0].value = this.props.reservationFilter.filter.q
    }
  }

  extractReservationsListData(data) {
      let reservationsList = data['reservations']
      let currentPage = data['pagination']['current_page']
      let totalPages = data['pagination']['total_pages']
      let loadMore = currentPage < totalPages ? true : false
      return {reservationsList: reservationsList, currentPage: currentPage, totalPages: totalPages, loadMore: loadMore}
  }

  filterReservationList = (filter) => {
    filter = filter || this.props.reservationFilter.filter
    window.axios.get(apiPath('reservations'), {params: filter}).then(response => {
      let { reservationsList, currentPage, totalPages, loadMore } = this.extractReservationsListData(response.data)
      this.props.receiveReservationsInfo(reservationsList, currentPage, totalPages, loadMore)
    })
  }

  handleSearchForListingOnReservationList(e={}) {
    if (Object.keys(e).length === 0 && e.constructor === Object) {
      this.setState({showClosedButton: false})
    }

    if (!(Object.keys(e).length === 0 && e.constructor === Object)) {
      (e.target.value !== "") ? this.setState({showClosedButton: true}) : this.setState({showClosedButton: false})
    }

    if (this.state.timeOutId) {
      clearTimeout(this.state.timeOutId)
    }

    let q = e.target ? e.target.value : null
    this.setState({timeOutId: setTimeout(() => {
      this.props.updateSearch(q)
      let filter = Object.assign({}, this.props.reservationFilter.filter)
      filter.q = q
      this.filterReservationList(filter)
    },1000)})
  }

  handleClearSearch() {
    // clear input form
    document.getElementsByClassName('searh-form')[0].value = ''
    this.handleSearchForListingOnReservationList()
  }

  renderShowClosedButton() {
    if(this.state.showClosedButton) {
      return(
        <button className='btn btn-search-clear p-0 border-0' type='button' data-tooltip='tooltip' title='Clear Search' onClick={this.handleClearSearch} >
          <i className='la la-close color-dark' />
        </button>
      )
    }
  }
  render() {
    return (
      <div className='flex-fill search-for-listing color-dark'>
        <form onSubmit={e => e.preventDefault()} className='form-group mb-0'>
          <div className='input-group'>
            <div className='input-group-prepend py-1'>
              <i className='la la-search'></i>
            </div>
            <input className='form-control mb-0 border-0 searh-form color-dark' placeholder='Search for listing'
              onChange={this.handleSearchForListingOnReservationList}
            />
            { this.renderShowClosedButton() }
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reservationFilter: state.reservationFilter
})

const mapDispatchToProps = {
  receiveReservationsInfo: receiveReservationsInfo,
  updateSearch: updateSearch
}

const SearchBarOnReservationList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CSearchBarOnReservationList)

export default SearchBarOnReservationList;