import React from 'react'
import './CallHistoryDetail.scss'
import PropTypes from 'prop-types'

class IncommingCallDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tabDetailSelect: 1
        }
    }

    renderTime(h) {
        if (h === null || h === '') {
            return 'None'
        }
        if (h.toUpperCase() === 'FLEXIBLE' || h.toUpperCase() === 'NOT_SELECTED') {
            return '(flexible)'
        }
        let time = h.split(":")[0]
        return Number(time) <= 12 ? `${time}:00 AM` : `${Number(time) - 12}:00 PM`
    }

    renderCheckinStatus() {
        let t = this.context.t
        let { reservation } = this.props
        if (!reservation) return ''
        let checkinStatus = 'not_check_in_yet'
        let guests = reservation.guests
        let verifiedGuest = guests.find(g => g.status === 'verified')
        if (reservation.checked) {
            checkinStatus = 'check_in_successfully'
        } else if (verifiedGuest) {
            checkinStatus = 'checkin_not_enough'
        }
        return t(`history_call_detail.status.${checkinStatus}`)
    }

    renderListingInfo() {
        return (
            <div className='listing-infos'>
                <div className='row'>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Listing ID</p>
                        <p className='value'>{this.props.listing && this.props.listing.listing_id}</p>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Listing Short Name</p>
                        <p className='value'>{this.props.listing && this.props.listing.short_name}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Address</p>
                        <p className='value'>{this.props.listing && this.props.listing.address}</p>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Tablet Type</p>
                        <p className='value'>{this.props.reservation.place ? this.props.reservation.place.place_type.capitalize() : this.props.tabletType.capitalize()}</p>
                    </div>
                </div>
            </div>
        )
    }

    renderReservationInfo() {
        return (
            <div className='listing-infos reservation-infos'>
                <div className='row'>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Checkin</p>
                        <p className='value'>{this.props.reservation && this.renderTime(this.props.reservation.checkin_time)}</p>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Checkout</p>
                        <p className='value'>{this.props.reservation && this.renderTime(this.props.reservation.checkout_time)}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Start date</p>
                        <p className='value'>{this.props.reservation && this.props.reservation.start_date.replace(/-/g, '/')}</p>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>End date</p>
                        <p className='value'>{this.props.reservation && this.props.reservation.end_date.replace(/-/g, '/')}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Number of Guests</p>
                        <p className='value'>{this.props.reservation && this.props.reservation.number_of_guests}</p>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <p className='title'>Check-in Status</p>
                        <p className='value'>
                            {this.renderCheckinStatus()}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderGuestInfo() {
        return (
            <div className='table-guest-infos'>
                <div className='thead row'>
                    <div className='col-md-2 col-lg-2'>Name</div>
                    <div className='col-md-3 col-lg-3 text-center'>Address</div>
                    <div className='col-md-2 col-lg-2 text-center'>Nationality</div>
                    <div className='col-md-2 col-lg-2 text-center'>Occupation</div>
                    <div className='col-md-3 col-lg-3 text-center'>Passport Number</div>
                </div>
                <hr />
                {this.props.guests &&
                    this.props.guests.map(guest => {
                        let guestInfo = guest['zens_data']
                        return (
                            <div key={guestInfo.id} className='tvalue row'>
                                <div className='col-md-2 col-lg-2'>{guestInfo.full_name}</div>
                                <div className='col-md-3 col-lg-3 text-center'>
                                    {`${guestInfo.street_1}, ${guestInfo.city}, ${guestInfo.state}, ${guestInfo.country}, ${guestInfo.postal_code}`}
                                </div>
                                <div className='col-md-2 col-lg-2 text-center'>{guestInfo.nationality}</div>
                                <div className='col-md-2 col-lg-2 text-center'>{guestInfo.occupation}</div>
                                <div className='col-md-3 col-lg-3 text-center'>{guestInfo.passport_number}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    switchTab(type) {
        this.setState({ tabDetailSelect: type })
    }

    renderTab(tabSelect) {
        switch (tabSelect) {
            case 0:
                return this.renderGuestInfo()
            case 1:
                return this.renderReservationInfo()
            case 2:
                return this.renderListingInfo()
            default:
                return
        }
    }
    render() {
        return (
            <div className="call-history">
                <div className='detail-content'>
                    {this.renderTab(this.state.tabDetailSelect)}
                </div>
                <div className='right-tab'>
                    <ul>
                        <li className={this.state.tabDetailSelect === 0 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 0)}>
                            <i className='la la-user right-20' />Guest details
                    </li>
                        <li className={this.state.tabDetailSelect === 1 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 1)}>
                            <i className='la la-building-o right-20' />Reservation detail
                    </li>
                        <li className={this.state.tabDetailSelect === 2 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 2)}>
                            <i className='la la-map-pin right-20' />Listing Info
                    </li>
                    </ul>
                </div>
            </div>
        )
    }
}

IncommingCallDetail.contextTypes = {
    t: PropTypes.func
}

export default IncommingCallDetail 