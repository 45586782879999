import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'configs/global.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './IncomingCallNotificationBar.scss'

class CIncomingCallNotificationBar extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  renderIncomingName() {
    let call = this.props.notificationCalls[0]
    if (call.reservation) {
      let listing = call.reservation.listing
      return listing && listing.short_name
    } else {
      return 'Anonymous'
    }
  }

  render() {
    let notificationCalls = this.props.notificationCalls
    const notificationCallLength = notificationCalls.length
    return (
      <div className='nav-noti-group'>
        <TransitionGroup>
          {
            notificationCalls.map((call, index) => {
              return (
                <CSSTransition key= {call.id} timeout={500} classNames='my-node'>
                  <div key={call.id} className='row noti-bar' style={{zIndex: notificationCallLength - index}}>
                    <div className='col-md-6 col-lg-6'>
                      <div>
                        <span>{ this.renderIncomingName() }</span>
                      </div>
                      <div>
                        <span className='mr-1'>{call.tablet_type.capitalize()}</span>
                        <span className='mr-1'>-</span>
                        <span className='ml-1'>{call.calling_type.capitalize()}</span>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-6 noti-group-btn text-right'>
                      <button onClick={() => this.props.receiveCall(call)} className='btn btn-success btn-noti-call'><FontAwesomeIcon icon='phone' className='mr-2 take-phone-icon'/>TAKE CALL</button>
                      <button onClick={() => this.props.dismissCall(call)} className='btn btn-danger btn-noti-call'>I'M BUSY</button>
                    </div>
                  </div>
                </CSSTransition>
              )
            })
          }
        </TransitionGroup>
      </div>
    )
  }
}

CIncomingCallNotificationBar.defaultProps = {
  listCalls: {},
  incomingCalls: [],
  notificationCalls: [],
}
const mapStateToProps = state => ({
  historyCalls: state.listCalls.historyCalls,
  notificationCalls: state.listCalls.notificationCalls,
})

const mapDispatchToProps = {
}

const IncomingCallNotificationBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CIncomingCallNotificationBar)

export default IncomingCallNotificationBar