import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux'
import { store } from './store/root'
import './configs/axios'
import { Routes } from './routes'
import I18n from "redux-i18n"
import { translations } from "./configs/locales/root"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'react-confirm-alert/src/react-confirm-alert.css'
library.add(fas)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <I18n translations={translations} initialLang="en">
          <Routes />
        </I18n>
      </Provider>
    );
  }
}

export default App;