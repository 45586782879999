import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment';

import { apiPath } from '../../utils/api';
import { updateCallFilter } from 'actions/call-filter';
import {
  expirationReservationAlert,
  unassignedListingAlert,
  locationNotAvailableAlert,
  fullVerifiedAlert,
} from '../ConfirmAlert/ConfirmAlert'
import ZenDateRangePicker from '../ZenDateRangePicker/ZenDateRangePicker';
import {
  fetchingHistoryCalls,
  receiveHistoryCalls,
} from 'actions/list-calls'
import { receiveListings } from 'actions/listing'
import './HistoryCalls.scss'

class CHistoryCalls extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.handleCallBack = this.handleCallBack.bind(this)
    this.handleTimeRangeFilter = this.handleTimeRangeFilter.bind(this)
    this.handleCallTypeFilter = this.handleCallTypeFilter.bind(this)
    this.makeHistoryFilter = this.makeHistoryFilter.bind(this)
    this.state = {
      callStatusSelect: 0,
      activeHistoryPage: 1,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.operatorName = JSON.parse(localStorage.getItem('authToken'))['authUser']['name'];

    this.myRef.current.addEventListener("scroll", () => {
      const {
        state: {
          isLoading,
        },
      } = this;
      if (isLoading || !this.props.callFilter.filter.hasMore) return;

      const obj = this.myRef.current
      if ((parseInt(obj.scrollTop) + 5) >= (obj.scrollHeight - obj.offsetHeight)) {
        this.loadHistoryCalls();
      }
    })

    // BEGIN: prevent date picker dropdown closing

    document.getElementById('toggle-date-picker').addEventListener('click', (e) => {
      e.stopPropagation()
      document.getElementById('dateRangeDropdown').classList.toggle('show')
    })

    window.onclick = (event) => {
      if (!event.target.matches('.date-range-toggle, .rdrNextPrevButton, .rdrNextPrevButton i, .rdrDays span')) {
        let dropdowns = document.getElementsByClassName('dropdown-content');
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          let openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show')
          }
        }
      }
    }

    // END
  }

  getUnique(arr, comp) {
    const unique = arr.map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  }

  makeHistoryFilter() {
    const self = this
    // reset  begin_id, status (load_more) before make new filter
    this.setState({ isLoading: false })
    let { filter } = this.props.callFilter
    filter = Object.assign(filter, {
      begin_id: null,
      status: null,
      hasMore: true
    })

    // update filter into store
    this.props.updateCallFilter(filter)
    // make request to get data
    console.log(this.props.callFilter.filter)
    axios.get(apiPath('calls/list_calls'), { params: this.props.callFilter.filter })
      .then(response => {
        let historyCalls = response.data['missed_calls'].concat(response.data['completed_calls'])
        let listings = response.data.listings
        historyCalls.map(call => {
          if (!call.reservation) {
            return call
          } else {
            let callListing = listings.find(item => { return item.listing_id === call.reservation.listing_id })
            if (callListing) {
              return call.reservation.listing = callListing
            } else {
              return call
            }
          }
        })
        self.props.receiveListings(listings)
        self.props.receiveHistoryCalls(historyCalls)
      })
  }

  loadHistoryCalls() {
    const self = this
    let beginID
    let missedCalls = this.missedCalls()
    let completedCalls = this.completedCalls()
    if (this.state.callStatusSelect === 0) {
      beginID = missedCalls[missedCalls.length - 1].id
    } else {
      beginID = completedCalls[completedCalls.length - 1].id
    }

    let { filter } = { ...this.props.callFilter }
    filter = Object.assign(filter, {
      begin_id: beginID,
      status: this.state.callStatusSelect
    })
    this.setState({ isLoading: true })
    window.axios.get(apiPath('calls/history_calls'), { params: filter })
      .then(response => {
        let state = { ...this.state }
        let calls = response.data['calls']
        let listings = this.getUnique(this.props.listings.concat(response.data.listings), 'listing_id')
        if (calls.length === 0) {
          filter.hasMore = false
        }
        else {
          let historyCalls = this.props.historyCalls.concat(calls)
          historyCalls.map(call => {
            if (!call.reservation) return call
            let callListing = listings.find(item => {
              return item.listing_id === call.reservation.listing_id
            })
            if (callListing) {
              return call.reservation.listing = callListing
            }
            return call
          })
          self.props.receiveHistoryCalls(historyCalls)
          self.props.receiveListings(listings)
        }
        state.historyPageLoad += 1;
        state.isLoading = false
        this.props.updateCallFilter(filter)
        this.setState(state)
      })
  }

  handleTimeRangeFilter(ranges) {
    let { filter } = { ...this.props.callFilter }
    filter = Object.assign(filter, {
      start_date: ranges.selection.startDate ? moment(new Date(ranges.selection.startDate)).format("YYYY/MM/DD") : null,
      end_date: ranges.selection.endDate ? moment(new Date(ranges.selection.endDate)).format("YYYY/MM/DD") : null
    })

    this.props.updateCallFilter(filter)
    this.makeHistoryFilter();
  }

  handleCallTypeFilter(callTypes) {
    let { filter } = { ...this.props.callFilter }
    filter = Object.assign(filter, {
      calling_type: [callTypes]
    })
    this.props.updateCallFilter(filter)
    this.makeHistoryFilter()
  }

  switchCallStatus(type) {
    this.setState({ callStatusSelect: type, activeHistoryPage: 1 })
  }

  renderBtnCallStatus(type) {
    return this.state.callStatusSelect === type ? 'btn btn-calling-type select' : 'btn btn-calling-type'
  }

  renderCallingType(type) {
    return (type === 'checkin' ? (<div className='type-checkin'>Checkin</div>) : (<div className='type-support'>Support Needed</div>))
  }

  renderDateRangeInfo() {
    let { filter } = { ...this.props.callFilter }
    if (filter.start_date && filter.end_date) {
      return (
        <div className='ml-3 color-black'>
          <span><b className='start-date-presence'>{moment(new Date(filter.start_date)).format("YYYY/MM/DD")}</b></span>
          <span><b className='mx-1'>-</b></span>
          <span><b className='end-date-presence'>{moment(new Date(filter.end_date)).format("YYYY/MM/DD")}</b></span>
        </div>
      )
    } else {
      return (<b className='ml-1 color-black '>ALL</b>)
    }
  }

  redirectToScreenCall(call) {
    this.props.history.push({
      pathname: '/call_screen',
      call: call,
      type: 'callBack',
    })
  }

  handleReservationCallback(historyCall, callActionEvent) {
    const t = this.context.t
    const dataRequest = {
      calling_type: historyCall.calling_type,
      from_system: 'operator',
      operator_name: this.operatorName,
      reservation_code: historyCall.reservation.code
    }
    axios.post(apiPath('calls'), dataRequest).then(response => {
      if (response.data['success'] === 'false') {
        callActionEvent.target.disabled = false
        switch (response.data.error.reason) {
          case 'guests_fully_verified':
            fullVerifiedAlert(t)
            break
          case 'reservation_expired_for_support':
          case 'reservation_expired_for_checkin':
            expirationReservationAlert(t)
            break
          case 'listing_not_assigned':
            unassignedListingAlert(t)
            break
          case 'device_removed':
            locationNotAvailableAlert(t)
            break
          default:
            break
        }
      } else {
        const call = response.data['call']
        call['reservation']['listing'] = historyCall.reservation.listing
        this.redirectToScreenCall(call)
      }
    })
  }

  handleAnonymousCallback(historyCall, callActionEvent) {
    const t = this.context.t
    let requestedData = {
      calling_type: 'support',
      tablet_type: historyCall.tablet_type,
      place_id: historyCall.place_id,
      from_system: 'operator',
      operator_name: this.operatorName,
    }
    axios.post(apiPath('calls'), requestedData).then(response => {
      if (response.data['success'] === "false" && response.data['error']['error_code'] === 1789) {
        locationNotAvailableAlert(t)
        callActionEvent.target.disabled = false
      } else {
        this.redirectToScreenCall(response.data['call'])
      }
    })
  }

  handleCallBack(e, historyCall) {
    e.persist()
    e.target.disabled = true
    if (historyCall.reservation) {
      this.handleReservationCallback(historyCall, e)
    } else {
      this.handleAnonymousCallback(historyCall, e)
    }
  }

  renderBtnHistoryAction(call, tabletType) {
    const disabledClass = call.reservation ? '' : 'disabled'
    return (
      <div className='btn-group'>
        <button className='btn-accept-call' onClick={e => this.handleCallBack(e, call)}>CALL BACK</button>
        <button onClick={e => this.props.handleClickOnDetailedItem({ type: "HistoryCallSelect", data: call })} className={`btn-detail-call-center ${disabledClass}`}>
          <i className='la la-info-circle' />
        </button>
      </div>
    )
  }


  renderTimeRangeFilter() {
    return (
      <div className='dropdown time-range-filter d-flex '>
        <span className='color-dark line-height-24'><b>TIME RANGE:</b></span>

        <button className='btn btn-primary bg-transparent border-0 dropdown-toggle py-0 date-range-toggle d-flex' id='toggle-date-picker' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {
            this.renderDateRangeInfo()
          }
          <span className='la la-angle-down ml-3 line-height-16 color-dark' />
        </button>
        <div id='dateRangeDropdown' className='dropdown-menu dropdown-content p-0' aria-labelledby="dateRangeDropdown">
          <div className='dropdown-item p-0'>
            <ZenDateRangePicker handleTimeRangeFilter={this.handleTimeRangeFilter} />
          </div>
        </div>
      </div>
    )
  }

  renderCallTypeFilter() {
    return (
      <div className='dropdown ml-3 call-type-filter'>
        <span className='color-dark line-height-24'><b>CALL TYPE:</b></span>
        <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <b className='color-black'>{callTypeList.find((item) => item.key === this.props.callFilter.filter.calling_type[0]).value.toUpperCase()}</b>
          <span className='la la-angle-down line-height-15 ml-3' />
        </button>
        <div className='dropdown-menu calling-type-list' aria-labelledby="dateRangeDropdown">
          {
            callTypeList.map(ct => {
              return (
                <button key={ct.key} className='btn dropdown-item color-dark' type='button' onClick={e => this.handleCallTypeFilter(ct.key)}>{ct.value}</button>
              )
            })
          }
        </div>
      </div>
    )
  }

  renderStatusFilter() {
    const statusList = [
      {
        key: 'all',
        value: 'ALL'
      },
      {
        key: 'solved',
        value: 'Solved'
      },
      {
        key: 'unsolved',
        value: 'Unsolved'
      },
    ]
    return (
      <div className='status-filter'>
        <span className='color-dark'><b>STATUS:</b></span>
        <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className='la la-angle-down line-height-15' />
        </button>
        <div className='dropdown-menu' aria-labelledby="dateRangeDropdown">
          {
            statusList.map(ct => {
              return (
                <button key={ct.key} className='btn dropdown-item' type='button'>{ct.value}</button>
              )
            })
          }
        </div>
      </div>

    )
  }

  renderSearchFilterGroup() {
    return (
      <div className='d-flex float-right search-filter-group'>
        {
          this.renderTimeRangeFilter()
        }
        {
          this.renderCallTypeFilter()
        }
      </div>
    )
  }

  missedCalls() {
    return this.props.historyCalls.filter(call => { return call.status === "missed" })
  }

  completedCalls() {
    return this.props.historyCalls.filter(call => { return call.status === "completed" })
  }

  filterHistoryCall() {
    return this.state.callStatusSelect === 0 ? this.missedCalls() : this.completedCalls()
  }

  guestClassName(call) {
    if (!call.reservation && this.state.callStatusSelect === 0) {
      return 'anonymous-guest'
    }
  }

  renderOperatorName(call) {
    return this.state.callStatusSelect === 0 ? call.guest_name : call.operator_name
  }

  render() {
    const filteredHistoryCalls = this.filterHistoryCall()
    const renderingFilteredHistoryCalls = filteredHistoryCalls.map((call) =>
      <div className='tvalue row' key={call.id}>
        <div className='col-md-2 col-lg-2'>{call.reservation ? call.reservation.code : 'None'}</div>
        <div className='col-md-2 col-lg-2 text-center'>{call.reservation && call.reservation.listing ? call.reservation.listing.short_name : 'None'}</div>
        <div className={`col-md-2 col-lg-2 text-center ${this.guestClassName(call)}`}>{this.renderOperatorName(call)}</div>
        <div className='col-md-2 col-lg-2 text-center'>{this.renderCallingType(call.calling_type)}</div>
        <div className='col-md-2 col-lg-2 text-center missed-time'>{moment(new Date(call.created_at)).format("hh:mm A, YYYY/MM/DD")}</div>
        <div className='col-md-2 col-lg-2 text-center'>{this.renderBtnHistoryAction(call, call.tablet_type)}</div>
      </div>
    )
    return (
      <div className="history-calls-container">
        <div className='row'>
          <div className='col-md-6'>
            <button className={this.renderBtnCallStatus(0)} onClick={this.switchCallStatus.bind(this, 0)}>MISSED CALLS</button>
            <button className={this.renderBtnCallStatus(1)} onClick={this.switchCallStatus.bind(this, 1)}>CONNECTED CALLS</button>
          </div>
          <div className='col-md-6'>
            {
              this.renderSearchFilterGroup()
            }
          </div>
        </div>
        <hr />
        <div className='table-list-calls'>
          <div className='thead row'>
            <div className='col-md-2 col-lg-2'>Reservation</div>
            <div className='col-md-2 col-lg-2 text-center'>Listing</div>
            <div className='col-md-2 col-lg-2 text-center'>{this.state.callStatusSelect === 0 ? "Guest's Name" : "Operator Name"}</div>
            <div className='col-md-2 col-lg-2 text-center'>Call Type</div>
            <div className='col-md-2 col-lg-2 text-center'>Time</div>
            <div className='col-md-2 col-lg-2 text-center'>Actions</div>
          </div>
          <div ref={this.myRef} className="table-list-content">
            {renderingFilteredHistoryCalls}
            {
              !filteredHistoryCalls.length &&
              <div className='col text-center nocall-noti'>No Call Log Matched</div>
            }
            {this.state.isLoading && this.props.callFilter.filter.hasMore && <div className='text-loading'>Loading...</div>}
          </div>
        </div>
      </div>
    )
  }
}

const callTypeList = [
  {
    key: 'all',
    value: 'All'
  },
  {
    key: 'checkin',
    value: 'Checkin'
  },
  {
    key: 'support',
    value: 'Support Needed'
  },
]

CHistoryCalls.defaultProps = {
  listCalls: {},
  historyCalls: [],
  callFilter: {
    filter: {}
  },
  listingsList: {}
}

CHistoryCalls.contextTypes = {
  t: PropTypes.func
}

const RHistoryCalls = withRouter(CHistoryCalls)

const mapStateToProps = state => ({
  historyCalls: state.listCalls.historyCalls,
  callFilter: state.callFilter,
  listings: state.listingsList.listings
})

const mapDispatchToProps = {
  fetchingHistoryCalls: fetchingHistoryCalls,
  receiveHistoryCalls: receiveHistoryCalls,
  updateCallFilter: updateCallFilter,
  receiveListings: receiveListings,
}

const HistoryCalls = connect(
  mapStateToProps,
  mapDispatchToProps
)(RHistoryCalls)

export default HistoryCalls