import React, { Component } from 'react'

class FlashMessage extends Component {
    alertClass(type) {
        let classes = {
            error: 'alert-danger',
            alert: 'alert-warning',
            notice: 'alert-info',
            success: 'alert-success'
        };
        return classes[type] || classes.success;
    }

    render() {
        const message = this.props.message;
        const alertClassName = `alert ${this.alertClass(message.type)}`;

        return (
            <div className={alertClassName}>
                <button className='close' onClick={() => {this.props.onClose(message.iD)}}>
                    &times;
                </button>
                <p>{message.text}</p>
            </div>
        );
    }
}

export default FlashMessage 