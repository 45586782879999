import { CALL_FILTER_ACTION } from 'actions/call-filter';

let initializeState = {
  filter: {
    status: null,
    start_date: null,
    end_date: null,
    begin_id: null,
    calling_type: ['all'],
    q: null,
    hasMore: true
  }
}

export const callFilter = (state=initializeState, action) => {
  switch (action.type) {
    case CALL_FILTER_ACTION.UPDATE_FILTER:
      return {...state, filter: action.payload.filter}
    default:
      return state
  }
}