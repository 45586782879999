import { LISTING_ACTIONS } from 'actions/listing'

let initializeState = {
  listings: []
}

export const listingsList = (state=initializeState, action) => {
  switch (action.type) {
    case LISTING_ACTIONS.RECEIVE_LISTINGS:
      return {...state, listings: action.payload.listings}
    case LISTING_ACTIONS.ADD_LISTING:
      return {...state, listings: [...state.listings, action.payload.listing]}
    default:
      return state
  }
}