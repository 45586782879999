import React, { Component } from 'react';
import './CallHistoryDetail.scss'
import PropTypes from 'prop-types'
import { apiPath } from '../../utils/api';
import CallLogList from './CallLogList'

class CallHistoryDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabDetailSelect: 1,
      guestInfo: null,
      guestsVerifiedStatus: null,
      callLogList: [],
      loadCallLogList: null,
      loadGuestsInfo: true
    }
  }

  componentDidMount() {
    let resId = this.props.reservation.id
    if (this.props.reservation.checked) {
      this.setState({ guestInfo: this.props.guests.map(guest => { return guest.zens_data }), guestsVerifiedStatus: this.props.guests.map(guest => { return guest.status }), loadGuestsInfo: false })
    } else {
      window.axios.get(apiPath(`reservations/${resId}/guests`))
        .then(response => {
          this.setState({ guestInfo: response.data.guests, guestsVerifiedStatus: response.data.guests_verified_status, loadGuestsInfo: false })
        })
        .catch((err) => {
          this.setState({ loadGuestsInfo: false })
        })
    }
    if (this.props.showCallLogTab) {
      this.setState({ loadCallLogList: true })
      window.axios.get(apiPath(`reservations/${resId}/call_logs`))
        .then(response => {
          this.setState({ loadCallLogList: false, callLogList: response.data })
        })
        .catch((err) => {
          this.setState({ loadCallLogList: false, callLogList: [] })
          console.log(err)
        })
    }
  }

  renderGuestInfo() {
    let guestInfo = this.state.guestInfo
    if (guestInfo && guestInfo.length > 0) {
      return (
        <div className='table-guest-infos'>
          <div className='thead row'>
            <div className='col-md-2 col-lg-2'>Name</div>
            <div className='col-md-3 col-lg-3 text-center'>Address</div>
            <div className='col-md-2 col-lg-2 text-center'>Nationality</div>
            <div className='col-md-2 col-lg-2 text-center'>Occupation</div>
            <div className='col-md-3 col-lg-3 text-center'>Passport Number</div>
          </div>
          <hr />
          {guestInfo.map(guest => {
            return (
              <div key={guest.id} className='tvalue row'>
                <div className='col-md-2 col-lg-2'>{guest.full_name}</div>
                <div className='col-md-3 col-lg-3 text-center'>
                  {`${guest.street_1}, ${guest.city}, ${guest.state}, ${guest.country}, ${guest.postal_code}`}
                </div>
                <div className='col-md-2 col-lg-2 text-center'>{guest.nationality}</div>
                <div className='col-md-2 col-lg-2 text-center'>{guest.occupation}</div>
                <div className='col-md-3 col-lg-3 text-center'>{guest.passport_number}</div>
              </div>
            )
          })
          }
        </div>
      )
    } else if (guestInfo && guestInfo.length === 0) {
      return <div className='no-data'>No Guest Information Found</div>
    }
  }

  renderListingInfo() {
    return (
      <div className='listing-infos'>
        <div className='row'>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Listing ID</p>
            <p className='value'>{this.props.listing && this.props.listing.listing_id}</p>
          </div>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Listing Short Name</p>
            <p className='value'>{this.props.listing && this.props.listing.short_name}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Address</p>
            <p className='value'>{this.props.listing && this.props.listing.address}</p>
          </div>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Tablet Type</p>
            <p className='value'>{this.props.reservation.place ? this.props.reservation.place.place_type.capitalize() : this.props.tabletType.capitalize()}</p>
          </div>
        </div>
      </div>
    )
  }

  renderTime(h) {
    if (h === null || h === '') {
      return 'None'
    }
    if (h.toUpperCase() === 'FLEXIBLE' || h.toUpperCase() === 'NOT_SELECTED') {
      return '(flexible)'
    }
    let time = h.split(":")[0]
    return Number(time) <= 12 ? `${time}:00 AM` : `${Number(time) - 12}:00 PM`
  }

  renderCheckinStatus() {
    let t = this.context.t
    let { reservation } = this.props
    if (!reservation) return ''
    if (this.state.guestsVerifiedStatus && this.state.loadGuestsInfo === false) {
      let checkinStatus = ''
      let guestsVerifiedStatus = this.state.guestsVerifiedStatus
      let numOfGuestsVerified = 0
      guestsVerifiedStatus.forEach(element => {
        if (element === 'verified') {
          numOfGuestsVerified++
        }
      });
      if (numOfGuestsVerified && numOfGuestsVerified === guestsVerifiedStatus.length) {
        checkinStatus = 'check_in_successfully'
      } else if (numOfGuestsVerified > 0) {
        checkinStatus = 'checkin_not_enough'
      } else {
        checkinStatus = 'not_check_in_yet'
      }
      return t(`history_call_detail.status.${checkinStatus}`)
    } else if (this.state.loadGuestsInfo) {
      return (<span className='load-data text-left'>loading ...</span>)
    }
  }

  renderReservationInfo() {
    return (
      <div className='listing-infos reservation-infos'>
        <div className='row'>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Checkin</p>
            <p className='value'>{this.props.reservation && this.renderTime(this.props.reservation.checkin_time)}</p>
          </div>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Checkout</p>
            <p className='value'>{this.props.reservation && this.renderTime(this.props.reservation.checkout_time)}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Start date</p>
            <p className='value'>{this.props.reservation && this.props.reservation.start_date.replace(/-/g, '/')}</p>
          </div>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>End date</p>
            <p className='value'>{this.props.reservation && this.props.reservation.end_date.replace(/-/g, '/')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Number of Guests</p>
            <p className='value'>{this.props.reservation && this.props.reservation.number_of_guests}</p>
          </div>
          <div className='col-md-6 col-lg-6'>
            <p className='title'>Check-in Status</p>
            <p className='value'>
              {this.renderCheckinStatus()}
            </p>
          </div>
        </div>
      </div>
    )
  }

  renderCallLogInfo() {
    return (
      <CallLogList
        callLogList={this.state.callLogList}
        loadCallLogList={this.state.loadCallLogList} />
    )
  }

  renderTab(tabSelect) {
    switch (tabSelect) {
      case 0:
        return this.renderGuestInfo()
      case 1:
        return this.renderReservationInfo()
      case 2:
        return this.renderListingInfo()
      case 3:
        return this.renderCallLogInfo()
      default:
        return
    }
  }

  switchTab(type) {
    this.setState({ tabDetailSelect: type })
  }

  render() {
    return (
      <div className="call-history">
        <div className='detail-content'>
          {this.renderTab(this.state.tabDetailSelect)}
        </div>
        <div className='right-tab'>
          <ul>
            {this.props.showCallLogTab &&
              <li className={this.state.tabDetailSelect === 3 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 3)}>
                <i className='la la-list-alt right-20' />Call Logs
              </li>
            }
            <li className={this.state.tabDetailSelect === 0 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 0)}>
              <i className='la la-user right-20' />Guest details
            </li>
            <li className={this.state.tabDetailSelect === 1 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 1)}>
              <i className='la la-building-o right-20' />Reservation detail
            </li>
            <li className={this.state.tabDetailSelect === 2 ? 'li-select' : undefined} onClick={this.switchTab.bind(this, 2)}>
              <i className='la la-map-pin right-20' />Listing Info
            </li>
          </ul>
        </div>
      </div>
    )
  };
}

CallHistoryDetail.contextTypes = {
  t: PropTypes.func
}

export default CallHistoryDetail