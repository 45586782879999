import { generateAuthActions } from 'redux-token-auth'
import { apiURL } from '../utils/api'

const config = {
  authUrl: apiURL('auth'),
  userAttributes: {
    id: 'id',
    username: 'username',
  }
}

const {
  registerUser,
  signInUser,
  signOutUser,
  verifyCredentials,
} = generateAuthActions(config)

export {
  registerUser,
  signInUser,
  signOutUser,
  verifyCredentials,
}