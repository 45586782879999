import React, { Component } from 'react';
import CallLogRow from "./CallLogRow"

class CallLogList extends Component {

    render() {
        let callLogList = this.props.callLogList.map((callLog) =>
            <CallLogRow
                key={callLog.id}
                id={callLog.id}
                createdDay={callLog.created_at}
                callType={callLog.calling_type}
                duration={callLog.duration}
                verificationlog={callLog.guest_verification_logs}
            />
        )
        return (
            <div>
                {this.props.loadCallLogList ? (<div className="load-data">Load Call Log....</div>) : (
                    callLogList.length === 0 ? (<div className="no-data">No Call Log Found</div>) : (
                        <table className="call-log-list table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Time</th>
                                    <th>Call Type</th>
                                    <th>Duration</th>
                                    <th>Guests Verified Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {callLogList}
                            </tbody>
                        </table>
                    )
                )}
            </div>
        )
    }
}

export default CallLogList