import React, { Component } from 'react'
import FlashMessage from './FlashMessage'

class FlashMessagesContainer extends Component {
    render() {
        return (
            <div className='row flash-messages'>
                {
                    this.props.flashMessages.map(message => 
                        <FlashMessage key={Math.random()} message={message} onClose={this.props.onClose} />
                    )
                }
            </div>
        )

    }
}

export default FlashMessagesContainer