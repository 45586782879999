import { RESERVATION_FILTER_ACTION } from 'actions/reservation-filter'

let initializeState = {
  filter: {
    checkin_status: 'all',
    reservation_status: 'all',
    in_use: 'all',
    q: null
  }
}

export const reservationFilter = (state=initializeState, action) => {
  switch (action.type) {
    case RESERVATION_FILTER_ACTION.UPDATE_SEARCH_FILTER:
      return  Object.assign({}, state, {
                filter: Object.assign({}, state.filter, {q: action.payload.q})
              })
    case RESERVATION_FILTER_ACTION.UPDATE_RESERVATION_STATUS_FILTER:
      return  Object.assign({}, state, {
                filter: Object.assign({}, state.filter, {reservation_status: action.payload.reservation_status})
              })
    case RESERVATION_FILTER_ACTION.UPDATE_CHECKIN_STATUS_FILTER:
      return  Object.assign({}, state, {
                filter: Object.assign({}, state.filter, {checkin_status: action.payload.checkin_status})
              })
    case RESERVATION_FILTER_ACTION.UPDATE_SMARTLOCK_STATUS_FILTER:
      return Object.assign({}, state, {
                filter: Object.assign({}, state.filter, {in_use: action.payload.smartlock_status})
              })
    default:
      return state
  }
}