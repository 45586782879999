import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store/root'
import { verifyCredentials } from './actions/redux-token-auth'
import cookies from 'browser-cookies'
import { signOutUser } from './actions/redux-token-auth'

const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
}

serviceWorker.unregister();

if (localStorage.getItem('access-token')) {
  // subcribe store to check current user is fetched
  const unsubscribe = store.subscribe(() => {
    let storeTree = store.getState()
    if (!storeTree.reduxTokenAuth.currentUser.isLoading) {
      // ensure render app after credential is checked
      unsubscribe()
      renderApp()
      // set session
      let session =  Date.now().toString()
      cookies.set('session', session)
    }
  })

  let rememberOrTabStillAlive =
    localStorage.getItem('rememberMe') === 'true' ||
    cookies.get('session')

  if (rememberOrTabStillAlive)
    verifyCredentials(store)
  else
    store.dispatch(signOutUser())
} else {
  // incase visitor
  renderApp()
}