import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { apiPath } from '../../utils/api'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { locationNotAvailableAlert } from '../ConfirmAlert/ConfirmAlert'
import { apiErrorCodes } from 'configs/api_error_codes.js'

class CTabletLocationRow extends Component {

    constructor(props) {
        super(props)
        this.role = JSON.parse(localStorage.getItem('authToken'))['authUser']['roles']
    }

    renderMultipleListingsShortname = (tabletLocation) => {
        let listings = tabletLocation.listings
        return (
            <div
                dangerouslySetInnerHTML={{ __html: `<p title="${listings.map(item => item.short_name).join(',&#010;')}">${listings[0].short_name},...</p>` }}
            />
        )
    }

    listingShortName(tabletLocation) {
      const listings = tabletLocation.listings
      if (Array.isArray(listings) && listings.length > 1) {
          return this.renderMultipleListingsShortname(tabletLocation)
      }
      return listings && listings[0] && listings[0].short_name
    }

    handleClickOnDeleteTabletLocation(id) {
        this.props.handleDeleteTabletLocation(id)
    }

    renderTabletLocationActions(tabletLocationId, hasAvailableReservation) {
        const onlyGuestSupportRole = this.role.length === 1 && this.role.includes("support")
        let editBtn = null, deleteBtn = null
        if (onlyGuestSupportRole) {
            editBtn =
                <button disabled onClick={(e) => { e.stopPropagation() }}>
                    <FontAwesomeIcon icon={faPen} onClick={(e) => { e.stopPropagation() }} />
                </button>
            deleteBtn =
                <button disabled onClick={(e) => { e.stopPropagation() }}>
                    <FontAwesomeIcon icon={faTrash} onClick={(e) => { e.stopPropagation() }} />
                </button>
        } else {
            if (hasAvailableReservation) {
                editBtn =
                    <button className="edit-btn" onClick={(e) => { e.stopPropagation(); this.handleClickOnEditTabletLocation(tabletLocationId) }}>
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                deleteBtn =
                    <button disabled onClick={(e) => { e.stopPropagation() }}>
                        <FontAwesomeIcon icon={faTrash} onClick={(e) => { e.stopPropagation() }} />
                    </button>
            } else {
                editBtn =
                    <button className="edit-btn" onClick={(e) => { e.stopPropagation(); this.handleClickOnEditTabletLocation(tabletLocationId) }}>
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                deleteBtn =
                    <button className="delete-btn" onClick={(e) => { e.stopPropagation(); this.handleClickOnDeleteTabletLocation(tabletLocationId) }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
            }
        }
        return (
            <div className='btn-group d-flex justify-content-center'>
                <div className='d-flex justify-content-center'>
                    <button className="call-btn" onClick={(e) => { e.stopPropagation(); this.handleCallToTabletLocation(e, tabletLocationId) }}>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                    </button>
                    {editBtn}
                    {deleteBtn}
                </div>
            </div>
        )
    }

    handleCallToTabletLocation(e, id) {
        let callButton = e.currentTarget
        if (callButton.disabled) {
            return
        }
        callButton.disabled = true
        let requestedData = {
            calling_type: 'support',
            place_id: id,
            from_system: 'operator',
            operator_name: JSON.parse(localStorage.getItem('authToken'))['authUser']['name']
        }
        const t = this.context.t
        window.axios.post(apiPath('calls'), requestedData).then(response => {
            if (response.data['success'] === "false" && response.data.error.error_code === apiErrorCodes.LOCATION_NOT_AVAILABLE) {
                locationNotAvailableAlert(t)
            } else {
                this.props.history.push({
                    pathname: '/call_screen',
                    type: 'callBack',
                    call: response.data['call']
                })
            }
        })
        setTimeout(() => {
            callButton.disabled = false
        }, 5000)
    }

    handleClickOnTabletLocationId(e, id) {
        if (document.getSelection().toString()) {
            return
        } else {
            this.props.handleClickOnTabletLocationId(id)
        }
    }

    handleClickOnEditTabletLocation(id) {
        this.props.handleClickOnEditTabletLocation(id)
    }

    renderTabletLocationAddress(address) {
        if (address.length >= 70) {
            return (
                <p title={address}>{`${address.substring(0, 30)}...`}</p>
            )
        } else {
            return (
                <p>{address}</p>
            )
        }
    }

    render() {
        const id = this.props.tabletLocation.id
        const address = this.props.tabletLocation.listings ? this.renderTabletLocationAddress(this.props.tabletLocation.address) : ''
        const setupCode = this.props.tabletLocation.setup_code
        const placeType = this.props.tabletLocation.place_type === 'inside' ? 'Inside' : 'Outside'
        const assigned = this.props.tabletLocation.uid !== null ? 'Yes' : 'No'
        const noListing = this.props.tabletLocation.listings ? this.props.tabletLocation.listings.length : ''
        const hasAvailableReservation = this.props.tabletLocation.has_available_reservation
        const listingShortName = this.listingShortName(this.props.tabletLocation)
        const actions = this.renderTabletLocationActions(this.props.tabletLocation.id, hasAvailableReservation)
        return (
            <div className="tvalue row tablet-location-element" onClick={(e) => { this.handleClickOnTabletLocationId(e, this.props.tabletLocation.id) }}>
                <div className='col-md-1 col-lg-1 text-center'>{id}</div>
                <div className='col-md-2 col-lg-2 text-left'>{listingShortName}</div>
                <div className='col-md-1 col-lg-1 text-center'>{noListing}</div>
                <div className='col-md-3 col-lg-3 text-left'>{address}</div>
                <div className='col-md-1 col-lg-1 text-center'>{placeType}</div>
                <div className='col-md-1 col-lg-1 text-center'>{assigned}</div>
                <div className='col-md-1 col-lg-1 text-center'>{setupCode}</div>
                <div className='col-md-2 col-lg-2 text-center'>{actions}</div>
            </div>
        )
    }
}

CTabletLocationRow.contextTypes = {
    t: PropTypes.func
}

const TabletLocationRow = withRouter(CTabletLocationRow)

export default TabletLocationRow