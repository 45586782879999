export const RESERVATION_FILTER_ACTION = {
  UPDATE_SEARCH_FILTER: 'UPDATE_SEARCH_FILTER',
  UPDATE_RESERVATION_STATUS_FILTER: 'UPDATE_RESERVATION_STATUS_FILTER',
  UPDATE_CHECKIN_STATUS_FILTER: 'UPDATE_CHECKIN_STATUS_FILTER',
  UPDATE_SMARTLOCK_STATUS_FILTER: 'UPDATE_SMARTLOCK_STATUS_FILTER'
}

export const updateSearch = (q) => ({
  type: RESERVATION_FILTER_ACTION.UPDATE_SEARCH_FILTER,
  payload: {
    q: q
  }
})

export const updateReservationStatus = (resStatus) => ({
  type: RESERVATION_FILTER_ACTION.UPDATE_RESERVATION_STATUS_FILTER,
  payload: {
    reservation_status: resStatus
  }
})

export const updateCheckinStatus = (checkinStatus) => ({
  type: RESERVATION_FILTER_ACTION.UPDATE_CHECKIN_STATUS_FILTER,
  payload: {
    checkin_status: checkinStatus
  }
})

export const updateSmartLockStatus = (smartLockStatus) => ({
  type: RESERVATION_FILTER_ACTION.UPDATE_SMARTLOCK_STATUS_FILTER,
  payload: {
    smartlock_status: smartLockStatus
  }
})