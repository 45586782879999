import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import ToggleSliderButton from '../../ToggleSliderButton/ToggleSliderButton'
import RefreshCode from '../../../images/RefreshCode.png';
import { apiPath } from '../../../utils/api'
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate'
import { loadListingOptions } from 'utils/loadListingOptions.js'
import { loadPlaceOptions } from 'utils/loadPlaceOptions.js'
import BackButton from '../BackButton'

class CAddPlace extends Component {
  constructor(props) {
    super(props);
    this.submitFormBtnRef = React.createRef()
    this.state = {
      isMasterPlace: false,
      password: '',
      listings: [],
      slavePlaces: [],
      device: '',
      address: '',
      placeType: 'outside',
    }
    this.handleType = this.handleType.bind(this)
    this.handleListings = this.handleListings.bind(this)
    this.handleAddress = this.handleAddress.bind(this)
    this.handleDevice = this.handleDevice.bind(this)
    this.handleSlavePlaces = this.handleSlavePlaces.bind(this)
    this.generateCode = this.generateCode.bind(this)
    this.handleToggleMasterPlace = this.handleToggleMasterPlace.bind(this)
    this.devices = []
    this.generator = require('generate-password');
  }

  componentDidMount() {
    this.generateCode()
    window.axios.get(apiPath('devices')).then(response => {
      let devices = response.data
      devices.forEach(device => {
        this.devices.push({ value: device.uid, label: device.name })
      })
    })
  }

  handleToggleMasterPlace() {
    this.setState({ isMasterPlace: !this.state.isMasterPlace, slavePlaces: [], listings: [] })
  }

  handleListings(listings) {
    let state = { ...this.state }
    let firstListing = listings[0]
    state.listings = listings
    if (firstListing && firstListing.address) {
      state.address = firstListing.address
    }

    if (listings.length > 1) {
      state.placeType = 'outside'
      state.isMasterPlace = false
    }

    this.setState(state)
  }

  handleSlavePlaces(places) {
    this.setState({ slavePlaces: places })
  }

  handleDevice(device) {
    let state = { ...this.state }
    state.device = device
    this.setState(state)
  }

  handleAddress(e) {
    let state = { ...this.state }
    state.address = e.target.value
    this.setState(state)
  }

  handleType(type) {
    if (type === 'inside' && this.state.listings.length > 1) {
      return false
    }
    this.setState({ placeType: type })
  }

  generateCode() {
    let state = { ...this.state }
    window.axios.get(apiPath('places/generate_setup_code')).then(response => {
      state.password = response.data['setup_code']
      this.setState(state)
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
    let t = this.context.t;
    let listingRequired = (this.state.placeType === "inside" && !this.state.listings.length) ||
      (this.state.placeType === "outside" && !this.state.isMasterPlace && !this.state.listings.length)
    let addressRequired = (!this.state.isMasterPlace && !this.state.address.length && this.state.placeType === "outside") ||
      (this.state.placeType === "inside" && !this.state.address.length)
    let slavesRequired = this.state.placeType === "outside" && this.state.isMasterPlace && !this.state.slavePlaces.length
    if (listingRequired) {
      let message = { text: t('listings.required'), type: "error" }
      this.props.handleAddFlashMessage(message)
    } else if (addressRequired) {
      let message = { text: t('address.required'), type: "error" }
      this.props.handleAddFlashMessage(message)
    } else if (slavesRequired) {
      let message = { text: t('slaves.required'), type: "error" }
      this.props.handleAddFlashMessage(message)
    } else {
      this.submitFormBtnRef.current.disabled = true
      let listing_ids = []
      this.state.listings.forEach(listing => {
        listing_ids.push(listing.value)
      })
      let slavePlaces = []
      this.state.slavePlaces.forEach(place => {
        slavePlaces.push(place.id)
      })
      const dataRequest = (this.state.isMasterPlace && this.state.placeType === "outside") ? {
        'setup_code': this.state.password,
        'place_type': this.state.placeType,
        'is_master': this.state.isMasterPlace,
        'slave_places': slavePlaces
      } : {
          'setup_code': this.state.password,
          'listing_ids': listing_ids,
          'place_type': this.state.placeType,
          'address': this.state.address,
          'uid': this.state.device.value
        }
      window.axios.post(apiPath('places'), dataRequest).then(response => {
        this.props.handleAddTabletLocation(response.data)
      })
    }
  }

  renderActiveClass(type) {
    let state = { ...this.state }
    if (state.placeType === type) {
      return 'active'
    } else if (state.listings.length > 1) {
      return 'disabled'
    } else {
      return ''
    }
  }

  renderSelectListingPlaceholder() {
    let t = this.context.t
    if (this.state.placeType === 'inside') {
      return t('edit_place.add_one_listing_placeholder')
    } else {
      return t('edit_place.select_listings_placeholder')
    }
  }

  render() {
    let t = this.context.t

    return (
      <div className='add-place'>
        <div className="header-add-place">
          <BackButton handleReturnToFilterableTabletLocations={this.props.handleReturnToFilterableTabletLocations} />
        </div>
        <form className="form-add-place" onSubmit={this.onSubmit}>
          <div className="form-group form-inline">
            <div className="col-lg-4 text-left">
              <span className="add-place-title">{t('type')}</span>
            </div>
            <div className="col-lg-8 choose-place-type">
              <div className="btn-group">
                <label className={`btn btn-primary btn-place-type ${this.renderActiveClass('outside')}`} onClick={() => this.handleType('outside')}>
                  <input className='d-none' type="radio" name="place-type" id="outside" /> {t('outside')}
                </label>
                <label className={`btn btn-primary btn-place-type ${this.renderActiveClass('inside')}`} onClick={() => this.handleType('inside')}>
                  <input className='d-none' type="radio" name="place-type" id="inside" /> {t('inside')}
                </label>
              </div>
            </div>
          </div>

          {
            this.state.placeType === "outside" &&
            <div className="form-group form-inline">
              <div className="col-lg-4 text-left">
                <span className="add-place-title">Control other tablets</span>
              </div>
              <div className="col-lg-8">
                {<ToggleSliderButton isMasterPlace={this.state.isMasterPlace} handleToggleMasterPlace={this.handleToggleMasterPlace} />}
              </div>
            </div>
          }

          {
            (!this.state.isMasterPlace || this.state.placeType === "inside") ? (
              <div>
                <div className="form-group form-inline">
                  <div className="col-lg-4 text-left">
                    <span className="add-place-title">{t('listings')}</span>
                  </div>
                  <div className="col-lg-8">
                    <AsyncPaginate
                      value={this.state.listings}
                      loadOptions={loadListingOptions}
                      onChange={this.handleListings}
                      additional={{ page: 1 }}
                      openOnFocus={true}
                      isMulti={true}
                      placeholder={this.renderSelectListingPlaceholder()}
                    />
                  </div>
                </div>
                <div className="form-group form-inline">
                  <div className="col-lg-4 text-left">
                    <span className="add-place-title">{t('tablet_info')}</span>
                  </div>
                  <div className="col-lg-8">
                    <Select
                      value={this.state.device}
                      onChange={this.handleDevice}
                      openOnClick={true}
                      options={this.devices}
                      placeholder={t('enter_tablet')}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div className="form-group form-inline">
                  <div className="col-lg-4 text-left">
                    <span className="add-place-title">{t('address')}</span>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control input-place"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleAddress}
                      placeholder={t('enter_address')}
                    />
                  </div>
                </div>
              </div>
            ) : (
                <div className="form-group form-inline">
                  <div className="col-lg-4 text-left">
                    <span className="add-place-title">Control Over</span>
                  </div>
                  <div className="col-lg-5">
                    <AsyncPaginate
                      value={this.state.slavePlaces}
                      onChange={this.handleSlavePlaces}
                      loadOptions={loadPlaceOptions}
                      additional={{ page: 1 }}
                      placeholder="You may add multiple Location IDs"
                      isMulti={true}
                      openOnFocus={true}
                    />
                  </div>
                </div>
              )
          }

          <div className="form-group form-inline">
            <div className="col-lg-4 text-left">
              <span className="add-place-title">{t('setup_code')}</span>
            </div>
            <div className="col-lg-8">
              <span className="setup-code add-place-title">{this.state.password}</span>
              <button className="generate-code-btn" onClick={(e) => { e.preventDefault(); this.generateCode() }}>
                <img src={RefreshCode} alt='refresh-code' id='refresh-code' />
              </button>
            </div>
          </div>
          <div className="form-group group-button text-right">
            <button type="submit" ref={this.submitFormBtnRef} className="btn btn-add">{t('create')}</button>
          </div>
        </form>
      </div>
    )
  };
}

CAddPlace.contextTypes = {
  t: PropTypes.func
}
const RAddPlace = withRouter(CAddPlace)

const mapStateToProps = state => ({
  reduxTokenAuth: state.reduxTokenAuth
})

const mapDispatchToProps = {
}

const AddPlace = connect(
  mapStateToProps,
  mapDispatchToProps
)(RAddPlace)

export default AddPlace
