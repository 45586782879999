import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class BackButton extends Component {
    render() {
        return (
            <button className="btn back-to-list" onClick={() => this.props.handleReturnToFilterableTabletLocations()}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
        )
    }
}

export default BackButton