import React, { Component } from 'react'
import Guest from './Guest'
import PropTypes from 'prop-types'
import './GuestsActions.scss'

class GuestsActions extends Component {

  renderStatusVerified() {
    const t = this.context.t
    let verifiedNumber = this.props.guests.filter(guest => {return  guest.status === 'verified' }).length
    let guestsNumber = this.props.guests.length
    return (
      <div>
        <strong>{`${verifiedNumber}/${guestsNumber}`}</strong>
        <span className='pl-1'>{ t('call_detail.verified_btn') }</span>
      </div>
    )
  }

  renderDisabledBtnClass(status) {
    return this.props.disabledBtns.includes(status)
  }

  render() {
    let { verifyingGuest } = this.props
    return (
      <div className='guests-actions col-md-6'>
        <p className='title'>Guests</p>
        <div className={ this.props.isSideBarOpen ? "guests-list float-left scrollbar-custom sidebaropen" : "guests-list float-left scrollbar-custom" }>
          {
            this.props.guests.map(guest => {
              let isVerifying = this.props.verifyingGuest.id === guest.id
              return(
                <Guest key={guest.id} guest={guest} isVerifying={isVerifying}/>
              )
            })
          }
        </div>
        <div className='verify-button-group float-right text-white text-center'>
          {
            this.renderStatusVerified()
          }
          { this.props.isCheckinCall &&
            <>
              <button
                className='btn btn-failed'
                onClick={() => this.props.handleVerifyCall(verifyingGuest.id, btnStatus.failed)}
                disabled={this.renderDisabledBtnClass(btnStatus.failed)}
                title='failed'>
                <div className='exclamation-icon-container'>
                  !
                </div>
              </button>
              <button className='btn btn-verified' onClick={() => this.props.handleVerifyCall(verifyingGuest.id, btnStatus.verified)}  disabled={this.renderDisabledBtnClass(btnStatus.verified)} title='verified'>
                <div className='check-icon-container'>
                  <i className="la la-check"></i>
                </div>
              </button>
            </>
          }
        </div>
      </div>
    )
  }
}

const btnStatus = {
  verified: 'verified',
  failed: 'failed',
}

GuestsActions.defaultProps = {
  guests: [],
  verifyingGuest: {}
}

GuestsActions.contextTypes = {
  t: PropTypes.func
}

export default GuestsActions