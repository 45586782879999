import axios from 'axios';
import qs from 'qs'

//----------------------------
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_ZENS_TABLET_TOKEN
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, {arrayFormat: 'brackets'})
}
//----------------------------
var authHeaderKeys = [
  'access-token',
  'token-type',
  'client',
  'expiry',
  'uid',
];
// get idea from redux-token-auth
const setAuthHeaders = (headers) => {
  authHeaderKeys.forEach(function (key) {
    axios.default.defaults.headers.common[key] = headers[key];
  });
};
// get idea from redux-token-auth
const persistAuthHeadersInLocalStorage = (headers) => {
  authHeaderKeys.forEach(function (key) {
    localStorage.setItem(key, headers[key]);
  });
};
//----------------------------
// inject header before request
axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line
    config.headers = {
      ...config.headers,
      'access-token': localStorage.getItem('access-token'),
      'token-type': localStorage.getItem('token-type'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      expiry: localStorage.getItem('expiry')
    };

    
    return config;
  },
  error =>
    // Do something with request error
    Promise.reject(error),
);
// update header after response
axios.interceptors.response.use(
  (response) => {
    if (response.headers['access-token']) {
      setAuthHeaders(response.headers);
      persistAuthHeadersInLocalStorage(response.headers);
    }

    return response;
  },
  error =>
    // Do something with response error
    Promise.reject(error),
);
//----------------------------
// public axios
window.axios = axios