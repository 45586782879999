import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import EndCall from '../../images/EndCall.png';
import Timer from '../../components/Timer/Timer';
import './CallScreenTopBar.scss'
import VerifyFlash from './VerifyFlash/VerifyFlash';

class CallScreenTopBar extends Component {
  renderNumberOfVerifiedGuests() {
    if (!this.props.isAnonymous && !this.props.isCheckinCall && this.props.reservation) {
      let listGuests = this.props.reservation.guests
      let totalGuest = listGuests.length
      let numOfGuestsVerified = listGuests.filter(guest => guest.status === "verified").length
      return (
        <div id="num-guets-verified">
          <p>{ (numOfGuestsVerified < totalGuest) ? `${numOfGuestsVerified}/${totalGuest} Guests Verified` : "" }</p>
        </div>
      )
    }
  }

  renderSignOfMicrophoneUnavailable() {
    if (this.props.isCheckinCall) {
      return (
        <div className="microphone-unavailable left" >
          <FontAwesomeIcon icon={faMicrophoneSlash}/> Microphone is unavailable
        </div>
      )
    } else {
      return (
        <div className="microphone-unavailable right">
          <FontAwesomeIcon icon={faMicrophoneSlash}/> Microphone is unavailable
        </div>
      )
    }
  }

  render() {
    let full_name = this.props.isAnonymous ? 'Anonymous' : this.props.verifyingGuest.zens_data.full_name
    return (
        <div className="d-flex justify-content-center topbar-box">
          <div className="d-flex flex-column justify-content-center name-duration-box">
            <div className="guest-name">
              { full_name }
            </div>
            {
              !this.props.isLoading && this.props.activeRoom && this.props.activeRoom.participants.size !== 0 &&
              <div className='duration'><Timer setDuration={this.props.setDuration} /></div>
            }
          </div>
          { this.props.isMicrophoneAvailable === false && this.renderSignOfMicrophoneUnavailable() }
          { this.renderNumberOfVerifiedGuests() }
          { this.props.isCheckinCall &&
            <div className="d-flex button-box">
            {this.props.disableEndCallButton ? (
              <button className='btn-end-call' disabled onClick={() => this.props.leaveRoomIfJoined()}>
                <img src={EndCall} alt="end-call"/>
              </button> 
            ) : (
              <button className='btn-end-call' onClick={() => this.props.leaveRoomIfJoined()}>
                <img src={EndCall} alt="end-call"/>
              </button>
            )}
            </div>
          }
          { this.props.isCheckinCall &&
            <button className="swipe-call-details" onClick={this.props.toggleCallScreenSideBar}>
              <i className="la la-comment"></i>
            </button>
          }
          <VerifyFlash alert={this.props.alert} />
        </div>
    )
  }
}

CallScreenTopBar.defaultProps = {
  verifyingGuest: {
    zens_data: {}
  }
}

export default CallScreenTopBar