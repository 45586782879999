export const apiURL = (apiPath) => {
  return `${process.env.REACT_APP_API_BASE_URL}/${apiPath}`
}
export const apiPath = (path) => {
  return `${process.env.REACT_APP_API_BASE_URL}/api/v1/${path}`
}
export const appURL = (appPath) => {
  return `${process.env.REACT_APP_BASE_URL}/${appPath}`
}
export const cableURL = () => {
  return `${process.env.REACT_APP_CABLE_URL}`
}
export const zensAppRedirectUrl = () => {
  return `${process.env.REACT_APP_ZENS_APP_URL}/login_via_zensapp/zenstablet?token=${process.env.REACT_APP_ZENS_APP_LOGIN_TOKEN}&redirect_uri=${process.env.REACT_APP_BASE_URL}/login`
}
export const imageURL = (imagePath) => {
  return `${process.env.REACT_APP_API_BASE_URL}/${imagePath}`
}
