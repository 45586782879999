import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import './Logout.scss'

class CLogout extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout(e) {
    e.preventDefault();
    localStorage.removeItem('authToken')
    this.props.history.push('/login')
  }

  render() {
    return (
      <button className="logout-btn" onClick={this.handleLogout}>Log Out</button>
    );
  };
}
CLogout.contextTypes = {
  t: PropTypes.func
}
const RLogout = withRouter(CLogout)

const mapStateToProps = state => ({
  reduxTokenAuth: state.reduxTokenAuth
})

const mapDispatchToProps = {
}

const Logout = connect(
  mapStateToProps,
  mapDispatchToProps
)(RLogout)

export default Logout
