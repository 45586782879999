import React, { Component } from 'react';
import GuestVerificationLog from './GuestVerificationLog';
import moment from 'moment';

class CallLogRow extends Component {

    convertSecondsTimeFormat(_seconds) {
        let sec_num = parseInt(_seconds, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    render() {
        return (
            <tr>
                <td>{this.props.id}</td>
                <td>{moment((new Date(this.props.createdDay))).format('h:mm A, YYYY/MM/DD')}</td>
                <td>
                    <div className={this.props.callType}>
                        {this.props.callType === "support" ? "Support Needed" : this.props.callType.capitalize()}
                    </div>
                </td>
                <td>{this.convertSecondsTimeFormat(this.props.duration)}</td>
                <td>
                    <GuestVerificationLog verificationLogList={this.props.verificationlog} />
                </td>
            </tr>
        )
    }
}

export default CallLogRow