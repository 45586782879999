import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

class GuestVerificationLog extends Component {
    render() {
        return (
            <ul>
                {this.props.verificationLogList.length ?
                    (this.props.verificationLogList.map((verificationLogRow) =>
                        verificationLogRow.status === "failed" ?
                            (
                                <li key={verificationLogRow.id}>
                                    <FontAwesomeIcon icon={faExclamationCircle} color={"#fcc52c"} /> {verificationLogRow['guest_name']}
                                </li>
                            ) : (
                                <li key={verificationLogRow.id}>
                                    <FontAwesomeIcon icon={faCheckCircle} color={"#34d699"} /> {verificationLogRow['guest_name']}
                                </li>
                            )
                    )) : ("----")
                }
            </ul>
        )
    }
}

export default GuestVerificationLog 