import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import './DetailedSelectedItem.scss'
import CallHistoryDetail from '../../../components/CallHistoryDetail/CallHistoryDetail'
import IncommingCallDetail from '../../CallHistoryDetail/IncommingCallDetail';

class DetailedSelectedItem extends Component {
    renderSelectedItem() {
        let component;
        const data = this.props.itemSelected.data
        switch (this.props.itemSelected.type) {
            case "IncomingCallSelect":
                component =
                    <IncommingCallDetail
                        reservation={data && data.reservation}
                        listing={data && data.reservation && data.reservation.listing}
                        tabletType={data && data.tablet_type}
                        guests={data && data.reservation && data.reservation.guests} />
                break
            case "HistoryCallSelect":
                component =
                    <CallHistoryDetail
                        reservation={data && data.reservation}
                        listing={data && data.reservation && data.reservation.listing}
                        tabletType={data && data.tablet_type}
                        guests={data && data.reservation && data.reservation.guests}
                        showCallLogTab={false} />
                break
            case "ReservationSelect":
                component =
                    <CallHistoryDetail
                        reservation={data && data.reservation}
                        listing={data && data.reservation && data.reservation.listing}
                        tabletType={data && data.tablet_type}
                        guests={data && data.reservation && data.reservation.guests}
                        showCallLogTab={true} />
                break;
            default:
                break;
        }
        return component
    }
    render() {
        return (
            <div className="detailed-item">
                <div className="row detailed-item-header">
                    <button className="btn back-to-list" onClick={() => this.props.backToList()}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>
                <div className="detailed-item-content">
                    {this.renderSelectedItem()}
                </div>
            </div>
        )
    }
}

export default DetailedSelectedItem