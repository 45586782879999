import { LIST_CALLS_ACTIONS } from 'actions/list-calls';

let initializeState = {
  fetching: false,
  historyCalls: [],
  incomingCalls: [],
  notificationCalls: [],
}

export const listCalls = (state=initializeState, action) => {
  switch (action.type) {
    case LIST_CALLS_ACTIONS.FETCHING_HISTORY_CALLS:
      return {...state, fetching: true}
    case LIST_CALLS_ACTIONS.RECEIVE_HISTORY_CALLS:
      return {...state, fetching: false, historyCalls: action.payload.historyCalls}
    case LIST_CALLS_ACTIONS.FETCHING_INCOMING_CALLS:
      return {...state, fetching: true}
    case LIST_CALLS_ACTIONS.RECEIVE_INCOMING_CALLS:
      return {...state, fetching: false, incomingCalls: action.payload.incomingCalls}
    case LIST_CALLS_ACTIONS.RECEIVE_INCOMING_CALL:
      return  {...state, incomingCalls: [
                ...state.incomingCalls,
                action.payload.incomingCall
              ]}
    case LIST_CALLS_ACTIONS.RECEIVE_NOTIFICATION_CALLS:
      let ascCalls = action.payload.notificationCalls.sort((a,b) => a.id - b.id)
      return {...state, notificationCalls: ascCalls}

    case LIST_CALLS_ACTIONS.RECEIVE_NOTIFICATION_CALL:
      return  {...state, notificationCalls: [
                ...state.notificationCalls,
                action.payload.notificationCall
              ]}
    case LIST_CALLS_ACTIONS.IGNORE_INCOMING_CALL:
      return  {...state, notificationCalls: state.notificationCalls.filter(item => item.id !== action.payload.incomingCall.id)}
    case LIST_CALLS_ACTIONS.RECEIVE_HISTORY_CALL:
      return  {...state, historyCalls: [
                action.payload.historyCall,
                ...state.historyCalls
              ]}
    case LIST_CALLS_ACTIONS.REMOVE_INCOMING_CALL:
      return  {...state, incomingCalls: state.incomingCalls.filter(item => item.id !== action.payload.incomingCall.id)}
    default:
      return state
  }
}