import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { apiPath } from '../../utils/api';
import { updateCallFilter } from 'actions/call-filter';
import {
  fetchingHistoryCalls,
  receiveHistoryCalls,
} from 'actions/list-calls'
import { receiveListings } from 'actions/listing'

class CSearchBar extends Component {
  constructor(props) {
    super(props)

    this.handleSearchForListingOnHistoryCall = this.handleSearchForListingOnHistoryCall.bind(this)
    this.makeHistoryFilter = this.makeHistoryFilter.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)

    this.state = {
      timeOutId: null,
      showClosedButton: false
    }
  }

  componentDidMount() {
    if (this.props.callFilter.filter.q) {
      this.setState({ showClosedButton: true })
    }
  }

  handleSearchForListingOnHistoryCall(e = {}) {
    if (Object.keys(e).length === 0 && e.constructor === Object) {
      this.setState({ showClosedButton: false })
    }

    if (!(Object.keys(e).length === 0 && e.constructor === Object)) {
      (e.target.value !== "") ? this.setState({ showClosedButton: true }) : this.setState({ showClosedButton: false })
    }

    if (this.state.timeOutId) {
      clearTimeout(this.state.timeOutId)
    }
    let initState = { ...this.props.callFilter }
    initState = Object.assign(initState.filter, {
      q: e.target ? e.target.value : null,
      begin_id: null,
      hasMore: true
    })
    this.setState({
      timeOutId: setTimeout(() => {
        this.props.updateCallFilter(initState)
        this.makeHistoryFilter()
      }, 1000)
    })
  }

  handleClearSearch() {
    // clear input form
    document.getElementsByClassName('searh-form')[0].value = ''
    this.handleSearchForListingOnHistoryCall()
  }

  makeHistoryFilter(queryValue) {
    const self = this
    axios.get(apiPath('calls/list_calls'), { params: this.props.callFilter.filter })
      .then(response => {
        let historyCalls = response.data['missed_calls'].concat(response.data['completed_calls'])
        let listings = response.data.listings
        historyCalls.map(call => {
          if (!call.reservation) return call
          let callListing = listings.find(item => { return item.listing_id === call.reservation.listing_id })
          if (callListing) {
            return call.reservation.listing = callListing
          }
          return call 
        })
        self.props.receiveListings(listings)
        self.props.receiveHistoryCalls(historyCalls)
      })
  }

  renderShowClosedButton() {
    if (this.state.showClosedButton) {
      return (
        <button className='btn btn-search-clear p-0 border-0' type='button' data-tooltip='tooltip' title='Clear Search' onClick={this.handleClearSearch} >
          <i className='la la-close color-dark' />
        </button>
      )
    }
  }


  render() {
    return (
      <div className='flex-fill search-for-listing color-dark'>
        <form onSubmit={e => e.preventDefault()} className='form-group mb-0'>
          <div className='input-group'>
            <div className='input-group-prepend py-1'>
              <i className='la la-search'></i>
            </div>
            <input className='form-control mb-0 border-0 searh-form color-dark' placeholder='Search for listing'
              onChange={this.handleSearchForListingOnHistoryCall}
              value={this.props.callFilter.filter.q === null ? '' : this.props.callFilter.filter.q}
            />
            {this.renderShowClosedButton()}
          </div>
        </form>
      </div>
    )
  }
}

CSearchBar.defaultProps = {
  callFilter: {
    filter: {}
  }
}

const RSearchBar = withRouter(CSearchBar)

const mapStateToProps = state => ({
  callFilter: state.callFilter,
})

const mapDispatchToProps = {
  receiveListings: receiveListings,
  fetchingHistoryCalls: fetchingHistoryCalls,
  receiveHistoryCalls: receiveHistoryCalls,
  updateCallFilter: updateCallFilter,
}

const SearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(RSearchBar)

export default SearchBar;