import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Guest.scss'

class Guest extends Component {

  renderVerifyIcon(status) {
    switch (status) {
      case 'verified':
        return <FontAwesomeIcon icon='check-circle' color='green' className='rounded-circle bg-white'/>
      case 'failed':
        return <FontAwesomeIcon icon='exclamation-circle' color='#FFC52C' className='rounded-circle bg-white'/>
      default:
        return <FontAwesomeIcon icon='circle' color='#838C93' className='rounded-circle'/>
    }
  }

  render() {
    const guestData = this.props.guest.zens_data
    return(
      <div className={`guest-item mb-2 ${this.props.isVerifying ? 'active' : ''}`}>
        <div className='d-inline pl-2 guest-status'>
          { this.renderVerifyIcon(this.props.guest.status) }
        </div>
        <div className='d-inline guest-name pl-2' title={guestData.full_name}>{guestData.full_name}</div>
      </div>
    )
  }
}

Guest.defaultProps = {
  guest: {},
  isVerifying: false
}
export default Guest