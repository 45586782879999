import React, { Component } from 'react';
import NoPassportImages from '../../images/NoPassportImages.png';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss'
import './GuestDetails.scss';


class GuestDetails extends Component {

  render() {
    let guestData = this.props.verifyingGuest.zens_data || {}
    let passportImages = guestData ? guestData.guest_images : []
    passportImages.map(image => {
      image.original = image.url
      image.thumbnail = image.url
      return image
    })
    return(
      <div className="guest-details-box">
        <div className='passport-images float-left'>
          { passportImages.length > 0 ?
            (<ImageGallery
              key={guestData.id}
              items={passportImages}
              showPlayButton={false}
              showThumbnails={false}
              showBullets={true}
              showNav={false}
            />) : (<img alt="" id="img-nopassportimages" src={NoPassportImages} />)
          }
        </div>
      </div>
    )
  }
}

export default GuestDetails