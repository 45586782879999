import { combineReducers }       from 'redux'
import { i18nState }             from 'redux-i18n'
import { reduxTokenAuthReducer } from 'redux-token-auth'
import { listCalls }             from 'reducers/list-calls'
import { callFilter }            from 'reducers/call-filter'
import { listingsList }          from 'reducers/listing'
import { reservation }           from 'reducers/reservation'
import { reservationFilter }     from 'reducers/reservation-filter'

export const structure = combineReducers({
  i18nState: i18nState,
  reduxTokenAuth: reduxTokenAuthReducer,
  listCalls: listCalls,
  callFilter: callFilter,
  listingsList: listingsList,
  reservation: reservation,
  reservationFilter: reservationFilter
});