import React from 'react'
import { apiPath, cableURL } from '../../utils/api'
import FilterableTabletLocation from './FilterableTabletLocation'
import AddPlace from './AddPlace/AddPlace'
import PlaceDetail from './PlaceDetail/PlaceDetail'
import EditPlace from './EditPlace/EditPlace'
import ActionCable from "actioncable";
import FlashMessagesContainer from './FlashMessagesContainer'
import './TabletLocation.scss'
import { confirmAlert } from 'react-confirm-alert'

class TabletLocation extends React.PureComponent {
    constructor(props) {
        super(props)

        this.tabIdentity = Math.random();
        this.state = {
            currentComponent: "FILTERABLE_TABLET_LOCATION",
            filterListingText: "",
            assignedStatus: "all",
            locationType: "all",
            oldestUpdatedAt: null,
            isLoadTabletLocationList: false,
            hasMoreTabletLocations: true,
            tabletLocations: [],
            flashMessages: [],
            scrollPosition: null,
            waitingSearchTimeout: null,
            detailedLocationId: null,
            editedLocationId: null,
            currentPage: null,
            totalPage: null
        }

        this.handleClickOnTabletLocationId = this.handleClickOnTabletLocationId.bind(this)
        this.handleReturnToFilterableTabletLocations = this.handleReturnToFilterableTabletLocations.bind(this)
        this.handleSetAssignedStatus = this.handleSetAssignedStatus.bind(this)
        this.handleSetLocationType = this.handleSetLocationType.bind(this)
        this.handleSetFilterListingText = this.handleSetFilterListingText.bind(this)
        this.handleSetCurrentComponent = this.handleSetCurrentComponent.bind(this)
        this.handleClickOnEditTabletLocation = this.handleClickOnEditTabletLocation.bind(this)
        this.handleAddTabletLocation = this.handleAddTabletLocation.bind(this)
        this.handleRemoveFlashMessage = this.handleRemoveFlashMessage.bind(this)
        this.handleAddFlashMessage = this.handleAddFlashMessage.bind(this)
        this.handleUpdateTabletLocation = this.handleUpdateTabletLocation.bind(this)
        this.handleDeleteTabletLocation = this.handleDeleteTabletLocation.bind(this)
        this.handleLoadMoreTabletLocation = this.handleLoadMoreTabletLocation.bind(this)
        this.handleTrackingScrollPosition = this.handleTrackingScrollPosition.bind(this)
    }

    componentDidMount() {
        const filterParams = {
            assignedStatus: this.state.assignedStatus,
            locationType: this.state.locationType,
            q: this.state.filterListingText
        }
        window.axios.get(apiPath('places'), { params: filterParams })
            .then(response => {
                let oldestUpdatedAt = response.data.places[response.data.places.length - 1]["updated_at"]
                this.setState({ tabletLocations: response.data.places, oldestUpdatedAt: oldestUpdatedAt })
            })

        const cable = ActionCable.createConsumer(cableURL());
        this.channel = cable.subscriptions.create("PlaceChannel", {
            connected: () => {
            },
            disconnected: () => {

            },
            received: (data) => {
                if (this.tabIdentity !== data.from_identity) {
                    switch (data.update_type) {
                        case 'createLocation':
                            this.handleAddTabletLocationFromSocket(data.location)
                            break;
                        case 'updateExistingLocation':
                            this.handleUpdateExistingTabletLocationFromSocket(data.location)
                            break;
                        case 'deleteLocation':
                            this.handleDeleteTabletLocationFromSocket(data.location)
                            break;
                        default:
                            break;
                    }
                }
            },
            broadcast_update_location: function (identity, location, updateType) {
                this.perform('broadcast_update_location', {
                    identity: identity,
                    location: location,
                    update_type: updateType
                })
            }
        })
    }

    isTabletLocationSatisfyFitler(tabletLocation) {
        let checkContainText = () => {
            let dataInput = []
            dataInput.push(tabletLocation.id.toString())
            dataInput.push(tabletLocation.address)
            if (tabletLocation.listings.length > 0) {
                tabletLocation.listings.forEach(element => {
                    dataInput.push(element['short_name'])
                    dataInput.push(element['address'])
                });
            }
            return dataInput.toString().includes(filterListingText)
        }
        let assignedStatus, locationType, filterListingText
        ({ assignedStatus, locationType, filterListingText } = this.state)
        let isSatisfyAssignedStatus = (assignedStatus === 'all' || assignedStatus === 'no') ? true : false
        let isSatisfyLocationType = (tabletLocation.place_type === locationType || locationType === 'all') ? true : false
        let isSatisfyFilterListingText = (filterListingText === '' || checkContainText()) ? true : false
        return isSatisfyAssignedStatus && isSatisfyLocationType && isSatisfyFilterListingText
    }

    handleAddTabletLocationFromSocket(location) {
        let tabletLocations = [...this.state.tabletLocations]
        tabletLocations.unshift(location)
        this.setState({ tabletLocations: tabletLocations })
    }

    handleUpdateExistingTabletLocationFromSocket(location) {
        let tabletLocations = [...this.state.tabletLocations].filter((localLocation) => {
            return localLocation.id !== location.id
        })
        tabletLocations.unshift(location)
        this.setState({ tabletLocations: tabletLocations })
    }

    handleDeleteTabletLocationFromSocket(location) {
        let tabletLocations = [...this.state.tabletLocations].filter((localLocation) => {
            return !location.includes(localLocation.id)
        })
        this.setState({ tabletLocations: tabletLocations })
    }

    handleClickOnTabletLocationId(id) {
        this.setState({ currentComponent: "DETAILED_TABLET_LOCATION", detailedLocationId: id })
    }

    handleClickOnEditTabletLocation(id) {
        this.setState({ currentComponent: "EDIT_TABLET_LOCATION", editedLocationId: id })
    }

    handleTrackingScrollPosition(scrollPosition) {
        this.setState({ scrollPosition: scrollPosition })
    }

    handleReturnToFilterableTabletLocations() {
        this.setState({ currentComponent: "FILTERABLE_TABLET_LOCATION", flashMessages: [] })
    }

    handleSetAssignedStatus(status) {
        this.setState({ assignedStatus: status, scrollPosition: 0 }, () => this.updateTabletLocations())
    }

    handleSetLocationType(type) {
        this.setState({ locationType: type, scrollPosition: 0 }, () => this.updateTabletLocations())
    }

    handleSetFilterListingText(value) {
        let state = { ...this.state }
        if (state.waitingSearchTimeout) {
            clearTimeout(state.waitingSearchTimeout)
        }
        state.filterListingText = value
        state.waitingSearchTimeout = setTimeout(() => {
            this.updateTabletLocations()
        }, 1000)
        state.scrollPosition = 0
        this.setState(state)
    }

    handleSetCurrentComponent(component) {
        this.setState({ currentComponent: component, flashMessages: [] })
    }

    handleRemoveFlashMessage(id) {
        let flashMessages = [...this.state.flashMessages]
        let removedMessage = flashMessages.indexOf(message => message.id === id)
        flashMessages.splice(removedMessage, 1)
        this.setState({ flashMessages: flashMessages })
    }

    handleAddFlashMessage(message) {
        let flashMessages = []
        flashMessages.push(message)
        this.setState({ flashMessages: flashMessages })
    }

    handleAddTabletLocation(data) {
        let addedLocation = data.place || data
        if (data['success'] && this.isTabletLocationSatisfyFitler(addedLocation)) {
          let message = { text: `Location #${addedLocation.id} added successfully`, type: "success", iD: Math.random() }
          let messages = [...this.state.flashMessages, message]
            let tabletLocations = [...this.state.tabletLocations]
            tabletLocations.unshift(addedLocation)
            this.setState({ tabletLocations: tabletLocations, currentComponent: "FILTERABLE_TABLET_LOCATION" }, () => {
                this.setState({ flashMessages: messages }, () => {
                    setTimeout(() => {
                        this.handleRemoveFlashMessage(message.iD)
                    }, 4000);
                })
            })
        } else {
          let message = { text: data.message, type: "error", iD: Math.random() }
          let messages = [...this.state.flashMessages, message]
          this.setState({ currentComponent: "FILTERABLE_TABLET_LOCATION", flashMessages: messages }, () => {
              setTimeout(() => {
                  this.handleRemoveFlashMessage(message.iD)
              }, 4000);
          })
        }
        this.channel.broadcast_update_location(this.tabIdentity, addedLocation, "createLocation")
    }

    handleDeleteTabletLocation(locationId) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="popup-remove-place">
                        <h2>Warning</h2>
                        <div className='body'>
                            <p>{`Are you sure you want to delete Tablet Location #${locationId}`}</p>
                            <p>All its data will be terminated permanently.</p>
                        </div>
                        <div className='text-center'>
                            <button className='btn submit' onClick={(e) => {
                                e.currentTarget.disabled = true
                                e.currentTarget.nextElementSibling.disabled = true
                                window.axios.delete(apiPath(`places/${locationId}`)).then(response => {
                                    if (response.data['success']) {
                                        let tabletLocations = [...this.state.tabletLocations].filter((location) => {
                                            return !response.data.places.includes(location.id)
                                        })
                                        this.setState({ tabletLocations: tabletLocations, scrollPosition: 0 }, () => {
                                            onClose()
                                            let message = { text: `Location #${locationId} was deleted`, type: "error", iD: Math.random() }
                                            let messages = [...this.state.flashMessages, message]
                                            this.setState({ flashMessages: messages }, () => {
                                                setTimeout(() => {
                                                    this.handleRemoveFlashMessage(message.iD)
                                                }, 4000);
                                            })
                                            this.channel.broadcast_update_location(this.tabIdentity, response.data.places, 'deleteLocation')
                                        })
                                    }
                                })
                            }}>
                                Delete anyway
                            </button>
                            <button className='btn cancel' onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                )
            }
        })
    }

    handleUpdateTabletLocation(data) {
        let tabletLocations = [...this.state.tabletLocations].filter((location) => {
            return location.id !== data.place.id
        })
        tabletLocations.unshift(data.place)
        this.setState({ tabletLocations: tabletLocations, currentComponent: "FILTERABLE_TABLET_LOCATION", scrollPosition: 0 }, () => {
            let message = { text: `Tablet location #${data.place.id} updated successfully`, type: "alert", iD: Math.random() }
            let messages = [...this.state.flashMessages, message]
            this.setState({ flashMessages: messages }, () => {
                setTimeout(() => {
                    this.handleRemoveFlashMessage(message.iD)
                }, 4000);
            })
            this.channel.broadcast_update_location(this.tabIdentity, data.place, 'updateExistingLocation')
        })
    }

    handleLoadMoreTabletLocation() {
        this.setState({ isLoadTabletLocationList: true })
        const filterParams = {
            assignedStatus: this.state.assignedStatus,
            locationType: this.state.locationType,
            q: this.state.filterListingText,
            olderUpdatedAt: this.state.oldestUpdatedAt
        }
        let hasMoreTabletLocations = null
        let oldestUpdatedAt = null
        let moreTabetLocations = null
        window.axios.get(apiPath('places'), { params: filterParams })
            .then(response => {
                hasMoreTabletLocations = (response.data.places.length < 15) ? false : true
                moreTabetLocations = (response.data.places.length > 0) ? [...this.state.tabletLocations, ...response.data.places] : [...this.state.tabletLocations]
                oldestUpdatedAt = (response.data.places.length > 0) ? response.data.places[response.data.places.length - 1]["updated_at"] : this.state.oldestUpdatedAt
                this.setState({ hasMoreTabletLocations: hasMoreTabletLocations, tabletLocations: moreTabetLocations, oldestUpdatedAt: oldestUpdatedAt, isLoadTabletLocationList: false })
            })
    }

    updateTabletLocations() {
        const filterParams = {
            assignedStatus: this.state.assignedStatus,
            locationType: this.state.locationType,
            q: this.state.filterListingText
        }
        window.axios.get(apiPath('places'), { params: filterParams })
            .then(response => {
                if (response.data.places.length < 15) {
                    this.setState({ tabletLocations: response.data.places, hasMoreTabletLocations: false })
                } else {
                    let oldestUpdatedAt = response.data.places[response.data.places.length - 1]["updated_at"]
                    this.setState({ tabletLocations: response.data.places, hasMoreTabletLocations: true, oldestUpdatedAt: oldestUpdatedAt })
                }
            })
    }

    render() {
        let currentComponent = null
        switch (this.state.currentComponent) {
            case "FILTERABLE_TABLET_LOCATION":
                currentComponent =
                    <FilterableTabletLocation
                        tabletLocations={this.state.tabletLocations}
                        filterListingText={this.state.filterListingText}
                        assignedStatus={this.state.assignedStatus}
                        locationType={this.state.locationType}
                        isLoadTabletLocationList={this.state.isLoadTabletLocationList}
                        hasMoreTabletLocations={this.state.hasMoreTabletLocations}
                        scrollPosition={this.state.scrollPosition}
                        handleClickOnTabletLocationId={this.handleClickOnTabletLocationId}
                        handleClickOnEditTabletLocation={this.handleClickOnEditTabletLocation}
                        handleSetAssignedStatus={this.handleSetAssignedStatus}
                        handleSetLocationType={this.handleSetLocationType}
                        handleSetFilterListingText={this.handleSetFilterListingText}
                        handleSetCurrentComponent={this.handleSetCurrentComponent}
                        handleDeleteTabletLocation={this.handleDeleteTabletLocation}
                        handleLoadMoreTabletLocation={this.handleLoadMoreTabletLocation}
                        handleTrackingScrollPosition={this.handleTrackingScrollPosition}
                    />
                break;
            case "ADD_TABLET_LOCATION":
                currentComponent =
                    <AddPlace
                        handleReturnToFilterableTabletLocations={this.handleReturnToFilterableTabletLocations}
                        handleAddTabletLocation={this.handleAddTabletLocation}
                        handleAddFlashMessage={this.handleAddFlashMessage} />
                break;
            case "EDIT_TABLET_LOCATION":
                currentComponent =
                    <EditPlace
                        id={this.state.editedLocationId}
                        handleReturnToFilterableTabletLocations={this.handleReturnToFilterableTabletLocations}
                        handleAddFlashMessage={this.handleAddFlashMessage}
                        handleUpdateTabletLocation={this.handleUpdateTabletLocation} />
                break;
            case "DETAILED_TABLET_LOCATION":
                currentComponent =
                    <PlaceDetail
                        id={this.state.detailedLocationId}
                        handleReturnToFilterableTabletLocations={this.handleReturnToFilterableTabletLocations}
                        handleClickOnEditTabletLocation={this.handleClickOnEditTabletLocation} />
                break;
            default:
                break;
        }

        return (
            <div className={"tablet-location-container" + (this.props.isHidden ? ' hidden' : '')}>
                <FlashMessagesContainer flashMessages={this.state.flashMessages} onClose={this.handleRemoveFlashMessage} />
                {currentComponent}
            </div>
        )
    }
}

export default TabletLocation