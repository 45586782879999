import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ZenLogo from '../../images/ZensTabletLogo.png';
import ZenLogoSmall from '../../images/ZenLogoSmall.png';
import { apiPath, cableURL } from '../../utils/api';
import ActionCable from "actioncable";
import { withRouter } from 'react-router'
import './NewVideoCall.scss'
import moment from 'moment';
import axios from 'axios';
import Logout from '../../components/Logout/Logout';
import DetailedSeletedItem from './DetailedSelectedItem/DetailedSelectedItem';
import Notification from 'react-web-notification';
import { Link } from 'react-router-dom';
import {
  fetchingHistoryCalls,
  receiveHistoryCalls,
  receiveIncomingCalls,
  receiveNotificationCalls,
  receiveNotificationCall,
  receiveIncomingCall,
  ignoreIncomingCall,
  receiveHistoryCall,
  removeIncomingCall,
} from 'actions/list-calls';
import { updateCallFilter } from 'actions/call-filter';
import {
  receiveListings,
  addListing
} from 'actions/listing'
import IncomingCalls from '../IncomingCalls/IncomingCalls'
import HistoryCalls from '../HistoryCalls/HistoryCalls'
import ReservationsList from '../ReservationsList/ReservationsList'
import SearchBarOnReservationList from '../ReservationsList/SearchBarOnReservationList'
import SearchBar from '../HistoryCalls/SearchBar'
import IncomingCallNotificationBar from '../IncomingCallNotificationBar/IncomingCallNotificationBar'
import incomingAudioFile from '../../audios/incoming_call.mp3';
import { CALL_WAITING_TIME } from 'configs/constants.js'
import CallUtil from '../../utils/callUtil'
import { tabletUnavailableAlert, clientLostConnectionAlert } from '../ConfirmAlert/ConfirmAlert'
import TabletLocation from '../TabletLocation/TabletLocation'

class CNewVideoCall extends Component {
  constructor(props) {
    super(props)
    this.channel = null;
    this.checkinChannel = null;
    this.noCallTabletType = 0;
    this.operatorName = 'abc'; // local
    this.role = ['admin', 'support'] // local
    this.incommingCallTimeouts = []

    this.callListAudios = []
    if (process.env.NODE_ENV !== 'development') {
      this.operatorName = JSON.parse(localStorage.getItem('authToken'))['authUser']['name']; // staging
      this.role = JSON.parse(localStorage.getItem('authToken'))['authUser']['roles']
    }

    console.log(this.role = JSON.parse(localStorage.getItem('authToken'))['authUser']['roles'])

    this.showNotification = this.showNotification.bind(this)
    this.updateNotiCall = this.updateNotiCall.bind(this)
    this.receiveCall = this.receiveCall.bind(this)
    this.dismissCall = this.dismissCall.bind(this)
    this.handleClickOnDetailedItem = this.handleClickOnDetailedItem.bind(this)
    this.switchTab = this.switchTab.bind(this)
    this.sidebarToggle = this.sidebarToggle.bind(this)
    this.backToList = this.backToList.bind(this)
    this.handleNotificationOnClick = this.handleNotificationOnClick.bind(this)
    this.handleNotificationOnClose = this.handleNotificationOnClose.bind(this)
    this.handleNotificationOnError = this.handleNotificationOnError.bind(this)
    this.state = {
      sidebarOpen: true,
      tabSelect: "INCOMMING_CALLS", // 0 - Incoming call, 1 - History Call
      itemSelected: null,
      // use for scroll infinity
      //noti
      ignore: true,
      title: '',
      //noti bar
      notiCalls: [],
      toggleDateRangePicker: false,
      timeOutId: null,
      audioIframePlaying: false,
    };
    this.audioPlay = this.audioPlay.bind(this)
    this.audioStop = this.audioStop.bind(this)

  }

  componentDidMount() {
    this.setState({ tabSelect: localStorage.getItem('NewVideoCallTab') })
    const t = this.context.t
    switch (this.props.location.flag) {
      case 'ignored':
        tabletUnavailableAlert(t)
        break
      case 'clientLostConnection':
        clientLostConnectionAlert(t)
        break
      default:
        break;
    }

    const self = this
    this.props.fetchingHistoryCalls()
    this.fetchListCall()
    const cable = ActionCable.createConsumer(cableURL());
    this.channel = cable.subscriptions.create("CallChannel", {
      connected: () => {
        console.log(`Connect to the CallChannel ***${new Date()}***`)
      },
      disconnected: () => {
        console.log(`Disconnect to the CallChannel ***${new Date()}***`)
      },
      received: data => {
        let callAction = data.call_action

        if (data.call) {
          let call = data.call

          call['created_at'] = moment(new Date(call.created_at)).format("hh:mm A, YYYY/MM/DD");
          switch (callAction) {
            case 'created':
              self.handleReceivedCall(call);
              console.log(call)
              break;
            case 'connected':
              console.log("connected case")
              self.handleConnectedCall(call);
              break;
            case 'ended':
            case 'canceled':
            case 'completed':
              console.log(callAction)
              self.handleRemoveCall(call);
              break
            default:
              console.log('default case')
              console.log(callAction)
              break;
          }
        }
      },
      accept_call: function (call) {
        this.perform('accept_call', {
          call_id: call.id
        });
      }
    });
  }

  componentWillUnmount() {
    this.callListAudios.forEach((item => {
      item.audio.pause()
    }))
    this.channel.unsubscribe()
    delete this.channel

    let { filter } = { ...this.props.callFilter }
    filter = Object.assign(filter, {
      start_date: null,
      end_date: null,
      q: null,
      calling_type: ['all']
    })

    this.props.updateCallFilter(filter)
  }

  attachReservationToCall(call, listings = null) {
    let callCopy = { ...call }
    if (!callCopy.reservation) {
      return callCopy
    }
    let listListing = listings || this.props.listings
    let callListing = listListing.find(item => { return item.listing_id === callCopy.reservation.listing_id })
    if (callListing) {
      callCopy.reservation.listing = callListing
      return callCopy
    }
  }

  attachReservationToListCall(listCall, listings = null) {
    let newListCall = []
    listCall.forEach(call => {
      let callWithRes = this.attachReservationToCall(call, listings)
      newListCall.push(callWithRes)
    })
    return newListCall
  }

  fetchListCall() {
    const self = this
    window.axios.get(apiPath('calls/list_calls')).then(response => {
      let historyCalls = response.data['missed_calls'].concat(response.data['completed_calls'])
      let incomingCalls = response.data['incoming_calls']
      let listings = response.data.listings

      self.props.receiveListings(listings)

      incomingCalls.forEach(call => {
        this.audioPlay(call.id)
      })
      if (incomingCalls.length) {
        this.setState({ audioIframePlaying: true })
      }

      let historyCallsWithRes = this.attachReservationToListCall(historyCalls, listings)
      let incomingCallsWithRes = this.attachReservationToListCall(incomingCalls, listings)

      self.props.receiveHistoryCalls(historyCallsWithRes)
      self.props.receiveIncomingCalls(incomingCallsWithRes)
      self.props.receiveNotificationCalls(incomingCalls)
      self.noCallTabletType = self.props.incomingCalls.length
    })
  }

  sidebarToggle() {
    const sidebarOpen = this.state.sidebarOpen
    this.setState({ sidebarOpen: !sidebarOpen })
  }

  switchTab(tabSelect) {
    this.setState({ tabSelect: tabSelect, itemSelected: null })
    localStorage.setItem("NewVideoCallTab", tabSelect)
  }

  backToList() {
    this.setState({ tabSelect: this.state.tabSelect, itemSelected: null })
  }

  receiveCall(call, type) {
    let callParams = {
      status: 'connected',
    }
    CallUtil.updateCall(call.id, callParams)

    // Clear timeout for received Call
    let callToClearTimeOut = this.incommingCallTimeouts.find(item => { return item.callId === call.id })
    if (callToClearTimeOut) { clearTimeout(callToClearTimeOut.timeout) }

    this.handleRemoveCall(call)
    this.props.history.push({
      pathname: '/call_screen',
      call: call,
      type: type,
    })
  }

  handleReceivedCall(call) {
    if (call.reservation && call.reservation.listing) {
      let isExistListing = this.props.listings.find((listing) => listing.listing_id === call.reservation.listing_id)
      if (!isExistListing) {
        this.props.addListing(call.reservation.listing)
      }
    }
    this.props.receiveIncomingCall(call);
    this.showNotification(call);
    this.updateNotiCall(call);
    this.audioPlay(call.id);
    this.handleTimeoutIncomingCall(call)
  }

  handleReceiveHistoryCall(call) {
    let { start_date: startDate, end_date: endDate, q: searchValue, calling_type: callingType } = this.props.callFilter.filter
    let mustBePush = true
    if (startDate && endDate) {
      if (new Date(call.created_at.split(' ')[2]) < new Date(startDate) || new Date(call.created_at.split(' ')[2]) > new Date(endDate)) {
        mustBePush = false
      }
    }

    if (searchValue) {
      if (searchValue !== '') {
        if (!call.reservation || call.reservation.listing.short_name !== searchValue) {
          mustBePush = false
        }
      }
    }

    if (callingType[0] !== 'all') {
      if (call.calling_type !== callingType[0]) {
        mustBePush = false
      }
    }

    let existCall = this.props.historyCalls.find(historyCall => historyCall.id === call.id)
    if (existCall) {
      mustBePush = false
    }

    if (mustBePush) {
      this.props.receiveHistoryCall(call)
    } else {
      return
    }
  }

  handleRemoveCall(call) {
    let newCall = this.attachReservationToCall(call)
    this.handleReceiveHistoryCall(newCall)
    this.props.removeIncomingCall(newCall)
    this.props.ignoreIncomingCall(newCall)
    this.audioStop(newCall.id)
  }

  handleConnectedCall(call) {
    console.log('Received by other operator')
    let newCall = this.attachReservationToCall(call)
    this.props.removeIncomingCall(newCall)
    this.props.ignoreIncomingCall(newCall)
    this.audioStop(newCall.id)
  }

  handleHistoryPageChange(pageNumber) {
    this.setState({ activeHistoryPage: pageNumber });
  }

  handlePermissionGranted() {
    this.setState({ ignore: false });
  }

  handlePermissionDenied() {
    this.setState({ ignore: true });
  }

  handleNotSupported() {
    this.setState({ ignore: true });
  }

  handleNotificationOnClick(e, tag) {
    window.focus()
    this.setState({ tabSelect: "INCOMMING_CALLS", title: '' })
    e.target.close()
  }

  handleNotificationOnError(e, tag) {
    console.log(e, 'Notification error tag:' + tag);
  }

  handleNotificationOnClose(e, tag) {
    console.log(e, 'Notification closed tag:' + tag);
  }

  handleNotificationOnShow(e, tag) {
    console.log(e, 'Notification shown tag:' + tag);
  }

  handleTimeoutIncomingCall(call) {
    // end this call from incoming call list after 60s
    let timeout = setTimeout(() => {
      let isIncomingCall = this.props.notificationCalls.find(item => { return item.id === call.id })
      if (!isIncomingCall) return
      this.handleEndCall(call)
    }, CALL_WAITING_TIME)
    this.incommingCallTimeouts.push({ callId: call.id, timeout: timeout })
  }

  handleEndCall(call) {
    axios.put(apiPath(`calls/${call.id}/cancel`), {
      device: call.reservation && call.reservation.device
    })
      .then(response => {
        console.log('Canceled the call')
      })
  }

  showNotification(call) {
    if (this.state.ignore) {
      return;
    }
    const t = this.context.t
    const title = t('browserNotification.title')
    const body = t('browserNotification.bodyText')
    const tag = Date.now();
    const options = {
      tag: tag,
      body: body,
      icon: 'ZenLogoSmall.png',
      lang: 'en',
      dir: 'ltr',
      sound: './sound.mp3'
    }
    this.setState({ title: title, options: options });
  }

  updateNotiCall(call) {
    let { notiCalls } = { ...this.state }
    notiCalls.push(call)
    this.setState({ notiCalls: notiCalls })
    this.props.receiveNotificationCall(call)
  }

  dismissCall(call) {
    let state = { ...this.state }
    state.notiCalls.shift()
    this.setState(state)
    this.props.ignoreIncomingCall(call)
    this.audioStop(call.id)
  }

  audioPlay(callID) {
    let existCall = this.callListAudios.find((call) => {
      return call.id === callID
    })
    if (existCall) {
      return
    }
    let audio = new Audio(incomingAudioFile)
    let callAudio = {
      id: callID,
      audio: audio
    }

    // Playing audio
    let audioPromise = audio.play()
    audioPromise
      .then()
      .catch(err => {
        console.log(err.message)
      })

    // Handle loop
    audio.addEventListener('ended', () => {
      if (this.callListAudios.length === 0) {
        audio.pause()
        audio = null
      } else {
        console.log(this.callListAudios)
        console.log(audio)
        audio.currentTime = 0;
        audio.play()
      }
    }, false)

    // Push audio to state
    this.callListAudios.push(callAudio)
    console.log(this.callListAudios)
  }

  audioStop(callID) {
    let callAudio = this.callListAudios.find(audio => { return audio.id === callID })

    // Pause audio
    if (callAudio) {
      callAudio.audio.pause()
      callAudio.audio = null
      this.callListAudios.splice(this.callListAudios.indexOf(callAudio), 1)
    }

    console.log(this.callListAudios)
  }

  renderAudioIframe() {
    if (this.props.notificationCalls.length && this.state.audioIframePlaying) {
      return (
        <iframe title="Call Sound" src={incomingAudioFile} allow='autoplay' className='d-none' id='audio-iframe' />
      )
    } else {
      return <div></div>
    }
  }

  handleClickOnDetailedItem(item) {
    this.setState({ itemSelected: item })
  }

  renderListCallContentTitle() {
    let result = ''
    switch (this.state.tabSelect) {
      case "INCOMMING_CALLS":
        result = 'Incoming Calls'
        break;
      case 'HISTORY_CALLS':
        result = 'History Calls'
        break;
      case 'RESERVATIONS_LIST':
        result = 'Reservation List'
        break;
      case 'TABLET_LOCATION':
        result = "Tablet Location"
        break;
      default:
        break;
    }
    return result
  }

  renderSideBar() {
    let sideBarElements = [
      {
        tabSelect: "INCOMMING_CALLS",
        title: "INCOMING CALLS",
        icon: "la la-phone"
      },
      {
        tabSelect: "HISTORY_CALLS",
        title: "CALL HISTORY",
        icon: "la la-history"
      },
      {
        tabSelect: "RESERVATIONS_LIST",
        title: "RESERVATION LIST",
        icon: "la la-dashboard"
      },
      {
        tabSelect: "TABLET_LOCATION",
        title: "TABLET LOCATION",
        icon: "la la-calculator"
      }
    ]

    return (
      <ul>
        {
          sideBarElements.map((sideBarElement) =>
            <li key={sideBarElement.tabSelect} className={this.state.tabSelect === sideBarElement.tabSelect ? 'li-select' : undefined}
              onClick={() => this.switchTab(sideBarElement.tabSelect)}>
              <i className={sideBarElement.icon + (this.state.sidebarOpen ? " right-20" : " left-230")} />
              {this.state.sidebarOpen ? sideBarElement.title : ""}
            </li>
          )
        }
      </ul>
    )
  }

  render() {
    return (
      <div className='new-list-call'>
        <IncomingCallNotificationBar
          receiveCall={this.receiveCall}
          dismissCall={this.dismissCall}
        />
        {
          this.renderAudioIframe()
        }

        {this.state.sidebarOpen === true ?
          <div className='sidebar sidebar-open'>
            <img src={ZenLogo} alt='zen-logo' className='zen-logo-new' />
            {this.renderSideBar()}
          </div> :
          <div className='sidebar'>
            <div className='row'>
              <img src={ZenLogoSmall} alt='zen-logo-small' className='zen-logo-small' />
            </div>
            {this.renderSideBar()}
          </div>
        }

        <div className={this.state.sidebarOpen === true ? 'content content-open' : 'content '}>
          <div className='header'>
            <div className='row w-100'>
              <div className='col-md-6 col-lg-6'>
                <button className='btn sidebarToggle' onClick={this.sidebarToggle}>
                  <FontAwesomeIcon icon="bars" />
                </button>
                <span className='header-title'>{this.renderListCallContentTitle()}</span>
              </div>
              <div className='col-md-6 col-lg-6 text-right'>
                <div className='d-flex float-right'>
                  {this.state.tabSelect === "HISTORY_CALLS" &&
                    <SearchBar />
                  }
                  {this.state.tabSelect === "RESERVATIONS_LIST" &&
                    <SearchBarOnReservationList />
                  }
                  <div className="dropdown user-menu">
                    <button className="btn dropdown-toggle operator-name" type="button" data-toggle="dropdown">{this.operatorName}@zens</button>
                    <ul className="dropdown-menu text-left px-2">
                      <li>
                        {(this.role.includes('super_admin') || this.role.includes('admin')) && <Link to={`/places`}>Move to Admin Page</Link>}
                      </li>
                      <li><Logout /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={(this.state.tabSelect === "INCOMMING_CALLS" && !this.state.itemSelected) ? 'incoming-call tab-content' : 'incoming-call tab-content hidden'}>
            <IncomingCalls
              handleClickOnDetailedItem={this.handleClickOnDetailedItem}
              receiveCall={this.receiveCall}
            />
          </div>
          <div className={(this.state.tabSelect === "HISTORY_CALLS" && !this.state.itemSelected) ? 'history-call tab-content' : 'history-call tab-content hidden'} id='history-call'>
            <HistoryCalls handleClickOnDetailedItem={this.handleClickOnDetailedItem} />
          </div>

          <div className={(this.state.tabSelect === "RESERVATIONS_LIST" && !this.state.itemSelected) ? 'reservations-list tab-content' : 'reservation-list tab-content hidden'} id="reservations-list">
            <ReservationsList handleClickOnDetailedItem={this.handleClickOnDetailedItem} />
          </div>

          {(this.role.includes("admin") || this.role.includes("support") || this.role.includes("operation")) && <TabletLocation isHidden={this.state.tabSelect !== "TABLET_LOCATION"} />}

          {this.state.itemSelected && <DetailedSeletedItem backToList={this.backToList} itemSelected={this.state.itemSelected} />}
        </div>

        <div>
          <Notification
            ignore={this.state.ignore && this.state.title !== ''}
            notSupported={this.handleNotSupported.bind(this)}
            onPermissionGranted={this.handlePermissionGranted.bind(this)}
            onPermissionDenied={this.handlePermissionDenied.bind(this)}
            onShow={this.handleNotificationOnShow}
            onClick={this.handleNotificationOnClick}
            onClose={this.handleNotificationOnClose}
            onError={this.handleNotificationOnError}
            timeout={5000}
            title={this.state.title}
            options={this.state.options}
          />
        </div>
      </div>
    )
  }
}

CNewVideoCall.defaultProps = {
  listCalls: {},
  listingsList: {},
  incomingCalls: [],
  historyCalls: [],
  listings: [],
  notificationCalls: [],
}

CNewVideoCall.contextTypes = {
  t: PropTypes.func
}

const RNewVideoCall = withRouter(CNewVideoCall)

const mapStateToProps = state => ({
  callFilter: state.callFilter,
  incomingCalls: state.listCalls.incomingCalls,
  historyCalls: state.listCalls.historyCalls,
  listings: state.listingsList.listings,
  notificationCalls: state.listCalls.notificationCalls,
})

const mapDispatchToProps = {
  fetchingHistoryCalls: fetchingHistoryCalls,
  receiveHistoryCalls: receiveHistoryCalls,
  receiveIncomingCalls: receiveIncomingCalls,
  receiveNotificationCalls: receiveNotificationCalls,
  receiveNotificationCall: receiveNotificationCall,
  receiveIncomingCall: receiveIncomingCall,
  ignoreIncomingCall: ignoreIncomingCall,
  receiveListings: receiveListings,
  addListing: addListing,
  removeIncomingCall: removeIncomingCall,
  receiveHistoryCall: receiveHistoryCall,
  updateCallFilter: updateCallFilter
}

const NewVideoCall = connect(
  mapStateToProps,
  mapDispatchToProps
)(RNewVideoCall)

export default NewVideoCall