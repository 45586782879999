export const en = {
  app_title: "ZenTablet",
  app_title_detail: "ZenTablet",
  home: 'Home',
  email: 'Email',
  password: 'Password',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  remember_me: 'Remember Me',
  forgot_password: 'Forgot Password',
  login: 'Log In',
  'login.enter_email': 'Enter your email',
  'login.enter_password': 'Enter your password',
  'login.invalid': 'Invalid email or password. Please try again.',
  'reset_password.type_email': 'Enter your email to receive the reset password instruction',
  'reset_password.link_sent': 'An email with reset password link has been sent to your email',
  'reset_password.password_changed': 'Your password has been changed successfully!',
  back_to_home: 'Back to home',
  back_to_login: 'Go back to login page',
  'reset_password.email_reset_password_sent': 'Password reset instruction has been sent',
  'reset_password.please_check_email': 'Please check you email inbox and follow the instruction to reset your password',
  'receive_call.please_validate': 'Please validate before turning call off',
  type: 'Type',
  listings: 'Listing(s)',
  tablet_uid: 'Tablet UID',
  tablet_info: 'Tablet Info',
  address: 'Address',
  setup_code: 'Setup Code',
  inside: 'Inside',
  outside: 'Outside',
  create: 'Create',
  cancel: 'Cancel',
  back: 'Back',
  edit: 'Edit',
  'listings.required': 'Listing field is required',
  'address.required': 'Address field is required',
  'slaves.required': 'Places to be controled is required',
  save: 'Save',
  'add_multiple_listings': 'You may add multiple listings',
  'enter_tablet': 'Enter Tablet UID',
  'enter_address': 'Enter address',
  'no_places': 'There is no tablet place added at the moment',
  'edit_place.add_one_listing_placeholder': 'You can add only listing',
  'edit_place.select_listings_placeholder': 'Select listings',
  'call_detail.check_in': 'Check-in',
  'call_detail.check_out': 'Check-out',
  'call_detail.number_of_guests': 'Number of Guests',
  'call_detail.1_guest': '01 Guest',
  'call_detail.{n}_guests': '{n} Guests',
  'call_detail.ticket_page': 'Ticket page',
  'call_detail.listing_id': 'Listing ID',
  'call_detail.listing_short_name': 'Listing short name',
  'call_detail.tablet_type': 'Tablet_type',
  'call_detail.address': 'Address',
  'call_detail.guest_name': 'Name',
  'call_detail.guest_address': 'Address',
  'call_detail.guest_nationality': 'Nationality',
  'call_detail.guest_occupation': 'Occupation',
  'call_detail.guest_passport_number': 'Passport Number',
  'call_detail.messenger.send_message': 'Send message',
  'call_detail.verified_btn': 'Verified',
  'call_detail.verify_flash_fail': 'Verify later',
  'call_detail.verify_flash_success': 'Verified as Successful',
  'history_call.call_back.confirm_verified_title': 'No Check-in needed',
  'history_call.call_back.confirm_verified_body': 'All guests in this reservation had been verified',
  'history_call.call_back.confirm_verified_close': 'Close',
  'history_call_detail.status.check_in_successfully': 'Check In Successfully',
  'history_call_detail.status.not_check_in_yet': 'Not Check In Yet',
  'history_call_detail.status.checkin_not_enough': 'Check In Not Enough',
  'browserNotification.title': 'New Incoming Call',
  'browserNotification.bodyText': 'You have a new call. Click here to view your Incoming Call list in ZensTablet',
  'confirm_alert.expiration_reservation_alert.body': 'Cannot make call due to the expiration of this reservation.',
  'confirm_alert.unassigned_listing_alert.title': 'Unassigned listing',
  'confirm_alert.unassigned_listing_alert.body': 'Unfortunately, this listing is currently not assigned to the Tablet Location of this reservation.',
  'confirm_alert.location_not_available.title': 'Location ID not available',
  'confirm_alert.location_not_available.bodyText': 'Unfortunately, this Location ID is currently not assigned to any Tablet. Hence, call cannot be made.',
  'place_detail.tablet_location': 'Tablet Location',
  'place_detail.location_id': 'Location ID',
  'place_editting.tablet_location': 'Edit Tablet Location',
  'confirm_alert.tablet_unavailable_alert.title': 'Tablet Currently Unavailable',
  'confirm_alert.tablet_unavailable_alert.body': 'The tablet might being in a call with another Guest Support',
  'confirm_alert.tablet_udid_changed_alert.body': 'Or tablet UDID was changed',
  'confirm_alert.client_lost_connection.title': 'Connection Lost',
  'confirm_alert.client_lost_connection.body': 'Call has been cancelled because the current tablet was disconnected from server',
  'confirm_alert.guests_filling_form.body': 'ZensRegister has not been filled out',
  'reservationsList.asking_calling_type.title': 'Which type of call would you like to make?'
}