import React, { Component } from 'react';
import ZenLogo from '../../images/ZenLogo.png';
import './LoginZensapp.css'
import { zensAppRedirectUrl } from '../../utils/api'
import { apiPath } from '../../utils/api'
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';

class LoginZensapp extends Component {
  componentDidMount() {
    if (this.props.location.search.split('data=').length > 1) {
      const dataRequest = {
        data: this.props.location.search.split('data=')[1]
      }

      axios({
        method: 'post',
        url: apiPath('authen'),
        data: dataRequest,
      }).then(response => {
        if (response.data['success']) {
          const today = new Date()
          const expiredAt = new Date(today.getFullYear(), today.getMonth(), today.getDate()+2)
          const authUser = response.data['user']
          localStorage.setItem('authToken', JSON.stringify(Object.assign({ authUser, expiredAt })))
          if (authUser['roles'].includes('admin') || authUser['roles'].includes('super_admin') || authUser['roles'].includes('operation')) {
            window.location = '/list_calls'
          } else {
            if (authUser['roles'].includes('support')) {
              window.location = '/list_calls'
            } else {
              this.showPopupWrongRole()
            }
          }
        }
      })
    }
  }

  showPopupWrongRole() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='popup-remopve-place popup-wrong-role'>
            <h2>Role not supported</h2>
            <div className='body'>
              <p className='mb-0'>Your account has no permission to access this page.</p>
              <p className='mb-0'>Please log out via ZensApp and login by another account which has Admin/Support role.</p>
              <div className='login-steps mx-auto text-left'>
                <p className='mb-0'><strong>Step 1:</strong> Move to ZensApp</p>
                <p className='mb-0'><strong>Step 2:</strong> Log out ZensApp</p>
                <p className='mb-0'><strong>Step 3:</strong> Login again by Admin/Support account</p>
              </div>
            </div>
            <div className='text-center'>
              <a href={process.env.REACT_APP_ZENS_APP_URL} target='_blank' rel="noopener noreferrer">
                <button className='btn submit mr-0 mb-2'>Move to ZensApp</button>
              </a>
            </div>
            <div className='text-center'>
              <a href={zensAppRedirectUrl()}>
                <button className='btn text-white login-again-btn'>Login Again</button>
              </a>
            </div>
          </div>
        )
      }
    })
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="login-form col-xs-6 col-sm-6 col-md-4 col-lg-3 text-center">
            <div className="zen-logo-login">
              <img src={ZenLogo} alt='zen-logo' />
            </div>
            <p>Log in with ZensApp to get started.</p>
            <div className="form-group group-button text-center">
              <a href={ `${zensAppRedirectUrl()}` }>
                <button type="submit" className="btn btn-submit">Login via Zensapp</button>
              </a>
            </div>
          </div>
        </div>
        <div className='copyright text-center'>
          <p>Copyright @ 2018 Zens</p>
        </div>
      </div>
    );
  }
}

export default LoginZensapp