import React, { Component } from 'react'

class FilterBar extends Component {

    constructor(props) {
        super(props)

        this.handleAssignedStatusFilter = this.handleAssignedStatusFilter.bind(this)
        this.handleClearSearch = this.handleClearSearch.bind(this)
    }

    renderShowCloseButton() {
        if (this.props.filterListingText) {
            return (
              <button className='btn btn-search-clear p-0 border-0' type='button' data-tooltip='tooltip' title='Clear Search' onClick={this.handleClearSearch} >
                <i className='la la-close color-dark' />
              </button>    
            )
          }
    }

    renderSearchInput() {
        return (
            <div className='flex-fill searchbar color-dark'>
                <form onSubmit={e => e.preventDefault()} className='form-group mb-0'>
                    <div className='input-group'>
                        <div className='input-group-prepend py-1'>
                            <i className='la la-search'></i>
                        </div>
                        <input className='form-control mb-0 border-0 search-form color-dark'
                            placeholder='Search for Location ID, Listing or Address'
                            value={this.props.filterListingText}
                            onChange={(e) => this.handleChangeSearchInput(e.target.value)}
                        />
                        { this.renderShowCloseButton() }
                    </div>
                </form>
            </div>
        )
    }

    handleClearSearch() {
        this.props.handleSetFilterListingText('')
    }

    handleChangeSearchInput(value) {
        this.props.handleSetFilterListingText(value)
    }

    handleLocationTypeFilter(key) {
        this.props.handleSetLocationType(key)
    }

    handleAssignedStatusFilter(key) {
        this.props.handleSetAssignedStatus(key)
    }

    renderTabletTypeFilter() {
        const tabletTypeText = filterGroup.locationType[this.props.locationType]
        return (
            <div className='dropdown ml-3 tablet-location-filter'>
                <span className='color-dark line-height-24'><b>Type:</b></span>
                <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <b className='color-black'>{tabletTypeText}</b>
                    <span className='la la-angle-down line-height-15 ml-3' />
                </button>
                <div className='dropdown-menu calling-type-list' aria-labelledby="dateRangeDropdown">
                    {
                        Object.keys(filterGroup.locationType).map(key => {
                            return (
                                <button key={key} className='btn dropdown-item color-dark' type='button' onClick={() => this.handleLocationTypeFilter(key)}>
                                    {filterGroup.locationType[key]}
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    renderAssignedDeviceFilter() {
        const assignedStatusText = filterGroup.assignedStatus[this.props.assignedStatus]
        return (
            <div className='dropdown ml-3 tablet-location-filter'>
                <span className='color-dark line-height-24'><b>Assigned:</b></span>
                <button className='btn btn-primary bg-transparent color-dark border-0 dropdown-toggle py-0' href='#' type='button' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <b className='color-black'>{assignedStatusText}</b>
                    <span className='la la-angle-down line-height-15 ml-3' />
                </button>
                <div className='dropdown-menu calling-type-list' aria-labelledby="dateRangeDropdown">
                    {
                        Object.keys(filterGroup.assignedStatus).map(key => {
                            return (
                                <button key={key} className='btn dropdown-item color-dark' type='button' onClick={() => this.handleAssignedStatusFilter(key)}>
                                    {filterGroup.assignedStatus[key]}
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='col-md-6'>
                <div className='d-flex float-right'>
                    {this.renderSearchInput()}
                    {this.renderAssignedDeviceFilter()}
                    {this.renderTabletTypeFilter()}
                </div>
            </div>
        )
    }
}

const filterGroup = {
    assignedStatus: {
        all: "All",
        yes: "Yes",
        no: "No"
    },
    locationType: {
        all: "All",
        inside: "Inside",
        outside: "Outside"
    }
}

export default FilterBar