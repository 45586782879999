import React from 'react'
import { confirmAlert } from 'react-confirm-alert';

const expirationReservationAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='popup-remopve-place popup-list-call'>
          <div className='body'>
            <p>{t('confirm_alert.expiration_reservation_alert.body')}</p>
          </div>
        </div>
      )
    }
  })
}

const unassignedListingAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='text-center popup-remopve-place popup-list-call'>
          <h5 className='title'>{t('confirm_alert.unassigned_listing_alert.title')}</h5>
          <div className='my-4 body'>
            <p>{t('confirm_alert.unassigned_listing_alert.body')}</p>
          </div>
          <div>
            <button className='btn bg-submit text-white' onClick={onClose}>Close</button>
          </div>
        </div>
      )
    }
  })
}

const locationNotAvailableAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='text-center popup-remove-place popup-list-call'>
          <h5 className='title'>{t('confirm_alert.location_not_available.title')}</h5>
          <div className='my-4 body'>
            <p>{t('confirm_alert.location_not_available.bodyText')}</p>
          </div>
          <div>
            <button className='btn bg-submit text-white' onClick={onClose}>Close</button>
          </div>
        </div>
      )
    },
    closeOnClickOutside: false,
    closeOnEscape: false,
  })
}

const fullVerifiedAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='text-center popup-remopve-place popup-list-call'>
          <h5 className='title'>{t('history_call.call_back.confirm_verified_title')}</h5>
          <div className='my-4 body'>
            <p>{t('history_call.call_back.confirm_verified_body')}</p>
          </div>
          <div>
            <button className='btn cancel bg-submit text-white' onClick={onClose}>{t('history_call.call_back.confirm_verified_close')}</button>
          </div>
        </div>
      )
    }
  })
}

const tabletUnavailableAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='text-center popup-remopve-place popup-list-call'>
          <h5 className='title'>{t('confirm_alert.tablet_unavailable_alert.title')}</h5>
          <div className='my-4 body'>
            <p>{t('confirm_alert.tablet_unavailable_alert.body')}</p>
            <p>{t('confirm_alert.tablet_udid_changed_alert.body')}</p>
          </div>
          <div>
            <button className='btn bg-submit text-white' onClick={onClose}>OK</button>
          </div>
        </div>
      )
    }
  })
}

const clientLostConnectionAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='text-center popup-remopve-place popup-list-call'>
          <h5 className='title'>{t('confirm_alert.client_lost_connection.title')}</h5>
          <div className='my-4 body'>
            <p>{t('confirm_alert.client_lost_connection.body')}</p>
          </div>
          <div>
            <button className='btn bg-submit text-white' onClick={onClose}>Close</button>
          </div>
        </div>
      )
    }
  })
}

const guestsFillingFormAlert = (t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='text-center popup-remopve-place popup-list-call'>
          <div className='my-4 body'>
            <p>{t('confirm_alert.guests_filling_form.body')}</p>
          </div>
        </div>
      )
    }
  })
}

export {
  expirationReservationAlert,
  unassignedListingAlert,
  locationNotAvailableAlert,
  fullVerifiedAlert,
  tabletUnavailableAlert,
  clientLostConnectionAlert,
  guestsFillingFormAlert,
}