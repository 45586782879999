import React, { Component } from 'react';
import { DateRangePicker}  from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './ZenDateRangePicker.scss';
import { createStaticRanges }  from 'react-date-range/src/defaultRanges.js'

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek
} from 'date-fns';


class ZenDateRangePicker extends Component {
  constructor(props) {
    super(props)
    this.handleRangeSelect = this.handleRangeSelect.bind(this)
    this.state = {
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: '#F4364C',
      }
    }
  }

  handleRangeSelect(ranges) {
    this.props.handleTimeRangeFilter(ranges)
    let selection = ranges.selection
    if (!selection.startDate && !selection.endDate) {
      selection.startDate = new Date()
      selection.endDate = new Date()
    }
    this.setState({selectionRange: ranges.selection})
  }

  render() {
    return(
     <DateRangePicker
        ranges={[this.state.selectionRange]}
        onChange={this.handleRangeSelect}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        showSelectionPreview={true}
        className={'PreviewArea'}
        moveRangeOnFirstSelection={false}
        displayMode={'dateRange'}
        preview={null}
        direction={'horizontal'}
        months={2}
        inputRanges={[]}
        maxDate={new Date()}
        staticRanges={staticRanges()}
      />
    )
  }

}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRanges = () => {
  return createStaticRanges(defaultStaticRanges)
}

const defaultStaticRanges = [
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: 'This Week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Clear Filter',
    range: () => ({
      startDate: null,
      endDate: null
    }),
  },
]

export default ZenDateRangePicker;