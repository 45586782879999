export const RESERVATION_ACTIONS = {
  RECEIVE_RESERVATIONS_INFO: 'RECEIVE_RESERVATIONS_INFO',
  ADD_MORE_RESERVATIONS: "ADD_MORE_RESERVATIONS"
}

export const receiveReservationsInfo = (reservationsList, currentPage, totalPages, loadMore) => ({
  type: RESERVATION_ACTIONS.RECEIVE_RESERVATIONS_INFO,
  payload: {
    reservationsList: reservationsList,
    currentPage: currentPage,
    totalPages: totalPages,
    loadMore: loadMore
  }
})

export const addMoreReservations = (moreReservations, currentPage, totalPages, loadMore) => ({
  type: RESERVATION_ACTIONS.ADD_MORE_RESERVATIONS,
  payload: {
    moreReservations: moreReservations,
    currentPage: currentPage,
    totalPages: totalPages,
    loadMore: loadMore
  }
})