import React, { Component } from 'react';
import './ToggleSliderButton.scss'

class ToggleSliderButton extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {

  }

  render() {
    let sliderInput = this.props.disabled ? (<input type="checkbox" disabled />) : (<input checked={this.props.isMasterPlace} type="checkbox" onChange={this.props.handleToggleMasterPlace} />)
    return(
      <div className="d-flex align-items-center">
        <div className="toggle-slider-button-container">
          <label>
            {sliderInput}
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    )
  }
}

export default ToggleSliderButton