import { render } from 'react-dom';
import React from 'react';

export function renderMessage(type, message) {
  let classes = {
    error: 'alert alert-danger',
    alert: 'alert alert-warning',
    notice: 'alert alert-info',
    success: 'alert alert-success'
  };
  window.location.href = '#error-msg'
  document.getElementById("error-msg").style.display = 'block'
  const hideMessage = function() {
    document.getElementById("error-msg").style.display = 'none'
  }
  const Message =
    <div className={classes[type]}>
      {message}
      <button className='close' aria-label='Close' type='button' onClick={hideMessage}>
        <span aria-hidden={true}>&times;</span>
      </button>
    </div>
  render(Message, document.getElementById("error-msg"))
}