import axios from 'axios';
import { apiPath } from './api';

const CallUtil = {
  updateCall: (callId, params, callBack=null) => {
    axios.put(apiPath(`calls/${callId}`), params)
    .then(response => {
      if (callBack) {
        callBack();
      }
    })
  }
}

export default CallUtil