import React, {Component} from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router'
import './IncomingCalls.scss';


class CIncomingCalls extends Component {
  constructor(props) {
    super(props)
    this.switchTabletType = this.switchTabletType.bind(this)
    this.state = {
      tabSelect: 0, // 0 - Incoming call, 1 - History Call
      tabletTypeSelect: 0,
    }
  }

  renderBtnAction(call, tabletType) {
    const disabledClass = call.reservation ? '' : 'disabled'
    return (
      <div className='btn-group'>
        <button className='btn-accept-call' onClick={() => this.props.receiveCall(call)}>TAKE CALL</button>
        <button onClick={e => this.props.handleClickOnDetailedItem({type: "IncomingCallSelect", data: call})} className={tabletType === 'inside' ? `btn-detail-call ${disabledClass}` : `btn-detail-call-center ${disabledClass}`}>
          <i className='la la-info-circle'/>
        </button>
      </div>
    )
  }

  switchTabletType(type) {
    this.setState({tabletTypeSelect: type})
  }

  renderBtnTabletType(type) {
    const titles = ['ALL TYPE', 'INSIDE', 'OUTSIDE']
    let className = 'btn btn-calling-type'
    let content = titles[type]
    if (this.state.tabletTypeSelect === type) {
      className = 'btn btn-calling-type select'
      content = titles[type] + ` (${this.noCallTabletType})`
    }
    return (<button key={type} className={className} onClick={this.switchTabletType.bind(this, type)}>{content}</button>)
  }

  filterCall() {
    if (this.state.tabletTypeSelect !== 0) {
      const tabletType = this.state.tabletTypeSelect === 1 ? 'inside' : 'outside'
      let filterCall = this.props.incomingCalls.filter(call => call.tablet_type === tabletType)
      this.noCallTabletType = filterCall.length
      return filterCall
    } else {
      // sorted outside at the top
      this.noCallTabletType = this.props.incomingCalls.length
      return this.props.incomingCalls.sort((prevCall, nextCall) => {
        if (prevCall.tablet_type > nextCall.tablet_type) {
          return -1;
        } else if (prevCall.tablet_type < nextCall.tablet_type) {
          return 1;
        } else {
          return prevCall.id >= nextCall.id ? 1 : -1
        }
      })
    }
  }

  renderCallingType(type) {
    return type === 'checkin' ? <div className='type-checkin'>Checkin</div>
                              : <div className='type-support'>Support Needed</div>
  }

  guestClassName(call) {
    if (call.reservation) return
    return 'anonymous-guest'
  }

  render() {
    let callRender = this.filterCall()
    const btnTabletTypes = []
    for (let i = 0; i <= 2; i++) {
      btnTabletTypes.push(this.renderBtnTabletType(i));
    }
    return (
      <div>
        <div className='row'>{ btnTabletTypes }</div>
        <hr />
        <div className='table-list-calls'>
          <div className='thead row'>
            <div className='col-md-1 col-lg-1'>No.</div>
            <div className='col-md-3 col-lg-3 text-center'>Listing</div>
            <div className='col-md-2 col-lg-2 text-center'>Guest's Name</div>
            <div className='col-md-2 col-lg-2 text-center'>Tablet Type</div>
            <div className='col-md-2 col-lg-2 text-center'>Call Type</div>
            <div className='col-md-2 col-lg-2 text-center'>Action</div>
          </div>
          {
            callRender.map((call, i) =>
            <div className='tvalue row' key={call.id}>
              <div className='col-md-1 col-lg-1'>{i + 1}</div>
              <div className='col-md-3 col-lg-3 text-center'>{call.reservation && call.reservation.listing ? call.reservation.listing.short_name : 'None'}</div>
              <div className={`col-md-2 col-lg-2 text-center ${this.guestClassName(call)}`}>{call.guest_name}</div>
              <div className='col-md-2 col-lg-2 text-center'>{call.tablet_type === 'inside' ? 'Inside' : 'Outside'}</div>
              <div className='col-md-2 col-lg-2 text-center'>{this.renderCallingType(call.calling_type)}</div>
              <div className='col-md-2 col-lg-2 text-center'>{this.renderBtnAction(call, call.tablet_type)}</div>
            </div>
          )}
          {
            !callRender.length &&
            <div className='col text-center nocall-noti'>No Call Log Matched</div>
          }
        </div>
      </div>
    )
  }
}

CIncomingCalls.defaultProps = {
  listCalls: {},
  incomingCalls: [],
}

const RIncomingCalls = withRouter(CIncomingCalls)

const mapStateToProps = state => ({
  incomingCalls: state.listCalls.incomingCalls
})

const mapDispatchToProps = {
}

const IncomingCalls = connect(
  mapStateToProps,
  mapDispatchToProps
)(RIncomingCalls)

export default IncomingCalls