import React, { Component } from 'react';
import parse from 'html-react-parser';
import moment from 'moment';
import ActionCable from "actioncable";
import { cableURL } from '../../utils/api';
import PropTypes from 'prop-types'
import './Messenger.scss'

class Messenger extends Component {
  constructor(props) {
    super(props)
    this.handleTypeMessage = this.handleTypeMessage.bind(this)
    this.handleEnterMessage = this.handleEnterMessage.bind(this)
    this.state = {
      messagesHistory: [],
      message: ''
    }
  }

  setUpMessageChannel(roomName) {
    const cable = ActionCable.createConsumer(cableURL());
    this.channel = cable.subscriptions.create({channel: "MessageChannel", room_name: roomName}, {
      connected: () => {
        console.log(`Connect to message channel: ${roomName}`)
      },
      disconnected: () => {

      },
      received: response => {
        if (response.type === "message") {
          let state = {...this.state}
          let fullMessage = {
            body: response.data,
            time: moment(new Date()).format("hh:mm a"),
            owner: response.owner
          }
          state.messagesHistory.push(fullMessage)
          this.setState(state, () => {
            setTimeout(() => {
              this.messengerContent.scrollTop = this.messengerContent.scrollHeight
            }, 100)
          })
        }
      },
      send_message: function(msg, roomName) {
        this.perform('send_message', {
          room_name: roomName,
          message: msg,
          owner: "operator"
        })
      }
    })
  }

  componentDidMount() {
    console.log('Messenger did mount')
    this.setUpMessageChannel(this.props.roomName)
    this.messengerContent = document.getElementById('messenger-content')
  }

  componentWillUnmount() {
    if (this.channel) {
      this.channel.unsubscribe()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.roomName !== prevProps.roomName) {
      if (this.channel) {
        this.channel.unsubscribe()
      }
      this.setUpMessageChannel(this.props.roomName)
    }
  }

  handleTypeMessage(e) {
    let state = {...this.state}
    state.message = e.target.value
    this.setState(state)
  }

  handleEnterMessage(event) {
    if (event.key !== 'Enter') return
    if (!this.state.message.length) return
    this.sendMessage()
  }

  renderHistoryMessage() {
    let text = ''
    this.state.messagesHistory.forEach(message => {
      let floatClass = message.owner === "operator" ? "float-right" : "float-left"
      text += `<div className='line-msg'>
                <div className='line-msg-container ${message.owner} ${floatClass}'>
                  <div className='msg-content row'>${message.body}</div>
                  <div className='msg-time text-right'>${message.time}</div>
                </div>
               </div>`
    })
    return parse(text)
  }

  sendMessage() {
    let state = {...this.state}
    let message = state.message
    if (!message.length) return
    this.channel.send_message(message, this.props.roomName)
    state.message = ''
    this.setState(state)
  }

  render() {
    const t = this.context.t
    return (
      <div>
        <div id="messenger-main" className={`messenger`}>
          <div className="messages-wrapper">
            <div id="messenger-content" className='history-msg scrollbar-custom'>
              { this.renderHistoryMessage() }
            </div>
          </div>
          <div className="input-messages">
            <input
              type="text"
              className='type-msg'
              placeholder={ t('call_detail.messenger.send_message') }
              value={this.state.message}
              onChange={this.handleTypeMessage}
              onKeyPress={this.handleEnterMessage}
              disabled={this.props.disableInputMessage}
            />
            <div className='btn-send-message bg-transparent border-0 text-white'>
              <button onClick={() => this.sendMessage()} disabled={this.props.disableInputMessage}>
                <i className='la la-paper-plane'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Messenger.contextTypes = {
  t: PropTypes.func
}

export default Messenger;