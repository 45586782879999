import React from 'react';
import { BrowserRouter, Route, Switch , Redirect} from 'react-router-dom'
import Logout from './components/Logout/Logout';
import Layout from './components/Layout/Layout';
import LoginZensapp from './components/LoginZensapp/LoginZensapp';
import NewVideoCall from './components/NewVideoCall/NewVideoCall';
import CallScreen from './components/CallScreen/CallScreen';

const PrivateRoute = ({ component: Component, authed, ...rest }) => (
  <Route {...rest} render={(props) => (
    authed === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

function isAuthenticated(roles) {
  if (localStorage.getItem('authToken')) {
    const authToken = JSON.parse(localStorage.getItem('authToken'))
    const authUser = authToken['authUser']
    let rightRole = false 
    for (let index = 0; index < roles.length; index++) {
      const role = roles[index];
      if (authUser['roles'].includes(role)) {
        rightRole = true
        break
      }
    }
    if (authUser['source'] === 'zensapp' && new Date() <= new Date(authToken['expiredAt']) && rightRole) {
      return true
    }
  }
  return false
}

export const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Layout>
          <PrivateRoute exact={true} authed={isAuthenticated(['support'])} path='/' component={NewVideoCall} />
          <Route exact path='/login' component={LoginZensapp} />
          <Route exact path='/logout' component={Logout} />
          <PrivateRoute exact path='/list_calls' authed={isAuthenticated(['support', 'admin', 'operation'])} component={NewVideoCall} />
          <PrivateRoute exact path='/call_screen' authed={isAuthenticated(['support', 'operation'])} component={CallScreen} />
        </Layout>
      </Switch>
    </BrowserRouter>
  )
}
