import { apiPath } from './api'

export const loadPlaceOptions = async (search, loadedOptions, {page, edit}) => {
  const placeEdit = edit ? edit : ''
  const response = await fetch(`${apiPath('places/slaves')}?search=${search}&page=${page}&edit=${placeEdit}`)
  const responseJSON = await response.json()
  const places = responseJSON.places
  let hasMore = Number(responseJSON.total_page) > Number(responseJSON.current_page)
  places.forEach(place => {
    place['value'] = place.id
    place['label'] = place.id
  })
  return {
    options: places,
    hasMore: hasMore,
    additional: {
      page: page + 1,
      edit: placeEdit
    }
  }
}