import React, { Component } from 'react'
import { formatSeconds } from '../../utils/format';
import './Timer.css'

class Timer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0
    };
    this.timer = null;
  }
  componentDidMount() {
    this.timer = setInterval(() => {
      let newCount = this.state.count + 1;
      this.setState({
        count: newCount >= 0 ? newCount : 0
      });
      this.props.setDuration(newCount)
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render () {
    return (
       <div className='timer'>
        {formatSeconds(this.state.count)}
      </div>
    )
  }
}
export default Timer;